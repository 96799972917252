import {Button, Cascader, Divider, Form, Input, InputNumber, Modal, Select, Space, TreeSelect} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import AddNewPictures from '../AddNewPictures';
// @ts-ignore
import {debounce} from "lodash"
import {showFail, showNeedCompleteInfo, showSuccess, showWarning} from "../functions";
import PickLocationModal from '../pickLocationModal';
import {addCompany, addHydrant, getAllCompanyNotPage, getAreaTree, getSiteNotPage, getUnits} from "../../api";
import PickUser from "../pickUser";
import {AxiosError} from "axios";

const useResetFormOnCloseModal = ({form, open}: { form: any, open: boolean }) => {
    const prevOpenRef: any = useRef();
    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const [sites, setSites] = useState<any[]>([])
    useEffect(() => {
    }, []);
    const prevOpen = prevOpenRef.current;
    useEffect(() => {
        if (!open && prevOpen) {
            form.resetFields();
        }
    }, [form, prevOpen, open]);
};



const AddHydrantModal = ({
                             open,
                             onClose,
                             siteOptions,
                             handleGetHydrant
                         }: { open: boolean, onClose: () => void, siteOptions: any, handleGetHydrant: any }) => {
    const [form] = Form.useForm();
    useResetFormOnCloseModal({
        form,
        open,
    });
    const [areaId,setAreaId]=useState(window.localStorage.getItem("areaId"));
    const [loading, setLoading] = useState<boolean>(false);
    const [areaIdRes,setAreaIdRes]=useState();

    const handleAddNewCompany = () => {
        form.validateFields().then((value) => {
            let newCompany = {
                companyName:value?.companyName,
                companyLng: value?.location?.lng,
                companyLat: value?.location?.lat,
                parkNum:value?.parkNum,
                areaId:areaId!=null && areaId=='1'?areaIdRes:areaId,
                unitId:!isUnitManager?value?.unitId:window.localStorage.getItem("unitId"),
                contactName:value?.contactName,
                contactMobile:value?.contactMobile,
                address:value?.address,
                openTimeDetail:value.openTimeDetail,
                remark:value.remark
            }
            setLoading(true);
            addCompany(newCompany).then((res: any) => {
                setLoading(false);
                form.resetFields();
                setLoc(null);
                console.log(res)
                if (res?.code === 200) {
                    handleGetHydrant().then(() => {
                        showSuccess(res?.msg);
                        onClose();
                    })
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                console.log(err)
            })

        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }

    const [pickLocModalOpen, setPickLocModalOpen] = useState(false);
    const handleCancelLocModal = () => {
        setPickLocModalOpen(false);
    }
    const [unitList, setUnitList] = useState<any[]>([]);
    const changeUnitName = (e: any) => {
        let v = e?.target?.value;
        if (v) {
            let params: any = {unitName: v};

            getUnits(params).then((res: any) => {
                console.log(res)
                if (res?.code === 200) {
                    setUnitList(res?.data?.map((d: any) => {
                        return {
                            value: d?.unitId,
                            label: d.unitName
                        }
                    }))
                }
            })
        } else {
            showWarning("请输入归属人姓名或手机号")
        }
    }

    const confirmLoc = (e: any) => {
        setLoc(e);
        form.setFieldsValue({"location": e})
    }
    const [loc, setLoc] = useState<any>()
    const [initLoc, setInitLoc] = useState({lng: 120.09, lat: 30.88});
    const getLocation = () => {
        console.log(navigator.geolocation)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((l) => {
                if (l?.coords) {
                    setInitLoc({lng: l?.coords?.longitude, lat: l?.coords?.latitude})
                } else {
                    showFail('获取定位失败')
                }
            });
        } else {
            alert("浏览器不支持地理定位。");
        }
    }
    const confirmName = () => {}

    const handleChange=(e:any)=>{
        setAreaIdRes(e)
        console.log(e)
    }


    interface areatree {
        value?: string,
        title?: string,
        children?: any[],
    }
    //保存数据
    const [areaTree, setAreaTree] = useState([]);
    const [areas, setAreas] = useState<any>([]);
    function tree(list:areatree[],res:any[]){
        for (let i = 0; i < res.length; i++) {
            if(res[i].children){
                // @ts-ignore
                list.push({value: res[i].areaId, title: res[i].name, children: []})
            }else {
                // @ts-ignore
                list.push({value: res[i].areaId, title: res[i].name})
            }
            // @ts-ignore
            if(res[i].children) {
                // @ts-ignore
                tree(list[i].children, res[i].children)
            }
        }
        return list

    }
    //查询权限Tree
    useEffect(()=>{
        let params = {}
        getAreaTree(params).then( (res:any)=>{
            setAreaTree(res.data);
            let a:areatree[]=[]
            let b=[]
            b.push(res.data)
            let list=tree(a,b)
            setAreas(list)
            console.log(list)
        }, (err:AxiosError)=>{
            console.log(err);
        });


    },[])

    const isUnitManager = JSON.parse(window.localStorage?.getItem("isUnitManager") as string);
    return (
        <div>
            <Modal open={open} onCancel={() => {
                onClose();

                setLoading(false)
            }} title={'停车场新增'} maskClosable={false} okText={'确认提交'}
                   onOk={debounce(handleAddNewCompany, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                <Form form={form} name="form" labelAlign="right" labelCol={{
                    xs: {span: 24},
                    sm: {span: 6},
                }} wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 14},
                }}>
                    <Form.Item
                        name="companyName"
                        label="停车场名称"
                        rules={[
                            {
                                required: true, message: "请输入停车场名称"
                            },
                        ]}
                    >
                        <Input placeholder="请输入停车场名称"/>
                    </Form.Item>

                    <Form.Item
                        name="parkNum"
                        label="车位数量"
                        rules={[
                            {
                                required: true, message: "请输入车位数量"
                            },
                        ]}
                    >
                        <InputNumber placeholder="请输入车位数量"/>
                    </Form.Item>
                    <Form.Item
                        name="address"
                        label="详细地址"
                        rules={[
                            {
                                required: true, message: "请输入详细地址"
                            },
                        ]}
                    >
                        <Input placeholder="请输入详细地址" style={{ width: '100%' }}/>
                    </Form.Item>

                    <Form.Item
                        name="location"
                        label="停车场定位"
                        // rules={[
                        //     {
                        //         required: true, message: "请选择定位"
                        //     },
                        // ]}
                    >
                        {loc ? (<div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{color: "#235c7a"}}>
                                    <div>经度:{loc?.lng}</div>
                                    <div>纬度:{loc?.lat}</div>
                                </div>
                                <div style={{width: "20px"}}/>
                                <div style={{display: "grid", placeItems: "center"}}>
                                    <Button type="primary" onClick={() => {
                                        setPickLocModalOpen(true)
                                    }}>重新选择
                                    </Button>
                                </div>

                            </div>
                        ) : (
                            <Button type="primary" onClick={() => {
                                getLocation();
                                setPickLocModalOpen(true);
                            }}>选择定位
                            </Button>
                        )}
                    </Form.Item>
                    {!isUnitManager? <Form.Item
                        name="unitId"
                        label="归属单位"
                        rules={[
                            {
                                required: true, message: "归属单位不能为空"
                            },
                        ]}
                    >
                        <Select
                            allowClear={true}
                            placeholder={"请选择归属单位"}
                            dropdownRender={(area) => (
                                <>
                                    {area}
                                    <Divider style={{margin: '8px 0'}}/>
                                    <Space style={{padding: '4px 8px 4px '}}>
                                        <Input
                                            allowClear
                                            placeholder="请输入单位名称"
                                            onChange={debounce(changeUnitName, 600)}
                                        />
                                    </Space>
                                </>
                            )}
                            options={unitList}
                        />
                    </Form.Item>
                        :''}
                    {areaId!=null && areaId=='1'?
                    <Form.Item
                        name="areaId"
                        label="归属区域"
                        rules={[
                            {
                                required: true, message: "归属区域不能为空"
                            },
                        ]}
                    ><TreeSelect
                            showSearch
                            style={{ width: '100%' }}
                            // value={areaTree}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder="归属区域"
                            allowClear
                            treeDefaultExpandAll
                            onChange={(e)=>handleChange(e)}
                            treeData={areas}
                        />
                    </Form.Item>:''}
                    <Form.Item
                        name="contactName"
                        label="联系人姓名"
                        // rules={[
                        //     {
                        //         required: true, message: "请输入联系人姓名"
                        //     },
                        // ]}
                    >
                        <Input placeholder="请输入联系人姓名"/>
                    </Form.Item>
                    <Form.Item
                        name="contactMobile"
                        label="联系人电话"
                        // rules={[
                        //     {
                        //         required: true, message: "请输入联系人电话"
                        //     },
                        // ]}
                    >
                        <InputNumber placeholder="请输入联系人电话" style={{ width: '100%' }}/>
                    </Form.Item>
                    <Form.Item
                        name="openTimeDetail"
                        label="开放时间描述"
                    >
                        <Input placeholder="请输入开放时间描述" style={{ width: '100%' }}/>
                    </Form.Item>
                    <Form.Item
                        name="remark"
                        label="备注"
                    >
                        <Input placeholder="请输入备注" style={{ width: '100%' }}/>
                    </Form.Item>
                </Form>
                <PickLocationModal loc={initLoc} open={pickLocModalOpen} onCancel={handleCancelLocModal}
                                   confirmLoc={(e: any) => {
                                       confirmLoc(e)
                                   }} confirmAddress={() => {}} confirmRoad={() => {}}
                                   confirmName={confirmName}
                                   repick={undefined}/>

            </Modal>

        </div>
    );
};

export default AddHydrantModal;