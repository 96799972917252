import {
    AutoComplete,
    Button,
    Carousel,
    Cascader,
    Form,
    Input,
    Modal,
    Popconfirm,
    Select,
    Table,
    Tag,
    Tooltip,
    Image, InputNumber
} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import styles from "./index.module.css"
import {iHydrant, iPicture} from "../../../interface";
import {
    addUnit,
    deleteUnit, getAllUnit, getQrcode, updateUnit,
} from "../../../api";
import {getAreaPath, showFail, showNeedCompleteInfo, showSuccess, transform} from "../../../components/functions";
import AddUnitModal from "../../../components/addUnitModal";
// @ts-ignore
import {debounce} from "lodash";
import locationIcon from "../../../assets/icons/location.svg";
import noneIcon from "../../../assets/icons/none.svg";
import LocationModal from "../../../components/locationModal";
import PickLocationModal from "../../../components/pickLocationModal";
// @ts-ignore

const Unit = () => {
        const [pageNum, setPageNum] = useState<number>(1);
        const [pageSize, setPageSize] = useState<number>(10);
        const [total, setTotal] = useState<number>(0);
        const [loading, setLoading] = useState<boolean>(false)
        const [sites, setSites] = useState<any[]>([])
        const [hydrantList, setHydrantList] = useState<iHydrant[]>([])
        const onPageChange = (_page: number) => {
            setPageNum(_page);
        };

        const onPageSizeChange = (_page: number, _pageSize: number) => {
            setPageSize(_pageSize);
        };
        const isUnit = JSON.parse(window.localStorage?.getItem("isUnit") as string);
        let params: any = {}
        const handleGetParkUnitList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
            setLoading(true);
            if (searchCondition?.unitName) {
                params.unitName = searchCondition?.unitName
            }
            params.pageSize = pageSize;
            params.pageNum = pageNum;
            if(isUnit){
                params.unitId = window.localStorage?.getItem("unitId") ;
            }
            getAllUnit(params).then((res: any) => {
                setLoading(false);
                if (res?.code === 200) {
                    setTotal(Number(res?.data?.total));
                    setHydrantList(res?.data?.records);
                } else {
                    showFail(res?.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        }

        useEffect(() => {
            handleGetParkUnitList(pageNum, pageSize);

        }, [pageSize, pageNum])

        const [form] = Form.useForm();


        const [searchCondition, setSearchCondition] = useState({
            unitName: "",

        });
        const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);
        const [locationModalOpen, setLocationModalOpen] = useState(false);
        const handleCloseLocationModal = () => {
            setLocationModalOpen(false);
        }
        const [hydrantLng, setHydrantLng] = useState<string | number>();
        const [hydrantLat, setHydrantLat] = useState();
        const [codeUrl, setCodeUrl] = useState();
        const columns: any = [
            {
                title: '单位名称',
                dataIndex: 'unitName',
                key: 'unitName',
            },
            {
                title: '联系人姓名',
                dataIndex: 'contactName',
            },
            {
                title: '联系人电话',
                dataIndex: 'contactMobile',
            },
            {
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                render: (_: any, record: any) => (
                    <div>
                        {!isUnit? <div>
                            <Popconfirm title={"是否确认删除该公司"} onConfirm={() => {
                                deleteUnit({unitId: record?.unitId}).then((res: any) => {
                                    if (res?.code === 200) {
                                        handleGetParkUnitList(pageNum, pageSize, newSearchCondition).then(() => {
                                            showSuccess(res?.msg);
                                        })
                                    } else {
                                        showFail(res?.msg)
                                    }
                                }).catch((err) => {
                                    console.log(err)
                                })
                            }}>
                                <Button danger type={'link'} color={'danger'}>删除</Button>
                            </Popconfirm>

                            <Button  type={'link'} color={'primary'}
                                     onClick={()=>{
                                         setUpdateModalVisible(true);
                                         form.setFieldsValue({'unitId':record.unitId})
                                         form.setFieldValue('unitName',record.unitName)
                                         form.setFieldValue('contactName',record.contactName)
                                         form.setFieldValue('contactMobile',record.contactMobile)
                                         // form.setFieldValue('parkNum',record.parkNum)
                                         // form.setFieldValue('remainNum',record.remainNum)
                                         // form.setFieldValue('unitLat',record.unitLat)
                                         // form.setFieldValue('unitLng',record.unitLng)
                                     }}
                            >修改</Button>
                        </div>:''}
                    </div>
                )
            },
        ];

        //新增
        const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
        const handleCloseAddModal = () => {
            setAddModalVisible(false);
        }
        const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);
        const handleCloseUpdateModal = () => {
            setUpdateModalVisible(false);
        }


        const [imgModalVisible, setImgModalVisible] = useState<boolean>(false);
        const handleCloseImgModal = () => {
            setImgModalVisible(false);
        }

        const handleUpdateNewUnit = () => {
            form.validateFields().then((value) => {
                let newUnit = {
                    unitName:value?.unitName,
                    unitId:form.getFieldValue('unitId'),
                    unitLng: value?.location?.lng,
                    unitLat: value?.location?.lat,
                    parkNum:value?.parkNum,
                    remainNum:value?.remainNum,
                    contactName:value?.contactName,
                    contactMobile:value?.contactMobile
                }
                setLoading(true);
                updateUnit(newUnit).then((res: any) => {
                    setLoading(false);
                    setLoc(null);
                    form.resetFields();
                    console.log(res)
                    if (res?.code === 200) {
                        handleGetParkUnitList(pageNum, pageSize).then(() => {
                            showSuccess(res?.msg);
                            handleCloseUpdateModal();
                        })
                    } else {
                        showFail(res?.msg)
                    }
                }).catch((err: any) => {
                    console.log(err)
                })

            }).catch((err) => {
                console.log(err);
                showNeedCompleteInfo();
            })
        }

        const [pickLocModalOpen, setPickLocModalOpen] = useState(false);
        const handleCancelLocModal = () => {
            setPickLocModalOpen(false);
            setUpdateModalVisible(true);
        }


        const confirmLoc = (e: any) => {
            setLoc(e);
            form.setFieldsValue({"location": e})
        }
        const [loc, setLoc] = useState<any>()
        const [initLoc, setInitLoc] = useState({lng: 120.09, lat: 30.88});
        const getLocation = () => {
            console.log(navigator.geolocation)
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((l) => {
                    if (l?.coords) {
                        setInitLoc({lng: l?.coords?.longitude, lat: l?.coords?.latitude})
                    } else {
                        showFail('获取定位失败')
                    }
                });
            } else {
                alert("浏览器不支持地理定位。");
            }
        }

        //新增宿舍中的选择位置对话框
        const confirmName = () => {}


        return (
            <div className={styles.main}>
                {!isUnit?
                    <div className={styles.mainHead}>
                        <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}} onChange={(e:any)=>{setSearchCondition({unitName: e.target.value})}} placeholder={'公司名称'} allowClear></Input>

                        <div className={styles.searchButton}>

                            <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                                handleGetParkUnitList(1, 10, searchCondition);
                                setPageNum(1);
                                setPageSize(10);
                                setNewSearchCondition(searchCondition)
                            }}>查询
                            </Button>

                            <Button type='primary' style={{marginRight: '20px', marginTop: '10px'}} onClick={() => {
                                setAddModalVisible(true);
                            }}>新增
                            </Button>

                        </div>

                    </div>
                    :''}
                <Table rowKey={'areaId'} columns={columns} dataSource={hydrantList} loading={loading}
                       pagination={{
                           current: pageNum,
                           onChange: onPageChange,
                           onShowSizeChange: onPageSizeChange,
                           total: total,
                           showSizeChanger: true,
                           defaultPageSize: pageSize,
                           pageSizeOptions: ['10', '20', '50', '100'],
                       }}/>


                <AddUnitModal open={addModalVisible} onClose={handleCloseAddModal} siteOptions={sites}
                              handleGetHydrant={async () => {
                                  await handleGetParkUnitList(pageNum, pageSize, newSearchCondition)
                              }}/>
                <Modal open={updateModalVisible} onCancel={() => {
                    handleCloseUpdateModal();
                    setLoading(false)
                }} title={'单位修改'} maskClosable={false} okText={'确认提交'}
                       onOk={debounce(handleUpdateNewUnit, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                    <Form form={form} name="form" labelAlign="right" labelCol={{
                        xs: {span: 24},
                        sm: {span: 6},
                    }} wrapperCol={{
                        xs: {span: 24},
                        sm: {span: 14},
                    }}>
                        <Form.Item
                            name="unitName"
                            label="公司名称"
                            rules={[
                                {
                                    required: true, message: "请输入公司名称"
                                },
                            ]}
                        >
                            <Input placeholder="请输入公司名称"/>
                        </Form.Item>
                        <Form.Item
                            name="contactName"
                            label="联系人姓名"
                            // rules={[
                            //     {
                            //         required: true, message: "请输入联系人姓名"
                            //     },
                            // ]}
                        >
                            <Input placeholder="请输入联系人姓名"/>
                        </Form.Item>
                        <Form.Item
                            name="contactMobile"
                            label="联系人电话"
                            // rules={[
                            //     {
                            //         required: true, message: "请输入联系人电话"
                            //     },
                            // ]}
                        >
                            <InputNumber style={{ width: '100%' }} placeholder="请输入联系人电话"/>
                        </Form.Item>
                        {/*<Form.Item*/}
                        {/*    name="parkNum"*/}
                        {/*    label="车位数量"*/}
                        {/*    rules={[*/}
                        {/*        {*/}
                        {/*            required: true, message: "请输入车位数量"*/}
                        {/*        },*/}
                        {/*    ]}*/}
                        {/*>*/}
                        {/*    <InputNumber placeholder="请输入车位数量"/>*/}
                        {/*</Form.Item>*/}
                        {/*<Form.Item*/}
                        {/*    name="remainNum"*/}
                        {/*    label="剩余车位"*/}
                        {/*    rules={[*/}
                        {/*        {*/}
                        {/*            required: true, message: "请输入剩余车位"*/}
                        {/*        },*/}
                        {/*    ]}*/}
                        {/*>*/}
                        {/*    <InputNumber placeholder="请输入剩余车位"/>*/}
                        {/*</Form.Item>*/}
                        {/*<Form.Item*/}
                        {/*    name="location"*/}
                        {/*    label="处置点位"*/}
                        {/*    initialValue={loc}*/}
                        {/*>*/}
                        {/*    {loc ? (<div style={{display: "flex", flexDirection: "row"}}>*/}
                        {/*            <div style={{color: "#235c7a"}}>*/}
                        {/*                <div>经度:{loc?.lng}</div>*/}
                        {/*                <div>纬度:{loc?.lat}</div>*/}
                        {/*            </div>*/}
                        {/*            <div style={{width: "20px"}}/>*/}
                        {/*            <div style={{display: "grid", placeItems: "center"}}>*/}
                        {/*                <Button type="primary" onClick={() => {*/}
                        {/*                    setPickLocModalOpen(true)*/}
                        {/*                    handleCloseUpdateModal();*/}
                        {/*                }}>重新选择*/}
                        {/*                </Button>*/}
                        {/*            </div>*/}

                        {/*        </div>*/}
                        {/*    ) : (*/}
                        {/*        <Button type="primary" onClick={() => {*/}
                        {/*            getLocation();*/}
                        {/*            setPickLocModalOpen(true);*/}
                        {/*            handleCloseUpdateModal();*/}
                        {/*        }}>选择定位*/}
                        {/*        </Button>*/}
                        {/*    )}*/}

                        {/*</Form.Item>*/}
                    </Form>
                </Modal>

                <LocationModal open={locationModalOpen} onCancel={handleCloseLocationModal}
                               lat={hydrantLat} lng={hydrantLng}/>

                <PickLocationModal  loc={initLoc} open={pickLocModalOpen} onCancel={handleCancelLocModal}
                                    confirmLoc={(e: any) => {
                                        confirmLoc(e)
                                        setUpdateModalVisible(true);
                                    }} confirmAddress={() => {
                }} confirmRoad={() => {
                }} confirmName={confirmName} repick={true}/>

                <Modal open={imgModalVisible} onCancel={() => {
                    handleCloseImgModal();
                    setLoading(false)
                }} title={'二维码'} maskClosable={false} footer={<Button>关闭</Button>} destroyOnClose>

                    <div style={{textAlign:"center"}}> <Image src={codeUrl}></Image></div>
                </Modal>
            </div>
        );
    }
;

export default Unit;