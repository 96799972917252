import {Button, Cascader, Form, Input, Modal, Select} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import AddNewPictures from '../AddNewPictures';
// @ts-ignore
import {debounce} from "lodash"
import {showFail, showNeedCompleteInfo, showNetError, showSuccess} from "../functions";
import PickLocationModal from '../pickLocationModal';
import {addHydrant, getHydrantId} from "../../api";
import PickUser from "../pickUser";


const useResetFormOnCloseModal = ({form, open}: { form: any, open: boolean }) => {
    const prevOpenRef: any = useRef();
    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;
    useEffect(() => {
        if (!open && prevOpen) {
            form.resetFields();
        }
    }, [form, prevOpen, open]);
};

const AddHydrantModal = ({
                             open,
                             onClose,
                             areaOptions,
                             handleGetHydrant
                         }: { open: boolean, onClose: () => void, areaOptions: any, handleGetHydrant: any }) => {
    const [form] = Form.useForm();
    useResetFormOnCloseModal({
        form,
        open,
    });

    const [generatedHydrantId, setGeneratedHydrantId] = useState<string>("");
    const handleGetGeneratedCommunityId = () => {
        getHydrantId().then((res: any) => {
            if (res?.code === 200) {
                setGeneratedHydrantId(res?.msg)
            }
        }).catch((err: any) => {
            console.log(err);
            showNetError();
        })
    }

    useEffect(() => {
        if (open) {
            handleGetGeneratedCommunityId()
        }
    }, [open])

    const [loc, setLoc] = useState<any>()
    const [initLoc, setInitLoc] = useState({lng: 120.09, lat: 30.88});


    //新增宿舍中的选择位置对话框
    const [pickLocModalOpen, setPickLocModalOpen] = useState(false);
    const handleCancelLocModal = () => {
        setPickLocModalOpen(false);
    }


    const confirmLoc = (e: any) => {
        setLoc(e);
        form.setFieldsValue({"location": e})
    }

    const confirmHydrantName = (e: any) => {
        form.setFieldsValue({"hydrantName": e})
    }

    const confirmRoad = (e: any) => {
        form.setFieldsValue({"road": e})
    }

    const confirmAddress = (e: any) => {
        form.setFieldsValue({"hydrantAddress": e})
    }


    const getLocation = () => {
        console.log(navigator.geolocation)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((l) => {
                if (l?.coords) {
                    setInitLoc({lng: l?.coords?.longitude, lat: l?.coords?.latitude})
                } else {
                    showFail('获取定位失败')
                }
            });
        } else {
            alert("浏览器不支持地理定位。");
        }
    }

    const [user, setUser] = useState<any>()
    const [pickUserModalOpen, setPickUserModalOpen] = useState(false);
    const handleCancelUserModal = () => {
        setPickUserModalOpen(false);
    }

    const confirmUser = (e: any) => {
        setUser(e);
        form.setFieldsValue({"userId": e?.userId})
    }


    const [loading, setLoading] = useState<boolean>(false);
    const handleAddNewHydrant = () => {
        form.validateFields().then((value) => {
            let newHydrant = {
                ...value,
                lng: value?.location?.lng,
                lat: value?.location?.lat,
                areaId: value?.area && value?.area[value?.area?.length - 1],
                hydrantId:generatedHydrantId
            }
            setLoading(true);
            addHydrant(newHydrant).then((res: any) => {
                setLoading(false);
                console.log(res)
                if (res?.code === 200) {
                    handleGetHydrant().then(() => {
                        showSuccess(res?.msg);
                        onClose();
                        setLoc(undefined);
                        setUser(undefined);
                    })
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                console.log(err)
            })

        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }


    return (
        <div>
            <Modal open={open} onCancel={() => {
                onClose();
                setLoc(undefined);
                setLoading(false)
            }} title={'XHS新增'} maskClosable={false} okText={'确认提交'}
                   onOk={debounce(handleAddNewHydrant, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                <Form form={form} name="form" labelAlign="right" labelCol={{
                    xs: {span: 24},
                    sm: {span: 6},
                }} wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 14},
                }}>
                    <Form.Item
                        name="area"
                        label="街道"
                        rules={[
                            {
                                required: true, message: "请选择XHS街道"
                            },
                        ]}
                    >
                        <Cascader style={{width: 250}}
                                  options={areaOptions}
                                  changeOnSelect={false} placeholder="街道/乡镇"/>
                    </Form.Item>

                    <Form.Item
                        name="location"
                        label="定位"
                        rules={[
                            {
                                required: true, message: "请选择XHS定位"
                            },
                        ]}
                    >
                        {loc ? (<div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{color: "#235c7a"}}>
                                    <div>经度:{loc?.lng}</div>
                                    <div>纬度:{loc?.lat}</div>
                                </div>
                                <div style={{width: "20px"}}/>
                                <div style={{display: "grid", placeItems: "center"}}>
                                    <Button type="primary" onClick={() => {
                                        setPickLocModalOpen(true)
                                    }}>重新选择
                                    </Button>
                                </div>

                            </div>
                        ) : (
                            <Button type="primary" onClick={() => {
                                getLocation();
                                setPickLocModalOpen(true);
                            }}>选择定位
                            </Button>
                        )}
                    </Form.Item>

                    {loc && (
                        <>
                            <Form.Item
                                name="hydrantName"
                                label="XHS名称"
                                rules={[
                                    {
                                        required: true, message: "请输入XHS名称"
                                    },
                                ]}
                            >
                                <Input placeholder="请输入XHS名称"/>
                            </Form.Item>

                            <Form.Item
                                name="road"
                                label="道路"
                                rules={[
                                    {
                                        required: true, message: "请输入XHS所处道路"
                                    },
                                ]}
                            >
                                <Input placeholder="请输入XHS所处道路"/>
                            </Form.Item>

                            <Form.Item
                                name="hydrantAddress"
                                label="具体地址"
                                rules={[
                                    {
                                        required: true, message: "请输入XHS具体地址"
                                    },
                                ]}
                            >
                                <Input.TextArea placeholder="请输入XHS具体地址"/>
                            </Form.Item>
                        </>
                    )}

                    {/*<Form.Item*/}
                    {/*    name="userId"*/}
                    {/*    label="负责人"*/}
                    {/*>*/}
                    {/*    {user ? (<div style={{display: "flex", flexDirection: "row",alignItems:'center'}}>*/}
                    {/*            <div style={{color: "#235c7a"}}>*/}
                    {/*                <div> {user?.username}{user?.mobile}</div>*/}
                    {/*            </div>*/}
                    {/*            <div style={{width: "20px"}}/>*/}
                    {/*            <div style={{display: "grid", placeItems: "center"}}>*/}
                    {/*                <Button type="primary" onClick={() => {*/}
                    {/*                    setPickUserModalOpen(true)*/}
                    {/*                }}>重新选择*/}
                    {/*                </Button>*/}
                    {/*            </div>*/}

                    {/*        </div>*/}
                    {/*    ) : (*/}
                    {/*        <Button type="primary" onClick={() => {*/}
                    {/*            setPickUserModalOpen(true);*/}
                    {/*        }}>选择负责人*/}
                    {/*        </Button>*/}
                    {/*    )}*/}
                    {/*</Form.Item>*/}


                    <Form.Item
                        name="qrCode"
                        label="二维码"
                    >
                        <Input placeholder="请输入XHS二维码"/>
                    </Form.Item>

                    <Form.Item
                        name="appearanceOk"
                        label="外观情况"
                        initialValue={1}
                    >
                        <Select options={[
                            {
                                value: 1,
                                label: '完好'
                            },
                            {
                                value: 0,
                                label: '有损坏'
                            }
                        ]}/>
                    </Form.Item>

                    <Form.Item
                        name="hasWater"
                        label="出水情况"
                        initialValue={1}
                    >
                        <Select options={[
                            {
                                value: 1,
                                label: '有水'
                            },
                            {
                                value: 0,
                                label: '不出水'
                            }
                        ]}/>
                    </Form.Item>

                    <Form.Item
                        name="pressureOk"
                        label="水压情况"
                        initialValue={1}
                    >
                        <Select options={[
                            {
                                value: 1,
                                label: '正常'
                            },
                            {
                                value: 0,
                                label: '水压不足'
                            }
                        ]}/>
                    </Form.Item>

                    <Form.Item
                        name="description"
                        label="描述"
                    >
                        <Input placeholder="请输入XHS描述"/>
                    </Form.Item>


                    <Form.Item
                        name="picture_front"
                        label="正面照片"
                        valuePropName="fileList"
                    >
                        <AddNewPictures id={generatedHydrantId} type={1} name={'front'} max={1}/>
                    </Form.Item>


                    <Form.Item
                        name="picture_back"
                        label="背面照片"
                        valuePropName="fileList"
                    >
                        <AddNewPictures id={generatedHydrantId} type={1} name={'back'} max={1}/>
                    </Form.Item>

                    <Form.Item
                        name="picture_left"
                        label="左面照片"
                        valuePropName="fileList"
                    >
                        <AddNewPictures id={generatedHydrantId} type={1} name={'left'} max={1}/>
                    </Form.Item>

                    <Form.Item
                        name="picture_right"
                        label="右面照片"
                        valuePropName="fileList"
                    >
                        <AddNewPictures id={generatedHydrantId} type={1} name={'right'} max={1}/>
                    </Form.Item>
                </Form>
                <PickLocationModal loc={initLoc} open={pickLocModalOpen} onCancel={handleCancelLocModal}
                                   confirmLoc={(e: any) => {
                                       confirmLoc(e)
                                   }} confirmName={confirmHydrantName} confirmAddress={confirmAddress}
                                   confirmRoad={confirmRoad} repick={undefined}/>

                {/*<PickUser open={pickUserModalOpen} onCancel={handleCancelUserModal} confirmUser={confirmUser}*/}
                {/*          initUser={user}/>*/}
            </Modal>

        </div>
    );
};

export default AddHydrantModal;