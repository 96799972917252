import React, {useEffect, useState} from 'react';
import {CommunityContact, iCommunity} from "../../interface";
import {deleteCommunityContact, deleteCommunityPoint, getCommunity} from "../../api";
import {showFail, showNetError, showSuccess} from "../functions";
import {Button, Modal, Popconfirm, Table} from 'antd';
//@ts-ignore
import {Map, Marker, Markers} from "react-amap";
import AddNewContactModal from "../addNewContactModal";
import EditContactModal from "../editContactModal";

const CommunityContactsModal = ({
                                    open,
                                    onCancel,
                                    communityId
                                }: { open: boolean, onCancel: () => void, communityId: string }) => {
    const [community, setCommunity] = useState<iCommunity | undefined>();
    useEffect(() => {
        if (open) {
            handleGetCommunity()
        }
    }, [open]);

    const handleGetCommunity = async () => {
        getCommunity({
            communityId
        }).then((res: any) => {
            if (res?.code === 200) {
                let community = res?.data[0]
                setCommunity(community);
            } else {
                showFail(res?.msg)
            }
        }).catch((err: any) => {
            showNetError()
            console.log(err);
        })
    }

    const columns: any = [
        {
            title: '类型',
            dataIndex: 'contactType',
            key: 'contactType',
        },
        {
            title: '姓名',
            dataIndex: 'contactName',
            key: 'contactName',
        },
        {
            title: '手机号',
            dataIndex: 'contactMobile',
            key: 'contactMobile',
        },
        {
            title: '固话',
            dataIndex: 'contactLandlinePhone',
            key: 'contactLandlinePhone',
        },
        {
            title: '备注',
            dataIndex: 'contactRemark',
            key: 'contactRemark',
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            render: (_: string, record: any) => (
                <>
                    <Button type={'link'} onClick={()=>{
                        setEditContactModalOpen(true);
                        setCommunityContact(record)
                    }}>修改</Button>
                    <Popconfirm title={`是否确认删除该${record?.contactType}`} onConfirm={() => {
                        deleteCommunityContact({communityContactId: record?.communityContactId}).then((res: any) => {
                            if (res?.code === 200) {
                                handleGetCommunity().then(() => {
                                    showSuccess(res?.msg)
                                })
                            } else {
                                showFail(res?.msg)
                            }
                        }).catch((err: any) => {
                            console.log('err', err)
                            showNetError();
                        })
                    }}>
                        <Button danger type={'link'}>删除</Button>
                    </Popconfirm>
                </>
            )
        },
    ]

    const [addNewContactModalOpen,setAddNewContactModalOpen]=useState<boolean>(false);
    const handleCloseAddNewContactModal=()=>{
        setAddNewContactModalOpen(false)
    }

    const [communityContact,setCommunityContact]=useState<CommunityContact|undefined>();
    const [editContactModalOpen,setEditContactModalOpen]=useState<boolean>(false);
    const handleCloseEditContactModal=()=>{
        setEditContactModalOpen(false)
    }

    return (
        <Modal open={open} onCancel={onCancel} title={`${community?.communityName}`} width={'800px'}
               cancelButtonProps={{style: {display: 'none'}}}  onOk={onCancel}>
            <div>
                <div style={{display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    marginBottom:'15px'
                }}>
                    <Button type={'primary'} onClick={()=>{
                        setAddNewContactModalOpen(true)
                    }}>新增联系人</Button>
                </div>
                <Table rowKey={'communityContactId'} columns={columns}
                       dataSource={community?.communityContacts}/>
                <AddNewContactModal open={addNewContactModalOpen} onCancel={handleCloseAddNewContactModal}
                    community={community as iCommunity} handleGetCommunity={handleGetCommunity}
                />

                <EditContactModal open={editContactModalOpen} onCancel={handleCloseEditContactModal}
                                  communityContact={communityContact as CommunityContact} handleGetCommunity={handleGetCommunity}
                />
            </div>

        </Modal>
    );
};

export default CommunityContactsModal;