import {
    AutoComplete,
    Button,
    Carousel,
    Cascader,
    Form,
    Input,
    Modal,
    Popconfirm,
    Select,
    Table,
    Tag,
    Tooltip,
    Image
} from 'antd';
import React, {useEffect, useState} from 'react';
import styles from "./index.module.css"
import {iCommunity, iHydrant} from "../../../interface";
import {
    deleteCommunity,
    getAreaTree,
    getCommunityPage
} from "../../../api";
import {getAreaPath, showFail, showSuccess, transform} from "../../../components/functions";
import locationIcon from "../../../assets/icons/location.svg"
import noneIcon from "../../../assets/icons/none.svg"

// @ts-ignore
import {debounce} from "lodash";
import CommunityMapModal from "../../../components/communityMapModal";
import CommunityContactsModal from "../../../components/communityContactsModal";
import AddCommunityModal from "../../../components/addCommunityModal";
import EditCommunityModal from "../../../components/editCommunityModal";
import AddNewContactModal from "../../../components/addNewContactModal";
import AddNewPoiModal from "../../../components/addNewPoiModal";

const CommunityComponent = ({type}: { type: any }) => {
        const isSuperManager = JSON.parse(window.localStorage?.getItem("isSuperManager") as string);

        const [pageNum, setPageNum] = useState<number>(1);
        const [pageSize, setPageSize] = useState<number>(10);
        const [total, setTotal] = useState<number>(0);
        const [loading, setLoading] = useState<boolean>(false)


        const [communityList, setCommunityList] = useState<iCommunity[]>([])
        const onPageChange = (_page: number) => {
            setPageNum(_page);
        };

        const onPageSizeChange = (_page: number, _pageSize: number) => {
            setPageSize(_pageSize);
        };

        let params: any = {}
        const handleGetCommunityList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
            setLoading(true);
            params.pageSize = pageSize;
            params.pageNum = pageNum;
            if (searchCondition?.communityName) {
                params.communityName = searchCondition?.communityName
            }
            if (searchCondition?.communityAddress) {
                params.communityAddress = searchCondition?.communityAddress
            }
            if (searchCondition?.areaId) {
                params.areaId = searchCondition?.areaId
            }
            params.type = type
            getCommunityPage(params).then((res: any) => {
                setLoading(false);
                if (res?.code === 200) {
                    setTotal(Number(res?.data?.total));
                    setCommunityList(res?.data?.records);
                } else {
                    showFail(res?.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        }

        useEffect(() => {
            handleGetCommunityList(pageNum, pageSize, newSearchCondition);
        }, [pageSize, pageNum])

        const [areaTree, setAreaTree] = useState<any>();
        const [areaTreeTran, setAreaTreeTran] = useState<any>();
        const handleGetArea = () => {
            getAreaTree().then((res) => {
                setAreaTree(res?.data);
                let areaTreeTran = transform(res?.data);
                let _nthAreaTreeTran = areaTreeTran?.children?.find((d: any) => (d?.value === '4'))
                setAreaTreeTran(_nthAreaTreeTran)
            }).catch((err) => {
                console.log(err)
            })
        }


        useEffect(() => {
            handleGetArea();
        }, [])

        const [searchCondition, setSearchCondition] = useState<any>({
            communityId:""
        });
        const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);

        const handleNameChange = (e: any) => {
            setSearchCondition({...searchCondition, hydrantName: e?.target?.value})
        }

        const handleAddressChange = (e: any) => {
            setSearchCondition({...searchCondition, hydrantAddress: e?.target?.value})
        }

        const handleAreaChange = (v: any) => {
            if (v) {
                setSearchCondition({...searchCondition, areaId: v[v?.length - 1]})
            } else {
                setSearchCondition({...searchCondition, areaId: ""})
            }
        }

        const [imageVisible, setImageVisible] = useState<boolean>(false);
        const [communityPictures, setCommunityPictures] = useState<any[]>([]);


        //位置对话框
        const [communityId, setCommunityId] = useState<string>("");
        const [communityMapModalOpen, setCommunityMapModalOpen] = useState(false);
        const handleCloseMapModal = () => {
            setCommunityMapModalOpen(false);
        }

        //联系人对话框
        const [communityContactsModalOpen, setCommunityContactsModalOpen] = useState(false);
        const handleCloseCommunityContactsModal = () => {
            setCommunityContactsModalOpen(false);
        }

        const columns: any = [
            {
                title: '名称',
                dataIndex: 'communityName',
                key: 'communityName',
                fixed: 'left',
                width: 180,
            },
            {
                title: '联系人',
                dataIndex: 'communityContacts',
                key: 'communityContacts',
                width: 100,
                fixed: 'left',
                render: (communityContacts: any, record: any) => (
                    <div>
                        <Button type={'link'} onClick={() => {
                            setCommunityId(record?.communityId);
                            setCommunityContactsModalOpen(true);
                        }
                        }>查看</Button>
                    </div>
                )
            },
            // {
            //     title: '总平面图',
            //     dataIndex: 'communityMapUrl',
            //     key: 'communityMapUrl',
            //     render: (_: any, record: any) => (
            //         <div>{(record?.communityMapUrl) ? (
            //             <Button type={'link'} onClick={() => {
            //                 setCommunityPictures([{
            //                     key: 1,
            //                     src: record?.communityMapUrl
            //                 }]);
            //                 setImageVisible(true);
            //             }}>查看</Button>) : <div>
            //             该社区/企业无平面图
            //         </div>}
            //         </div>
            //     )
            // },
            // {
            //     title: '平面图',
            //     dataIndex: 'floorPictures',
            //     key: 'floorPictures',
            //     render: (floorPictures: any, record: any) => (
            //         <div>{
            //             type===1?(<div />):
            //             (floorPictures&&floorPictures.length>0) ? (
            //             <Button type={'link'} onClick={() => {
            //                 let pictures=floorPictures?.map((f:any)=>{return {
            //                     src:f.url,
            //                     key:f.floorPictureId
            //                 }})
            //                 setCommunityPictures(pictures);
            //                 setImageVisible(true);
            //             }}>查看</Button>) : <div>
            //             该企业无楼层图
            //         </div>}
            //         </div>
            //     )
            // },
            {
                title: '县区及街道',
                dataIndex: 'areaId',
                key: 'areaId',
                render: (areaId: string) =>
                    (<div>{areaTree ? getAreaPath(areaTree, areaId) : "数据加载中"}</div>)
            },
            {
                title: '地址',
                dataIndex: 'communityAddress',
                key: 'communityAddress',
                width: 230,
            },
            {
                title: '类型',
                dataIndex: 'type',
                key: 'type',
                width: 150,
                render: (type: number) => (
                    <>
                        <Tag color={type === 1 ? 'green' :
                            'blue'}>{type === 1 ? '社区' : '企业'}</Tag>
                    </>
                )
            },
            {
                title: '编码',
                dataIndex: 'code',
                key: 'code',
                width: 150,
            },
            {
                title: '房屋幢数',
                dataIndex: 'houseDetail',
                key: 'houseDetail',
                width: 230,
            },
            {
                title: '配电设施',
                dataIndex: 'electricDetail',
                key: 'electricDetail',
                width: 230,
            },
            {
                title: '公众聚众场所',
                dataIndex: 'publicPlace',
                key: 'publicPlace',
                width: 230,
            },
            {
                title: '停车库',
                dataIndex: 'parkingPlace',
                key: 'parkingPlace',
                width: 230,
            },
            {
                title: '小区通道',
                dataIndex: 'communityChannel',
                key: 'communityChannel',
                width: 260,
            },
            {
                title: '内部固定消防设施',
                dataIndex: 'innerTools',
                key: 'innerTools',
                width: 230,
            },
            {
                title: '行驶路线',
                dataIndex: 'travelLine',
                key: 'travelLine',
            },
            {
                title: '行驶距离',
                dataIndex: 'travelDistance',
                key: 'travelDistance',
            },
            {
                title: '行驶时间',
                dataIndex: 'travelTime',
                key: 'travelTime',
            },
            {
                title: '水源备注',
                dataIndex: 'waterMapDescription',
                key: 'waterMapDescription',
            },
            {
                title: '占地面积',
                dataIndex: 'communityArea',
                key: 'communityArea',
            },
            {
                title: '建筑高度',
                dataIndex: 'communityHeight',
                key: 'communityHeight',
            },
            {
                title: '建筑特点',
                dataIndex: 'buildingFeatures',
                key: 'buildingFeatures',
            },
            {
                title: '耐火等级',
                dataIndex: 'fireRate',
                key: 'fireRate',
            },
            {
                title: '使用性质',
                dataIndex: 'natureUse',
                key: 'natureUse',
            },
            {
                title: '总高度',
                dataIndex: 'totalHeight',
                key: 'totalHeight',
            },
            {
                title: '层数',
                dataIndex: 'level',
                key: 'level',
                width: 230,

            },
            {
                title: '毗邻情况(东)',
                dataIndex: 'east',
                key: 'east',
            },
            {
                title: '毗邻情况(西)',
                dataIndex: 'west',
                key: 'west',
            },
            {
                title: '毗邻情况(南)',
                dataIndex: 'south',
                key: 'south',
            },
            {
                title: '毗邻情况(北)',
                dataIndex: 'nouth',
                key: 'nouth',
            },
            {
                title: '距最近消防站行车距离',
                dataIndex: 'minDistance',
                key: 'minDistance',
            },
            {
                title: '重点安全隐患',
                dataIndex: 'keySafety',
                key: 'keySafety',
            },
            {
                title: '描述',
                dataIndex: 'communityRemark',
                key: 'communityRemark',
                width:300
            },
            {
                title: '地图及点位',
                dataIndex: 'location',
                key: 'location',
                render: (_: any, record: any) => (
                    <>
                        {(record?.communityLat && record?.communityLng) ? (
                            <img style={{cursor: 'pointer'}} src={locationIcon} width={20} height={20} alt=""
                                 onClick={() => {
                                     setCommunityMapModalOpen(true);
                                     setCommunityId(record?.communityId)
                                 }}/>
                        ) : (<div>
                            <Tooltip title={'该社区暂无定位'}>
                                <img src={noneIcon} alt="" width={20} height={20}/>
                            </Tooltip>
                        </div>)}
                    </>
                ),
                fixed: 'right',
            },
            {
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                fixed: 'right',
                width: 150,
                render: (_: any, record: any) => (
                    <div>
                        <Button type={'link'} onClick={() => {
                            setCommunityRecord(record);
                            setAddContactModalOpen(true);
                        }}>新增联系人</Button>

                        {type===1&&(
                            <Button type={'link'} onClick={() => {
                                setCommunityRecord(record);
                                setAddPoiModalOpen(true);
                            }}>新增点位</Button>
                        )}

                        <Button type={'link'} onClick={() => {
                            setCommunityRecord(record);
                            setEditModalVisible(true);
                        }}>修改</Button>

                        {isSuperManager && (
                            <Popconfirm title={"是否确认删除该社区"} onConfirm={() => {
                                deleteCommunity({communityId: record?.communityId}).then((res: any) => {
                                    if (res?.code === 200) {
                                        handleGetCommunityList(pageNum, pageSize, newSearchCondition).then(() => {
                                            showSuccess(res?.msg);
                                        })
                                    } else {
                                        showFail(res?.msg)
                                    }
                                }).catch((err) => {
                                    console.log(err)
                                })
                            }}>
                                <Button danger type={'link'} color={'danger'}>删除</Button>
                            </Popconfirm>
                        )}


                    </div>
                )
            },
        ];

        //新增
        const [addType, setAddType] = useState<number>(1);
        const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
        const handleCloseAddModal = () => {
            setAddModalVisible(false);
        }

        //修改
        const [communityRecord, setCommunityRecord] = useState<iCommunity | undefined>();
        const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
        const handleCloseEditModal = () => {
            setEditModalVisible(false);
        }


        //新增联系人
        const [addContactModalOpen, setAddContactModalOpen] = useState<boolean>(false);
        const handelCloseAddContactModal = () => {
            setAddContactModalOpen(false);
        }

        //新增点位
        const [addPoiModalOpen, setAddPoiModalOpen] = useState<boolean>(false);
        const handelCloseAddPoiModal = () => {
            setAddPoiModalOpen(false);
        }

        return (
            <div className={styles.main}>
                <div className={styles.mainHead}>
                    <div className={styles.searchContent}>

                        <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                               onChange={handleNameChange} placeholder={'社区名称'} allowClear/>

                        <Cascader onChange={handleAreaChange} style={{width: 250, marginRight: '30px', marginTop: '10px'}}
                                  options={areaTreeTran ? [areaTreeTran] : []}
                                  changeOnSelect placeholder="县区/街道/乡镇" allowClear/>

                        <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                               onChange={handleAddressChange} placeholder={'社区地址'} allowClear/>

                    </div>
                    <div className={styles.searchButton}>

                        <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                            handleGetCommunityList(1, 10, searchCondition);
                            setPageNum(1);
                            setPageSize(10);
                            setNewSearchCondition(searchCondition)
                        }}>查询
                        </Button>

                        <Button type='primary' style={{marginRight: '20px', marginTop: '10px'}} onClick={() => {
                            // setAddModalVisible(true);

                            setAddType(type);
                            setAddModalVisible(true)

                        }}>新增
                        </Button>

                    </div>

                </div>

                <Table rowKey={'communityId'} scroll={{x: 5000}} columns={columns} dataSource={communityList}
                       loading={loading}
                       pagination={{
                           current: pageNum,
                           onChange: onPageChange,
                           onShowSizeChange: onPageSizeChange,
                           total: total,
                           showSizeChanger: true,
                           defaultPageSize: pageSize,
                           pageSizeOptions: ['10', '20', '50', '100'],
                       }}/>
                <Image.PreviewGroup
                    items={communityPictures}
                    preview={{
                        visible: imageVisible,
                        onVisibleChange: (value) => {
                            setImageVisible(value);
                        },
                    }}
                >
                    {communityPictures.map((image: any) => (
                        <Image
                            key={image.key}
                            width={200}
                            height={200}
                            style={{display: 'none'}}
                            src={image.src}
                            preview={{
                                scaleStep: 0.5,
                                src: image.src,
                            }}
                        />
                    ))}
                </Image.PreviewGroup>

                <CommunityMapModal open={communityMapModalOpen} onCancel={handleCloseMapModal} communityId={communityId}/>

                <CommunityContactsModal open={communityContactsModalOpen} onCancel={handleCloseCommunityContactsModal}
                                        communityId={communityId}/>

                <AddCommunityModal open={addModalVisible} onClose={handleCloseAddModal} areaOptions={[areaTreeTran]}
                                   type={addType}
                                   handleGetCommunity={async () => {
                                       await handleGetCommunityList(pageNum, pageSize, newSearchCondition)
                                   }}/>

                <EditCommunityModal open={editModalVisible} onClose={handleCloseEditModal} areaOptions={[areaTreeTran]}
                                    handleGetCommunity={async () => {
                                        await handleGetCommunityList(pageNum, pageSize, newSearchCondition)
                                    }} community={communityRecord as iCommunity}/>

                <AddNewContactModal open={addContactModalOpen} onCancel={handelCloseAddContactModal}
                                    community={communityRecord as iCommunity} handleGetCommunity={async () => {
                }}/>


                <AddNewPoiModal open={addPoiModalOpen} onCancel={handelCloseAddPoiModal}
                                community={communityRecord as iCommunity}
                                handleGetCommunity={async () => {
                                }}
                />
            </div>
        );
    }
;

export default CommunityComponent;