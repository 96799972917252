import {
    AutoComplete,
    Button,
    Carousel,
    Cascader,
    Form,
    Input,
    Modal,
    Popconfirm,
    Select,
    Table,
    Tag,
    Tooltip,
    Image, Divider, Space
} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import styles from "./index.module.css"
import {iHydrant, iPicture} from "../../../interface";
import {
    addDemand,
    deleteDemand, getAllCompanyNotPage, getAllDemand, updateDemand,
} from "../../../api";
import {
    getAreaPath,
    showFail,
    showNeedCompleteInfo,
    showSuccess,
    showWarning,
    transform
} from "../../../components/functions";
import AddRoadModal from "../../../components/addRoadModal";
// @ts-ignore
import {debounce} from "lodash";
import locationIcon from "../../../assets/icons/location.svg";
import noneIcon from "../../../assets/icons/none.svg";
import LocationModal from "../../../components/locationModal";
import {log} from "node:util";
// @ts-ignore

const Demand = () => {
        const [pageNum, setPageNum] = useState<number>(1);
        const [pageSize, setPageSize] = useState<number>(10);
        const [total, setTotal] = useState<number>(0);
        const [loading, setLoading] = useState<boolean>(false)
        const [locationModalOpen, setLocationModalOpen] = useState(false);

        const [hydrantList, setHydrantList] = useState<iHydrant[]>([])
        const onPageChange = (_page: number) => {
            setPageNum(_page);
        };

        const onPageSizeChange = (_page: number, _pageSize: number) => {
            setPageSize(_pageSize);
        };
        const [hydrantLng, setHydrantLng] = useState<string | number>();
        const [hydrantLat, setHydrantLat] = useState();
        let params: any = {}
        const handleGetCleanDemandList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
            setLoading(true);
            console.log( window.localStorage.getItem('companyId') )
            if ( window.localStorage.getItem('companyId') !== 'undefined') {
                params.companyId = window.localStorage.getItem('companyId')
            }
            if(searchCondition?.demanderPhone!=null){
                params.demanderPhone=searchCondition.demanderPhone
            }
            if(searchCondition?.companyId!=null){
                params.companyId=searchCondition.companyId
            }
            params.pageSize = pageSize;
            params.pageNum = pageNum;
            getAllDemand(params).then((res: any) => {
                setLoading(false);
                if (res?.code === 200) {
                    setTotal(Number(res?.data?.total));
                    setHydrantList(res?.data?.records);
                } else {
                    showFail(res?.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        }

        useEffect(() => {
            handleGetCleanDemandList(pageNum, pageSize);
        }, [pageSize, pageNum])

        const [form] = Form.useForm();


        const [searchCondition, setSearchCondition] = useState({
            companyId: "",
            demanderPhone: "",
        });
        const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);

        const handleCloseLocationModal = () => {
            setLocationModalOpen(false);
        }
        const columns: any = [
            {
                title: '作业单位',
                dataIndex: ['cleanCompany','companyName'],
            },
            {
                title: '需求状态',
                dataIndex: 'demandState',
                key: 'demandState',
            },
            {
                title: '地址',
                dataIndex: 'address',
                key: 'address',
            },
            {
                title: '清运车牌',
                dataIndex: 'carLicense',
                key: 'carLicense',
            },
            {
                title: '清运联系电话',
                dataIndex: 'carPhone',
                key: 'carPhone',
            },
            {
                title: '备注',
                dataIndex: 'remark',
                key: 'remark',
            },
            {
                title: '需求定位',
                dataIndex: 'location',
                key: 'location',
                render: (_: any, record: any) => (
                    <>
                        {(record?.demandLat && record?.demandLng) ? (
                            <img style={{cursor: 'pointer'}} src={locationIcon} width={20} height={20} alt=""
                                 onClick={() => {
                                     setLocationModalOpen(true);
                                     setHydrantLat(record?.demandLat);
                                     setHydrantLng(record?.demandLng);
                                 }}/>
                        ) : (<div>
                            <Tooltip title={'暂无定位'}>
                                <img src={noneIcon} alt="" width={20} height={20}/>
                            </Tooltip>
                        </div>)}
                    </>
                )
            },
            // {
            //     title: '操作',
            //     dataIndex: 'operation',
            //     key: 'operation',
            //     render: (_: any, record: any) => (
            //         <div>
            //             <Popconfirm title={"是否确认删除该公司"} onConfirm={() => {
            //                 deleteDemand({demandId: record?.demandId}).then((res: any) => {
            //                     if (res?.code === 200) {
            //                         handleGetCleanDemandList(pageNum, pageSize, newSearchCondition).then(() => {
            //                             showSuccess(res?.msg);
            //                         })
            //                     } else {
            //                         showFail(res?.msg)
            //                     }
            //                 }).catch((err) => {
            //                     console.log(err)
            //                 })
            //             }}>
            //                 <Button danger type={'link'} color={'danger'}>删除</Button>
            //             </Popconfirm>
            //
            //             <Button  type={'link'} color={'primary'}
            //             onClick={()=>{
            //                 setUpdateModalVisible(true);
            //                 form.setFieldValue('demandName',record.demandName)
            //                 form.setFieldValue('demandId',record.demandId)
            //             }}
            //             >修改</Button>
            //         </div>
            //     )
            // },
        ];

        //新增
        const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
        const handleCloseAddModal = () => {
            setAddModalVisible(false);
        }
        const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);
        const handleCloseUpdateModal = () => {
            setUpdateModalVisible(false);
        }

        const handleUpdateNewDemand = () => {
            form.validateFields().then((value) => {
                let newDemand = {
                    demandName:value?.demandName,
                    demandId:form.getFieldValue('demandId')
                }
                setLoading(true);
                updateDemand(newDemand).then((res: any) => {
                    setLoading(false);
                    console.log(res)
                    if (res?.code === 200) {
                        handleGetCleanDemandList(pageNum, pageSize).then(() => {
                            showSuccess(res?.msg);
                            handleCloseUpdateModal();
                        })
                    } else {
                        showFail(res?.msg)
                    }
                }).catch((err: any) => {
                    console.log(err)
                })

            }).catch((err) => {
                console.log(err);
                showNeedCompleteInfo();
            })
        }

        const [companyList, setCompanyList] = useState<any[]>([]);

        const changeName = (e: any) => {
            let v = e?.target?.value;
            if (v) {
                let params: any = {companyName: v};

                getAllCompanyNotPage(params).then((res: any) => {
                    console.log(res)
                    if (res?.code === 200) {
                        setCompanyList(res?.data?.map((d: any) => {
                            return {
                                value: d?.companyId,
                                label: d.companyName
                            }
                        }))
                    }
                })
            } else {
                showWarning("请输入归属人姓名或手机号")
            }
        }



        return (
            <div className={styles.main}>

                <div className={styles.mainHead}>
                    {/*<Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}} onChange={(e:any)=>{setSearchCondition({demanderPhone: e.target.value,companyId: searchCondition.companyId})}} placeholder={'作业人手机号'} allowClear></Input>*/}
                    { window.localStorage.getItem('companyId') == 'undefined'?
                        <Select
                            style={{width: '280px', marginRight: '30px', marginTop: '10px'}}
                            allowClear={true}
                            placeholder={"请选择归属单位"}
                            onChange={(e)=>{
                                console.log(e)
                                setSearchCondition({demanderPhone: searchCondition.demanderPhone,companyId: e})}
                            }
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider style={{margin: '8px 0'}}/>
                                    <Space style={{padding: '4px 8px 4px '}}>
                                        <Input
                                            allowClear
                                            placeholder="请输入单位名称"
                                            onChange={debounce(changeName, 600)}
                                        />
                                    </Space>
                                </>
                            )}
                            options={companyList}
                        />:''}
                    <div className={styles.searchButton}>

                        <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                            handleGetCleanDemandList(1, 10, searchCondition);
                            setPageNum(1);
                            setPageSize(10);
                            setNewSearchCondition(searchCondition)
                        }}>查询
                        </Button>

                        {/*<Button type='primary' style={{marginRight: '20px', marginTop: '10px'}} onClick={() => {*/}
                        {/*    setAddModalVisible(true);*/}
                        {/*}}>新增*/}
                        {/*</Button>*/}

                    </div>

                </div>

                <Table rowKey={'areaId'} columns={columns} dataSource={hydrantList} loading={loading}
                       pagination={{
                           current: pageNum,
                           onChange: onPageChange,
                           onShowSizeChange: onPageSizeChange,
                           total: total,
                           showSizeChanger: true,
                           defaultPageSize: pageSize,
                           pageSizeOptions: ['10', '20', '50', '100'],
                       }}/>


                {/*<AddRoadModal open={addModalVisible} onClose={handleCloseAddModal} areaOptions={[]}*/}
                {/*              handleGetHydrant={async () => {*/}
                {/*                  await handleGetCleanDemandList(pageNum, pageSize, newSearchCondition)*/}
                {/*              }}/>*/}
                <Modal open={updateModalVisible} onCancel={() => {
                    handleCloseUpdateModal();
                    setLoading(false)
                }} title={'清运公司新增'} maskClosable={false} okText={'确认提交'}
                       onOk={debounce(handleUpdateNewDemand, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                    <Form form={form} name="form" labelAlign="right" labelCol={{
                        xs: {span: 24},
                        sm: {span: 6},
                    }} wrapperCol={{
                        xs: {span: 24},
                        sm: {span: 14},
                    }}>
                        <Form.Item
                            name="demandName"
                            label="公司名称"
                            rules={[
                                {
                                    required: true, message: "请输入公司名称"
                                },
                            ]}
                        >
                            <Input placeholder="请输入公司名称"/>
                        </Form.Item>

                    </Form>
                </Modal>


                <LocationModal open={locationModalOpen} onCancel={handleCloseLocationModal}
                               lat={hydrantLat} lng={hydrantLng}/>

            </div>
        );
    }
;

export default Demand;