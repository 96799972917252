import {
    AutoComplete,
    Button,
    Carousel,
    Cascader,
    Form,
    Input,
    Modal,
    Popconfirm,
    Select,
    Table,
    Tag,
    Tooltip,
    Image, Divider, Space
} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import styles from "./index.module.css"
import {iHydrant, iPicture} from "../../../interface";
import {
    addWork,
    deleteWork, getAllCompanyNotPage, getAllWork, updateWork,
} from "../../../api";
import {
    getAreaPath,
    showFail,
    showNeedCompleteInfo,
    showSuccess,
    showWarning,
    transform
} from "../../../components/functions";
import AddRoadModal from "../../../components/addRoadModal";
// @ts-ignore
import {debounce} from "lodash";
import locationIcon from "../../../assets/icons/location.svg";
import noneIcon from "../../../assets/icons/none.svg";
import LocationModal from "../../../components/locationModal";
import {log} from "node:util";
// @ts-ignore

const Work = () => {
        const [pageNum, setPageNum] = useState<number>(1);
        const [pageSize, setPageSize] = useState<number>(10);
        const [total, setTotal] = useState<number>(0);
        const [loading, setLoading] = useState<boolean>(false)
        const [locationModalOpen, setLocationModalOpen] = useState(false);

        const [hydrantList, setHydrantList] = useState<iHydrant[]>([])
        const onPageChange = (_page: number) => {
            setPageNum(_page);
        };

        const onPageSizeChange = (_page: number, _pageSize: number) => {
            setPageSize(_pageSize);
        };
        const [hydrantLng, setHydrantLng] = useState<string | number>();
        const [hydrantLat, setHydrantLat] = useState();
        let params: any = {}
        const handleGetCleanWorkList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
            setLoading(true);
            console.log( window.localStorage.getItem('companyId') )
            if ( window.localStorage.getItem('companyId') !== 'undefined') {
                params.companyId = window.localStorage.getItem('companyId')
            }
            if(searchCondition?.workerPhone!=null){
                params.workerPhone=searchCondition.workerPhone
            }
            if(searchCondition?.companyId!=null){
                params.companyId=searchCondition.companyId
            }
            params.pageSize = pageSize;
            params.pageNum = pageNum;
            getAllWork(params).then((res: any) => {
                setLoading(false);
                if (res?.code === 200) {
                    setTotal(Number(res?.data?.total));
                    setHydrantList(res?.data?.records);
                } else {
                    showFail(res?.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        }

        useEffect(() => {
            handleGetCleanWorkList(pageNum, pageSize);
        }, [pageSize, pageNum])

         const [form] = Form.useForm();


        const [searchCondition, setSearchCondition] = useState({
            companyId: "",
            workerPhone: "",
        });
        const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);

        const handleCloseLocationModal = () => {
            setLocationModalOpen(false);
        }
        const columns: any = [
            {
                title: '作业单位',
                dataIndex: ['cleanCompany','companyName'],
            },
            {
                title: '垃圾重量',
                dataIndex: 'garbageWeight',
                key: 'garbageWeight',
            },
            {
                title: '垃圾类型',
                dataIndex: 'garbageType',
                key: 'garbageType',
            },
            {
                title: '作业状态',
                dataIndex: 'workState',
                key: 'workState',
            },
            {
                title: '作业车辆',
                dataIndex: ['cleanCar','carLicense'],
            },
            {
                title: '作业人员',
                dataIndex: 'workerPhone',
            },
            {
                title: '作业开始时间',
                dataIndex: 'workStartTime',
                key: 'workStartTime',
            },
            {
                title: '作业结束时间',
                dataIndex: 'workEndTime',
                key: 'workEndTime',
            },
            {
                title: '作业开始定位',
                dataIndex: 'workEndTime',
                key: 'workEndTime',
            },
            {
                title: '作业结束定位',
                dataIndex: 'workEndTime',
                key: 'workEndTime',
            },
            {
                title: '开始作业定位',
                dataIndex: 'location',
                key: 'location',
                render: (_: any, record: any) => (
                    <>
                        {(record?.startLat && record?.startLng) ? (
                            <img style={{cursor: 'pointer'}} src={locationIcon} width={20} height={20} alt=""
                                 onClick={() => {
                                     setLocationModalOpen(true);
                                     setHydrantLat(record?.startLat);
                                     setHydrantLng(record?.startLng);
                                 }}/>
                        ) : (<div>
                            <Tooltip title={'暂无定位'}>
                                <img src={noneIcon} alt="" width={20} height={20}/>
                            </Tooltip>
                        </div>)}
                    </>
                )
            },
            {
                title: '结束作业定位',
                dataIndex: 'location',
                key: 'location',
                render: (_: any, record: any) => (
                    <>
                        {(record?.endLng && record?.endLat) ? (
                            <img style={{cursor: 'pointer'}} src={locationIcon} width={20} height={20} alt=""
                                 onClick={() => {
                                     setLocationModalOpen(true);
                                     setHydrantLat(record?.endLng);
                                     setHydrantLng(record?.endLat);
                                 }}/>
                        ) : (<div>
                            <Tooltip title={'暂无定位'}>
                                <img src={noneIcon} alt="" width={20} height={20}/>
                            </Tooltip>
                        </div>)}
                    </>
                )
            },
            // {
            //     title: '操作',
            //     dataIndex: 'operation',
            //     key: 'operation',
            //     render: (_: any, record: any) => (
            //         <div>
            //             <Popconfirm title={"是否确认删除该公司"} onConfirm={() => {
            //                 deleteWork({workId: record?.workId}).then((res: any) => {
            //                     if (res?.code === 200) {
            //                         handleGetCleanWorkList(pageNum, pageSize, newSearchCondition).then(() => {
            //                             showSuccess(res?.msg);
            //                         })
            //                     } else {
            //                         showFail(res?.msg)
            //                     }
            //                 }).catch((err) => {
            //                     console.log(err)
            //                 })
            //             }}>
            //                 <Button danger type={'link'} color={'danger'}>删除</Button>
            //             </Popconfirm>
            //
            //             <Button  type={'link'} color={'primary'}
            //             onClick={()=>{
            //                 setUpdateModalVisible(true);
            //                 form.setFieldValue('workName',record.workName)
            //                 form.setFieldValue('workId',record.workId)
            //             }}
            //             >修改</Button>
            //         </div>
            //     )
            // },
        ];

        //新增
        const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
        const handleCloseAddModal = () => {
            setAddModalVisible(false);
        }
    const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);
    const handleCloseUpdateModal = () => {
        setUpdateModalVisible(false);
    }

    const handleUpdateNewWork = () => {
        form.validateFields().then((value) => {
            let newWork = {
                workName:value?.workName,
                workId:form.getFieldValue('workId')
            }
            setLoading(true);
            updateWork(newWork).then((res: any) => {
                setLoading(false);
                console.log(res)
                if (res?.code === 200) {
                    handleGetCleanWorkList(pageNum, pageSize).then(() => {
                        showSuccess(res?.msg);
                        handleCloseUpdateModal();
                    })
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                console.log(err)
            })

        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }

    const [companyList, setCompanyList] = useState<any[]>([]);

    const changeName = (e: any) => {
        let v = e?.target?.value;
        if (v) {
            let params: any = {companyName: v};

            getAllCompanyNotPage(params).then((res: any) => {
                console.log(res)
                if (res?.code === 200) {
                    setCompanyList(res?.data?.map((d: any) => {
                        return {
                            value: d?.companyId,
                            label: d.companyName
                        }
                    }))
                }
            })
        } else {
            showWarning("请输入归属人姓名或手机号")
        }
    }



        return (
            <div className={styles.main}>

                <div className={styles.mainHead}>
                    <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}} onChange={(e:any)=>{setSearchCondition({workerPhone: e.target.value,companyId: searchCondition.companyId})}} placeholder={'作业人手机号'} allowClear></Input>
                    { window.localStorage.getItem('companyId') == 'undefined'?
                    <Select
                        style={{width: '280px', marginRight: '30px', marginTop: '10px'}}
                        allowClear={true}
                        placeholder={"请选择归属单位"}
                        onChange={(e)=>{
                            console.log(e)
                            setSearchCondition({workerPhone: searchCondition.workerPhone,companyId: e})}
                        }
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider style={{margin: '8px 0'}}/>
                                <Space style={{padding: '4px 8px 4px '}}>
                                    <Input
                                        allowClear
                                        placeholder="请输入单位名称"
                                        onChange={debounce(changeName, 600)}
                                    />
                                </Space>
                            </>
                        )}
                        options={companyList}
                    />:''}
                    <div className={styles.searchButton}>

                        <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                            handleGetCleanWorkList(1, 10, searchCondition);
                            setPageNum(1);
                            setPageSize(10);
                            setNewSearchCondition(searchCondition)
                        }}>查询
                        </Button>

                        {/*<Button type='primary' style={{marginRight: '20px', marginTop: '10px'}} onClick={() => {*/}
                        {/*    setAddModalVisible(true);*/}
                        {/*}}>新增*/}
                        {/*</Button>*/}

                    </div>

                </div>

                <Table rowKey={'areaId'} columns={columns} dataSource={hydrantList} loading={loading}
                       pagination={{
                           current: pageNum,
                           onChange: onPageChange,
                           onShowSizeChange: onPageSizeChange,
                           total: total,
                           showSizeChanger: true,
                           defaultPageSize: pageSize,
                           pageSizeOptions: ['10', '20', '50', '100'],
                       }}/>


                {/*<AddRoadModal open={addModalVisible} onClose={handleCloseAddModal} areaOptions={[]}*/}
                {/*                 handleGetHydrant={async () => {*/}
                {/*                     await handleGetCleanWorkList(pageNum, pageSize, newSearchCondition)*/}
                {/*                 }}/>*/}
                <Modal open={updateModalVisible} onCancel={() => {
                            handleCloseUpdateModal();
                            setLoading(false)
                        }} title={'清运公司新增'} maskClosable={false} okText={'确认提交'}
                               onOk={debounce(handleUpdateNewWork, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                            <Form form={form} name="form" labelAlign="right" labelCol={{
                                xs: {span: 24},
                                sm: {span: 6},
                            }} wrapperCol={{
                                xs: {span: 24},
                                sm: {span: 14},
                            }}>
                                <Form.Item
                                    name="workName"
                                    label="公司名称"
                                    rules={[
                                        {
                                            required: true, message: "请输入公司名称"
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入公司名称"/>
                                </Form.Item>

                            </Form>
                </Modal>


                <LocationModal open={locationModalOpen} onCancel={handleCloseLocationModal}
                               lat={hydrantLat} lng={hydrantLng}/>

            </div>
        );
    }
;

export default Work;