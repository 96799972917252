import {
    Button,
    DatePicker,
    Divider,
    Drawer,
    Form,
    Input,
    InputNumber, message,
    Modal,
    Select,
    Space,
    Table,
    Tooltip
} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
// @ts-ignore
import {debounce} from "lodash"
import {showFail, showNeedCompleteInfo, showSuccess} from "../functions";
import {addRegularInspectPlan, getUserList, updateRegularPlan} from "../../api";
import {RangePickerProps} from 'antd/es/date-picker';
import AddInspectRecordModal from "../addInspectRecord";
import {iHydrant, iRegularInspectPlan} from "../../interface";
import {ExclamationCircleFilled} from '@ant-design/icons';
import moment from "moment";
import dayjs from "dayjs";

const UpdateRegularInspectPlanModal = ({
                                           open,
                                           onClose,
                                           handleGetRegularInspectPlan,
                                           regularPlan
                                       }: { open: boolean, onClose: () => void, handleGetRegularInspectPlan: any, regularPlan: iRegularInspectPlan }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (open) {
            form.resetFields();
        }
    }, [open])

    const [userOptions, setUserOptions] = useState<any[]>([]);
    const changeName = (e: any) => {
        let username = e.target.value
        if (username) {
            getUserList({username}).then((res: any) => {
                let _user = res?.data?.map((d: any) => {
                    return {
                        label: `姓名:${d?.username}  手机号:${d?.mobile}`,
                        value: d?.userId
                    }
                })
                setUserOptions(_user)
            }).catch((err: any) => {
                console.log(err);
            })
        }
    }


    useEffect(() => {
        if (regularPlan?.userId) {
            getUserList({userId: regularPlan?.userId}).then((res: any) => {
                let _user = res?.data?.map((d: any) => {
                    return {
                        label: `姓名:${d?.username}  手机号:${d?.mobile}`,
                        value: d?.userId
                    }
                })
                setUserOptions(_user)
            }).catch((err: any) => {
                console.log(err);
            })
        }
    }, [open])

    const handlePlanStartTimeChange = (date: any) => {
        if (date) {
            let startTime = date?.$d?.valueOf();
            const now = new Date();
            const nextHour = new Date(now.getTime() + 60 * 60 * 1000);
            if (startTime > nextHour) {
                form.setFieldsValue({regularStartTime: date})
            } else {
                let nextHourStr = moment(nextHour).format('YYYY-MM-DD HH:mm:ss')
                message.warning({content: `首次任务开始时间必须晚于${nextHourStr}(当前时间的后一小时)`, duration: 2.5})
                form.setFieldsValue({regularStartTime: ""})
            }
        } else {
            form.setFieldsValue({regularStartTime: ""})
        }
    }

    const [cycle, setCycle] = useState<number | null>(regularPlan?.cycle);
    const handleCycleChange = (n: number | null) => {
        if (n) {
            if (executionDay) {
                if (executionDay > n) {
                    setCycle(null);
                    form.setFieldsValue({cycle: null})
                    message.warning({
                        content: `计划周期不能小于任务持续时间(${executionDay}天)`,
                        duration: 2.5
                    })
                } else {
                    setCycle(n);
                    form.setFieldsValue({cycle: n})
                }
            } else {
                setCycle(n);
                form.setFieldsValue({cycle: n})
            }
        } else {
            setCycle(null);
            form.setFieldsValue({cycle: null})
        }

    }

    const [executionDay, setExecutionDay] = useState<number | null>(regularPlan?.executionDay);
    const handleExecutionDayChange = (n: number | null) => {
        if (n) {
            if (cycle) {
                if (cycle < n) {
                    setExecutionDay(null);
                    form.setFieldsValue({executionDay: null})
                    message.warning({
                        content: `任务持续时间不能大于计划周期(${cycle}天)`,
                        duration: 2.5
                    })
                } else {
                    setExecutionDay(n);
                    form.setFieldsValue({executionDay: n})
                }
            } else {
                setExecutionDay(n);
                form.setFieldsValue({executionDay: n})
            }
        } else {
            setExecutionDay(null);
            form.setFieldsValue({executionDay: null})
        }
    }


    const [loading, setLoading] = useState<boolean>(false);

    const pushData = (values: any) => {
        setLoading(true);
        updateRegularPlan(values).then((res: any) => {
            setLoading(false);
            if (res?.code === 200) {
                handleGetRegularInspectPlan().then(() => {
                    showSuccess(res?.msg);
                    onClose();
                    form.resetFields();
                    setUserOptions([]);
                })
            } else {
                showFail(res?.msg)
            }
        }).catch((err: any) => {
            console.log(err)
        })
    }

    const handleAddNewRegularInspectPlan = () => {
        form.validateFields().then((value) => {
            let updatedInspectPlan = {
                ...value,
                regularInspectionId: regularPlan?.regularInspectionId,
                regularStartTime: value?.regularStartTime?.$d.valueOf(),
            }
            pushData(updatedInspectPlan);
        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }


    return (
        <div>
            <Modal width={'60vw'} open={open} onCancel={() => {
                onClose();
                setLoading(false);
                setUserOptions([])
            }} title={'定期计划信息修改'} maskClosable={false}
                   onOk={debounce(handleAddNewRegularInspectPlan, 100)}
                   okButtonProps={{loading: loading}}
                   cancelButtonProps={{style: {display: 'none'}}}
                   okText={'确认修改'} destroyOnClose
            >
                <Form form={form} name="form" labelAlign="right" labelCol={{
                    xs: {span: 24},
                    sm: {span: 6},
                }} wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 14},
                }}>
                    <Form.Item
                        name="name"
                        label="计划名称"
                        initialValue={regularPlan?.name}
                        rules={[
                            {
                                required: true, message: "请输入计划名称"
                            },
                        ]}
                    >
                        <Input placeholder="请输入计划名称"/>
                    </Form.Item>

                    <Form.Item
                        name="cycle"
                        label="周期(天)"
                        initialValue={regularPlan?.cycle}
                        rules={[
                            {
                                required: true, message: "请输入计划执行周期"
                            },
                        ]}
                    >
                        <InputNumber addonAfter="天" min={0} placeholder="请输入计划执行周期" style={{width: "300px"}}
                                     onChange={debounce(handleCycleChange, 1000)}/>
                    </Form.Item>

                    <Form.Item
                        name="regularStartTime"
                        label="首次任务开始时间"
                        initialValue={dayjs(regularPlan?.regularStartTime, 'YYYY-MM-DD HH:mm:ss')}
                        rules={[
                            {
                                required: true, message: "请选择首次任务开始时间"
                            },
                        ]}
                    >
                        <DatePicker placeholder={'请选择首次任务开始时间'} style={{width: "100%"}} showTime={{format: 'HH'}}
                                    format="YYYY-MM-DD HH:00" onChange={handlePlanStartTimeChange}
                                    disabled={regularPlan?.state !== 0}
                        />
                    </Form.Item>

                    {regularPlan?.state !== 0 && (
                        <Form.Item label="说明"
                        >
                            <div style={{color:'#be9f4e'}}>当前计划已发布, 不可修改首次任务开始时间</div>
                        </Form.Item>
                    )}

                    <Form.Item
                        name="executionDay"
                        label="任务持续时间(天)"
                        initialValue={regularPlan?.executionDay}
                        rules={[
                            {
                                required: true, message: "请输入巡检任务的执行时间(天)"
                            },
                        ]}
                    >
                        <InputNumber addonAfter="天" min={0} onChange={debounce(handleExecutionDayChange, 1000)}
                                     placeholder="请输入一次巡检任务的执行时间" style={{width: "300px"}}/>
                    </Form.Item>

                    <Form.Item
                        name="userId"
                        label="负责人"
                        rules={[
                            {
                                required: true, message: "请选择任务负责人"
                            },
                        ]}
                        initialValue={regularPlan?.userId}
                    >
                        <Select
                            allowClear={true}
                            placeholder={"请输入用户名查询并选择"}
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider style={{margin: '8px 0'}}/>
                                    <Space style={{padding: '4px 8px 4px '}}>
                                        <Input
                                            allowClear
                                            placeholder="请输入用户名"
                                            onChange={debounce(changeName, 600)}
                                        />

                                    </Space>
                                </>
                            )}
                            options={userOptions}
                        />
                    </Form.Item>


                    <Form.Item
                        name="taskRemark"
                        label="备注"
                    >
                        <Input placeholder="请输入任务备注"/>
                    </Form.Item>

                </Form>

            </Modal>
        </div>
    );
};

export default UpdateRegularInspectPlanModal;