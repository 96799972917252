import React, {useEffect, useState} from 'react';
import {
    Button,
    Divider,
    Input,
    message,
    Modal,
    Popconfirm,
    Select,
    Space,
    Switch,
    Table,
    TreeSelect,
    Row,
    Form, Spin, InputNumber
} from "antd";
import {AxiosError} from "axios";
import {PlusOutlined, TeamOutlined} from "@ant-design/icons";
import {
    addArea,
    getAreaTree,
    deleteArea,
    updateArea,
     searchByMobile, searchUserById
} from "../../../api";
const { Option } = Select;

//菜单管理
const AreaManagement = () => {

    const [form] = Form.useForm()
    const [form2] = Form.useForm()
    interface areatree {
        value?: string,
        title?: string,
        children?: any[],
    }
    //保存数据
    const onAddArea = (e:any) =>{

            setAddAreaS(true)

          let params = e
            console.log(params)
            addArea(params).then( (res: any) => {
                if (/^5[0-9][0-9]/.test(res.code)) {
                    message.error(res.msg);
                    form2.resetFields()
                    setAddAreaS(false)
                } else {
                    form2.resetFields()
                    message.success(res.msg);
                    setIsAddModalOpen(false);
                    setAddAreaS(false)
                    onGetArea();
                    // window.location.reload()
                }
            }, (err: AxiosError) => {
                console.log(err);
                setAddAreaS(false)
            })
        }

    const [parentId,setParentId]=useState();
    const [addAreaS,setAddAreaS]=useState<boolean>(false)
    const [updateAreaS,setUpdateAreaS]=useState<boolean>(false)
    const [areaTree, setAreaTree] = useState<any>([]);
    const [areas, setAreas] = useState<any>([]);
    function tree(list:areatree[],res:any[]){
        console.log(res)
        for (let i = 0; i < res.length; i++) {
            if(res[i].children){
                // @ts-ignore
                list.push({value: res[i].areaId, title: res[i].name, children: []})
            }else {
                // @ts-ignore
                list.push({value: res[i].areaId, title: res[i].name})
            }
            // @ts-ignore
            if(res[i].children) {
                // @ts-ignore
                tree(list[i].children, res[i].children)
            }
        }
        return list
    }
    //查询区域Tree

    const [sysDepartUsers,setSysDepartUsers]=useState<any[]>([])
    const [sysCompanyUsers,setSysCompanyUsers]=useState<any[]>([])
    const [sysAssetMangers,setSysAssetMangers]=useState<any[]>([])

    useEffect(()=>{

        let params = {}
        getAreaTree(params).then( (res:any)=>{
            setAreaTree(res.data);
            let a:areatree[]=[]
            let b=[]
            b.push(res.data)
            let list=tree(a,b)
           setAreas(list)
            console.log(list)
        }, (err:AxiosError)=>{
            console.log(err);
        });


    },[])

    const onGetArea = () => {
        let params = {}
       getAreaTree(params).then( (res:any)=>{
            setAreaTree(res.data);
            console.log(res.data)
        }, (err:AxiosError)=>{
            console.log(err);
        });
    }


    //新增对话框
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const handleAddCancel = () => {
        setIsAddModalOpen(false);
    };
    const [currentData, setCurrentData] = useState<any>('');
    //新增区域
    const [addName, setAddName] = useState<string>('');

    const handleChange=(e:any)=>{
        setParentId(e)
        console.log(e)
    }


    //删除区域
    const onDeleteArea = (val: any)=>{
        let params = {
            areaId: val.areaId,
        }
        console.log(params)

        deleteArea(params).then ((res:any)=> {
            if (/^4[0-9][0-9]/.test(res.code)) {
                message.error(res.msg);
            }else {
                message.success(res.msg);
                onGetArea();
            }
        }, (err:AxiosError)=>{
            console.log(err);
        })
    }

    //修改对话框
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

    const handleUpdateCancel = () => {
        setIsUpdateModalOpen(false);
    };

    const onUpdateArea = (e:any) =>{
        setUpdateAreaS(true)
        let params=e
         params.areaId  = currentData.areaId;
        console.log(params)
        updateArea(params).then ((res:any)=> {
            if (res.code==200) {
                message.success(res.msg);
                setUpdateAreaS(false)
                setIsUpdateModalOpen(false);
                onGetArea();
            }else {
                message.error(res.msg);
                setUpdateAreaS(false)
            }
        }, (err:AxiosError)=>{
            setUpdateAreaS(false)
            console.log(err);
        })
    };


    //表头数据
    // @ts-ignore
    const Columns = [
        {
            title: '区域名称',
            dataIndex: 'name',

        },
        {
            title: '操作',
            dataIndex: 'operate',
            render: (_: any, record: any,index:any) =>(

                    <Space>
                        {record.areaType!=0?
                        <Popconfirm title="确认删除吗?" onConfirm={() => onDeleteArea(record)}>
                            <a>删除</a>
                        </Popconfirm>:''}
                        {record.areaType!=0?
                        <a onClick={()=> {
                            console.log(record)
                            form.setFieldsValue(
                                { name:record.name,
                                    companyUserId:record.companyUserId,
                                    departUserId:record.departUserId,
                                    assetManagerId:record.assetManagerId,
                                    budgetAmount:record.budgetAmount,
                                    remainingAmount:record.remainingAmount,
                                    limitAmount:record.limitAmount,
                                //
                                })
                            setCurrentData(record);
                            setIsUpdateModalOpen(true);
                            searchUserById({userId:record.departUserId}).then((res:any)=>{
                                let x=[]
                                x.push(res.data)
                                setSysDepartUsers(x)
                            })
                            searchUserById({userId:record.companyUserId}).then((res:any)=>{
                                let x=[]
                                x.push(res.data)
                                setSysCompanyUsers(x)
                            })
                            searchUserById({userId:record.assetManagerId}).then((res:any)=>{
                                let x=[]
                                x.push(res.data)
                                setSysAssetMangers(x)
                            })
                        }}>修改</a>:''}
                    </Space>
            )
        },

    ]

    return (
        <div className='user_management_layout'>
            <div className='user_management_header' style={{marginBottom: '12px' }}><TeamOutlined/> 区域管理</div>
            <Divider style={{margin: '0' }}/>
            <div className='user_management_add'>
                <Button onClick={()=>{setIsAddModalOpen(true)}} type="primary" ><PlusOutlined />新增</Button>
            </div>
            {/*<Space align="center" style={{ marginBottom: 16 }}>*/}
            {/*    CheckStrictly: <Switch checked={checkStrictly} onChange={setCheckStrictly} />*/}
            {/*</Space>*/}
            <Table
                rowKey="areaId"
                columns={Columns}
                dataSource={[areaTree]}
                defaultExpandAllRows
            />

            {/*<AddModal record={currentData} onCancel={handleAddCancel} open={isAddModalOpen}/>*/}




            <Modal
                title='新增区域'
                open={isAddModalOpen}
                onCancel={handleAddCancel}
                // onOk={onAddArea}
                footer={[]}
            >
                <Spin spinning={addAreaS}>
                    <Form
                        onFinish={(e:any)=>onAddArea(e)}
                        name="basic"
                        form={form2}
                    >
                        <Form.Item name={'name'} label={"区域名"} rules={[{ required: true, message: '请输入区域名！' }]}><Input  style={{width: '300px', marginBottom: '10px'}} onChange={(e)=>setAddName(e.target.value)} /></Form.Item>
                        <Form.Item name={'parentId'} label={"归属区域"} rules={[{ required: true, message: '请输入归属区域！' }]}><TreeSelect
                                showSearch
                                style={{ width: '100%' }}
                                // value={areaTree}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                placeholder="归属区域"
                                allowClear
                                treeDefaultExpandAll
                                onChange={(e)=>handleChange(e)}
                                treeData={areas}
                            /></Form.Item>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form>
                </Spin>
            </Modal>

            {/*<UpdateModal record={currentData} onCancel={handleUpdateCancel} open={isUpdateModalOpen}/>*/}
            <Modal
                title='修改区域'
                open={isUpdateModalOpen}
                onCancel={handleUpdateCancel}
                // onOk={onUpdateArea}
                footer={[]}
            >
                <Spin spinning={updateAreaS}>
                    <Form
                        name="basic"
                        form={form}
                        onFinish={(e:any)=>onUpdateArea(e)}
                    >
                        <Form.Item name={'name'} label={"区域名"}><Input  style={{width: '300px', marginBottom: '10px'}}  /></Form.Item>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form>
                </Spin>
            </Modal>
        </div>
    );
};

export default AreaManagement;