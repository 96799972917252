import React from 'react';
import Router from "./Router";
import { HashRouter } from 'react-router-dom';
import './App.css'
import zh_CN from 'antd/es/locale/zh_CN';
import {ConfigProvider} from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

const App = () => {
  return (
      <HashRouter>
        <ConfigProvider locale={zh_CN}>
          <Router />
        </ConfigProvider>
      </HashRouter>
  );
};

export default App;