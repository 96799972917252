import React, {useEffect, useState} from 'react';
import {Input, Modal, Button, message} from "antd";
import s from "./index.module.css";
import {Map, Marker} from "react-amap";
import {getMapAddress, getMapLoc} from "../../api";
import {showWarning} from "../functions";

const PickLocationModal = ({loc, open, onCancel, confirmLoc, confirmName, confirmRoad, confirmAddress, repick}) => {
    const [searchedLocs, setSearchedLocs] = useState([]);
    const [centerLoc, setCenterLoc] = useState({
        lng: loc?.lng,
        lat: loc?.lat
    });
    useEffect(() => {
        setCenterLoc(loc);
    }, [loc])
    const [pointedLoc, setPointedLoc] = useState(repick ? loc : {lng: 0, lat: 0});
    const [locSearchLoading, setLocSearchLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [showResArea, setShowResArea] = useState(false);
    // const [total, setTotal] = useState(0);
    const handleSearchChange = (e) => {
        setSearchText(e?.target?.value);
    }
    return (
        <Modal cancelText="取消" okText="确定" onOk={() => {
            if (pointedLoc?.lng !== 0) {
                confirmLoc(pointedLoc);
                getMapAddress(pointedLoc).then((res) => {
                    console.log('address', res)
                    if (res?.data?.result) {
                        if (res?.data?.result?.address_component?.street) {
                            confirmRoad(res?.data?.result?.address_component?.street)
                        } else {
                            showWarning("获取道路失败")
                        }

                        if (res?.data?.result?.formatted_addresses?.standard_address) {
                            confirmAddress(res?.data?.result?.formatted_addresses?.standard_address)
                        } else {
                            showWarning("获取详细地址失败")
                        }

                        if (res?.data?.result?.formatted_addresses?.recommend) {
                            confirmName(res?.data?.result?.formatted_addresses?.recommend)
                        } else {
                            showWarning("获取XHS名称失败")
                        }
                    }
                })

                onCancel();
            } else {
                message.warning("请选择位置!")
            }
        }} title="定位" width="50vw" open={open} onCancel={() => {
            setSearchedLocs([]);
            if (!repick) {
                setCenterLoc({lng: 120.09, lat: 30.88});
                setPointedLoc({lng: 0, lat: 0});
            }
            setSearchText("");
            onCancel();
        }}>
            <div style={{width: '100%', height: "60vh"}}>
                <div className={s.locSearchBar}>
                    <Input.Search
                        loading={locSearchLoading}
                        placeholder="请输入地址"
                        allowClear
                        value={searchText}
                        enterButton="查询"
                        onChange={handleSearchChange}
                        onSearch={() => {

                            //高德地图请求(关键词搜索调用只有100次)
                            // axios.get(`https://restapi.amap.com/v3/place/text?keywords=${searchText}&city=${"湖州"}&offset=5&page=1&key=b78db98d39643185beaf67d97c73d661&extensions=all`)
                            //     .then((res) => {
                            //         console.log(res.data);
                            //         setShowResArea(true);
                            //         setLocSearchLoading(false);
                            //         setSearchedLocs(res?.data?.pois);
                            //         // setTotal(res?.data?.)
                            //     }).catch((err) => {
                            //     console.log(err)
                            // })
                            // if (searchText) {
                            //     //百度地图请求(经纬度坐标系和高德地图不一致)
                            //     axios.get(`/getloc/place/v2/suggestion?query=${searchText}&region=%E6%B9%96%E5%B7%9E&output=json&ak=yXBH7IWOeGHWS56YcW8jYIdx1CqohTck`)
                            //         .then((res) => {
                            //             setShowResArea(true);
                            //             setLocSearchLoading(false);
                            //             setSearchedLocs(res?.data?.result.splice(0, 6));
                            //             setTotal(res?.data?.result?.length);
                            //         }).catch((err) => {
                            //         console.log(err)
                            //     })
                            // }
                            if (searchText) {
                                setLocSearchLoading(true);
                                //腾讯地图请求
                                getMapLoc({searchText}).then((res) => {
                                    setShowResArea(true);
                                    setLocSearchLoading(false);
                                    if (res?.code === 200) {
                                        setSearchedLocs(res?.data?.data);
                                    } else {
                                        message.error(res?.msg)
                                    }
                                }).catch((err) => {
                                    console.log(err)
                                })
                            } else {
                                message.warning("请输入搜索内容!")
                            }
                        }}
                    />
                </div>
                <div className={s.pickLoc}>
                    定位: {pointedLoc?.lat === 0 ? "暂无定位, 请在地图上点击确定" : `经度${pointedLoc?.lng}°, 纬度${pointedLoc?.lat}°`}
                </div>
                <div className={s.map}>
                    {showResArea && (<div className={s.resultList}>
                        <div className={s.resultTitle}>查询结果</div>
                        <div className={s.mainList}>
                            {searchedLocs?.length === 0 ? (<div style={{
                                height: "100%",
                                width: '100%',
                                display: "grid"
                            }}>无对应地点</div>) : searchedLocs?.map((loc, index) => (
                                <div className={s.listItem} key={index} onClick={() => {
                                    setPointedLoc({lng: loc?.location?.lng, lat: loc?.location?.lat});
                                    setCenterLoc({lng: loc?.location?.lng, lat: loc?.location?.lat});
                                    setShowResArea(false);
                                }}>
                                    {loc?.title} (地址:{loc?.address})
                                </div>))}
                        </div>
                        <div className={s.cancelButton}>
                            <Button type="primary" size="small" onClick={() => {
                                setShowResArea(false)
                            }}>关闭</Button></div>
                    </div>)}

                    <Map events={{
                        click: (v) => {
                            setPointedLoc({lng: v?.lnglat?.lng, lat: v?.lnglat?.lat});
                            setCenterLoc({lng: v?.lnglat?.lng, lat: v?.lnglat?.lat});
                        }
                    }}
                         center={{longitude: centerLoc?.lng, latitude: centerLoc?.lat}}
                         zoom={17}
                    >
                        {pointedLoc?.lat !== 0 && (
                            <Marker position={{longitude: pointedLoc?.lng, latitude: pointedLoc?.lat}}/>
                        )}
                    </Map>
                </div>
            </div>
        </Modal>
    )
}

export default PickLocationModal;