import {
    Button,
    Input,
    Popconfirm,
    Table,
    Tooltip,
} from 'antd';
import React, {useEffect, useState} from 'react';
import styles from "./index.module.css"
import {
     deleteSite,
    getSitePage
} from "../../../api";
import {showFail, showSuccess} from "../../../components/functions";
import locationIcon from "../../../assets/icons/location.svg"
import noneIcon from "../../../assets/icons/none.svg"

// @ts-ignore
import {debounce} from "lodash";
import AddSiteModal from "../../../components/addSiteModal";
import LocationModal from '../../../components/locationModal';
import EditSiteModal from "../../../components/editSiteModal";

const Sites = () => {
        const isSuperManager = JSON.parse(window.localStorage?.getItem("isSuperManager") as string);

        const [pageNum, setPageNum] = useState<number>(1);
        const [pageSize, setPageSize] = useState<number>(10);
        const [total, setTotal] = useState<number>(0);
        const [loading, setLoading] = useState<boolean>(false)


        const [siteList, setSiteList] = useState<any[]>([])
        const onPageChange = (_page: number) => {
            setPageNum(_page);
        };

        const onPageSizeChange = (_page: number, _pageSize: number) => {
            setPageSize(_pageSize);
        };

        let params: any = {}
        const handleGetSiteList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
            setLoading(true);
            params.pageSize = pageSize;
            params.pageNum = pageNum;
            if (searchCondition?.siteName) {
                params.siteName = searchCondition?.siteName
            }
            getSitePage(params).then((res: any) => {
                setLoading(false);
                if (res?.code === 200) {
                    setTotal(Number(res?.data?.total));
                    setSiteList(res?.data?.records);
                } else {
                    showFail(res?.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        }

        useEffect(() => {
            handleGetSiteList(pageNum, pageSize, newSearchCondition);
        }, [pageSize, pageNum])

        const [searchCondition, setSearchCondition] = useState<any>({
            siteName: ""
        });
        const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);

        const handleNameChange = (e: any) => {
            setSearchCondition({...searchCondition, siteName: e?.target?.value})
        }


        //位置对话框
        const [siteLng, setSiteLng] = useState<string | number>();
        const [siteLat, setSiteLat] = useState();
        const [locationModalOpen, setLocationModalOpen] = useState(false);
        const handleCloseLocationModal = () => {
            setLocationModalOpen(false);
        }

        const columns: any = [
            {
                title: '名称',
                dataIndex: 'siteName',
                key: 'siteName',
            },
            {
                title: '管理员手机号',
                dataIndex: 'managerMobile',
                key: 'managerMobile',
            },
            {
                title: '管理员密码',
                dataIndex: 'managerPassword',
                key: 'managerPassword',
            },
            {
                title: '定位',
                dataIndex: 'location',
                key: 'location',
                render: (_: any, record: any) => (
                    <>
                        {(record?.siteLat && record?.siteLng) ? (
                            <img style={{cursor: 'pointer'}} src={locationIcon} width={20} height={20} alt=""
                                 onClick={() => {
                                     setLocationModalOpen(true);
                                     setSiteLat(record?.siteLat);
                                     setSiteLng(record?.siteLng);
                                 }}/>
                        ) : (<div>
                            <Tooltip title={'该处置单位暂无定位'}>
                                <img src={noneIcon} alt="" width={20} height={20}/>
                            </Tooltip>
                        </div>)}
                    </>
                )
            },
            {
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                fixed: 'right',
                width: 150,
                render: (_: any, record: any) => (
                    <div>

                        <Button type={'link'} onClick={() => {
                            setSiteRecord(record);
                            setEditModalVisible(true);
                        }}>修改</Button>

                        {isSuperManager && (
                            <Popconfirm title={"是否确认删除该处置单位"} onConfirm={() => {
                                deleteSite({siteId: record?.siteId}).then((res: any) => {
                                    if (res?.code === 200) {
                                        handleGetSiteList(pageNum, pageSize, newSearchCondition).then(() => {
                                            showSuccess(res?.msg);
                                        })
                                    } else {
                                        showFail(res?.msg)
                                    }
                                }).catch((err) => {
                                    console.log(err)
                                })
                            }}>
                                <Button danger type={'link'} color={'danger'}>删除</Button>
                            </Popconfirm>
                        )}
                    </div>
                )
            },
        ];

        const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
        const handleCloseAddModal = () => {
            setAddModalVisible(false);
        }

        //修改
        const [siteRecord, setSiteRecord] = useState<any | undefined>();
        const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
        const handleCloseEditModal = () => {
            setEditModalVisible(false);
        }

        return (
            <div className={styles.main}>
                <div className={styles.mainHead}>
                    <div className={styles.searchContent}>

                        <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                               onChange={handleNameChange} placeholder={'处置单位名称'} allowClear/>

                    </div>
                    <div className={styles.searchButton}>

                        <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                            handleGetSiteList(1, 10, searchCondition);
                            setPageNum(1);
                            setPageSize(10);
                            setNewSearchCondition(searchCondition)
                        }}>查询
                        </Button>

                        <Button type='primary' style={{marginRight: '20px', marginTop: '10px'}} onClick={() => {
                            setAddModalVisible(true)

                        }}>新增
                        </Button>

                    </div>

                </div>

                <Table rowKey={'siteId'} columns={columns} dataSource={siteList}
                       loading={loading}
                       pagination={{
                           current: pageNum,
                           onChange: onPageChange,
                           onShowSizeChange: onPageSizeChange,
                           total: total,
                           showSizeChanger: true,
                           defaultPageSize: pageSize,
                           pageSizeOptions: ['10', '20', '50', '100'],
                       }}/>

                <LocationModal open={locationModalOpen} onCancel={handleCloseLocationModal}
                               lat={siteLat} lng={siteLng}/>

                <AddSiteModal open={addModalVisible} onClose={handleCloseAddModal}
                              handleGetSite={async () => {
                                  await handleGetSiteList(pageNum, pageSize, newSearchCondition)
                              }}/>

                <EditSiteModal open={editModalVisible} onClose={handleCloseEditModal}
                                    handleGetSite={async () => {
                                        await handleGetSiteList(pageNum, pageSize, newSearchCondition)
                                    }} siteRecord={siteRecord as any}/>


            </div>
        );
    }
;

export default Sites;