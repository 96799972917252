import {AutoComplete, Button, Cascader, Input, Modal, Select, Table, Tooltip} from 'antd';
import React, {useEffect, useState} from 'react';
import {iCommunity} from "../../interface";
import {getAreaTree, getCommunityPage} from "../../api";
import {diffArray, getAreaPath, showFail, transform} from "../functions";
import styles from "../taskRecordDrawer/index.module.css";
import locationIcon from "../../assets/icons/location.svg";
import noneIcon from "../../assets/icons/none.svg";
import LocationModal from '../locationModal';

const AddTaskCommunitiesModal = ({
                                     open,
                                     onCancel,
                                     insertRecords,
                                     initObjectList
                                 }: { open: boolean, onCancel: () => void, insertRecords: any, initObjectList: any[] | [] }) => {
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false)
    const [communityList, setCommunityList] = useState<any[]>([])
    const onPageChange = (_page: number) => {
        setPageNum(_page);
    };

    const onPageSizeChange = (_page: number, _pageSize: number) => {
        setPageSize(_pageSize);
    };

    const [searchCondition, setSearchCondition] = useState({
        id: "",
        areaId: "",
        communityName: "",
        communityAddress: "",
        qrCode: "",
        type:1
    });
    const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);

    let params: any = {}
    const handleGetCommunityList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
        setLoading(true);
        params.pageSize = pageSize;
        params.pageNum = pageNum;
        if (searchCondition?.type) {
            params.type = searchCondition?.type
        }
        if (searchCondition?.communityName) {
            params.communityName = searchCondition?.communityName
        }
        if (searchCondition?.communityAddress) {
            params.communityAddress = searchCondition?.communityAddress
        }
        if (searchCondition?.areaId) {
            params.areaId = searchCondition?.areaId
        }
        getCommunityPage(params).then((res: any) => {
            setLoading(false);
            if (res?.code === 200) {
                setTotal(Number(res?.data?.total));
                setCommunityList(res?.data?.records);
            } else {
                showFail(res?.msg)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        if (open) {
            handleGetCommunityList(pageNum, pageSize, newSearchCondition);
        }
    }, [pageSize, pageNum, open])

    const [initCommunityIdList, setInitCommunityIdList] = useState<string[] | []>([]);

    const [selectedRowKeys, setSelectedRowKeys] = useState<string[] | []>([]);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const onSelectChange = (newSelectedRowKeys: any, selectedRows: any) => {
        console.log('newSelectedRowKeys',newSelectedRowKeys)
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedRows(selectedRows);
    };
    let finalSelected = diffArray(selectedRowKeys, initCommunityIdList);
    const hasSelected = finalSelected.length > 0;

    let finalSelectedRows = selectedRows.filter((item) => finalSelected.includes(item?.communityId as string))

    const rowSelection:any = {
        selectedRowKeys,
        onChange: onSelectChange,
        preserveSelectedRowKeys: true,
        type: 'checkbox',
        getCheckboxProps: (record: any) => {
            let disabled = false;
            let _init = initCommunityIdList?.map((s: string) => (Number(s)));
            if (_init.includes(Number(record?.communityId))) {
                disabled = true
            }
            return {
                value: record.communityId,
                disabled
            }
        },
    };


    const handleTypeChange = (e: any) => {
        setSearchCondition({...searchCondition, type: e})
    }

    const handleNameChange = (e: any) => {
        setSearchCondition({...searchCondition, communityName: e?.target?.value})
    }

    const handleAddressChange = (e: any) => {
        setSearchCondition({...searchCondition, communityAddress: e?.target?.value})
    }

    const handleAreaChange = (v: any) => {
        if (v) {
            setSearchCondition({...searchCondition, areaId: v[v?.length - 1]})
        } else {
            setSearchCondition({...searchCondition, areaId: ""})
        }
    }

    const [areaTree, setAreaTree] = useState<any>();
    const [areaTreeTran, setAreaTreeTran] = useState<any>();
    const handleGetArea = () => {
        getAreaTree().then((res) => {
            setAreaTree(res?.data);
            setAreaTreeTran(transform(res?.data))
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        if (open) {
            handleGetArea();
            let _initIdList = initObjectList?.map((h) => (h?.communityId)) as string[];
            setInitCommunityIdList(_initIdList);
            setSelectedRowKeys(_initIdList);
        }
    }, [open])

    //位置对话框
    const [communityLng, setCommunityLng] = useState<string | number>();
    const [communityLat, setCommunityLat] = useState();
    const [locationModalOpen, setLocationModalOpen] = useState(false);
    const handleCloseLocationModal = () => {
        setLocationModalOpen(false);
    }

    const columns= [
        {
            title: '名称',
            dataIndex: 'communityName',
            key: 'communityName',
        },
        {
            title: '县区及街道',
            dataIndex: 'areaId',
            key: 'areaId',
            render: (areaId: string) =>
                (<div>{areaTree ? getAreaPath(areaTree, areaId) : "数据加载中"}</div>)
        },
        {
            title: '地址',
            dataIndex: 'communityAddress',
            key: 'communityAddress'
        },
        {
            title: '二维码',
            dataIndex: 'qrCode',
            key: 'qrCode',
        },
        {
            title: '定位',
            dataIndex: 'location',
            key: 'location',
            render: (_: any, record: any) => (
                <>
                    {(record?.communityLat && record?.communityLng) ? (
                        <img style={{cursor: 'pointer'}} src={locationIcon} width={20} height={20} alt=""
                             onClick={() => {
                                 setLocationModalOpen(true);
                                 setCommunityLat(record?.communityLat);
                                 setCommunityLng(record?.communityLng);
                             }}/>
                    ) : (<div>
                        <Tooltip title={'该社区/企业暂无定位'}>
                            <img src={noneIcon} alt="" width={20} height={20}/>
                        </Tooltip>
                    </div>)}
                </>
            )
        },
    ];

    return (
        <>

            <Modal open={open} onCancel={onCancel} width={'60vw'} title={'选择任务对象'}
                   okText={'添加'} onOk={() => {
                insertRecords(finalSelectedRows)
            }}>
                <div className={styles.mainHead}>
                    <div className={styles.searchContent}>
                        <Select style={{width: '180px', marginRight: '30px', marginTop: '10px'}} options={[
                            {value:1,label:"社区"},
                            {value:2,label:"企业"},
                        ]} value={searchCondition?.type} onChange={handleTypeChange}/>

                        <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                               onChange={handleNameChange} placeholder={'名称'} allowClear/>

                        <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                               onChange={handleAddressChange} placeholder={'地址'} allowClear/>

                        <Cascader onChange={handleAreaChange} style={{width: 250, marginTop: '10px'}}
                                  options={areaTreeTran ? [areaTreeTran] : []}
                                  changeOnSelect placeholder="县区/街道/乡镇"/>

                    </div>
                    <div className={styles.searchButton}>
                        <Button style={{marginRight: '20px'}} type='primary' onClick={() => {
                            handleGetCommunityList(1, 5, searchCondition);
                            setPageNum(1);
                            setPageSize(5);
                            setNewSearchCondition(searchCondition)
                        }}>查询
                        </Button>

                    </div>
                </div>

                <div style={{height: "1vh"}}/>
                {hasSelected && (<div style={{display: 'flex', flexDirection: 'row', fontSize: '15px'}}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row'
                        }}>已选中 {finalSelected.length} 个对象
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                        <Button style={{fontSize: '15px'}} type='link' onClick={() => {
                            setSelectedRowKeys(initCommunityIdList)
                        }}>
                            取消勾选
                        </Button>
                    </div>
                </div>)}
                <Table rowKey={'communityId'} columns={columns} dataSource={communityList} loading={loading}
                       rowSelection={rowSelection}
                       pagination={{
                           current: pageNum,
                           onChange: onPageChange,
                           onShowSizeChange: onPageSizeChange,
                           total: total,
                           showSizeChanger: true,
                           defaultPageSize: pageSize,
                           pageSizeOptions: ['5', '10', '20', '50', '100', '200', '500'],
                       }}/>
                <LocationModal open={locationModalOpen} onCancel={handleCloseLocationModal}
                               lat={communityLat} lng={communityLng}/>
            </Modal>
        </>
    );
};

export default AddTaskCommunitiesModal;