import {message} from "antd";
import {GlobalOutlined, FormOutlined} from "@ant-design/icons"
import axios from "axios";

export const showWarning = (content: string, afterClose?: () => void) => {
    message.warning({
        content,
        duration: 1.5,
        onClose: afterClose
    })
}

export const showSuccess = (content: string, afterClose?: () => void) => {
    message.success({
        content,
        duration: 1.5,
        onClose: afterClose
    })
}


export const showFail = (content: string, afterClose?: () => void) => {
    message.error({
        content,
        duration: 1.5,
        onClose: afterClose
    })
}

export const showNetError = () => {
    message.error({
        content: 'Net Error',
        duration: 1.5,
        icon: <GlobalOutlined/>
    })
}

export const showNeedCompleteInfo = () => {
    message.error({
        content: '请完成所有必填/必选项',
        duration: 1.5,
        icon: <FormOutlined/>
    })
}


interface Node {
    value?: string;
    label?: string;
    areaId: string;
    name: string;
    delFlag: number;
    children?: Node[];
}

export function transform(node: Node): Node | null {

    if (node.delFlag === 1) {
        return null;
    }

    const result: any = {
        value: node.areaId,
        label: node.name
    };

    if (Array.isArray(node.children)) {
        result.children = [];
        for (const child of node.children) {
            const transformedChild = transform(child);
            if (transformedChild) {
                result.children.push(transformedChild);
            }
        }
    }

    return result;

}

interface Area {
    areaId: string;
    name: string;
    parentId: string;
    children?: Area[];
}

export function getAreaPath(data: Area, areaId: string): string {
    if (data.areaId === areaId) {
        return data.name;
    }

    if (data.children) {
        for (let child of data.children) {
            const path = getAreaPath(child, areaId);
            if (path) {
                return data.name + '/' + path;
            }
        }
    }

    return '';
}

export function getAreaIdArray(data: Node, areaId: string): string[] {
    let areaArray:string[]=[];
    if (data.value === areaId) {
        areaArray.push(data.value)
        return areaArray;
    }

    if (data.children) {
        for (let child of data.children) {
            const _areaArray = getAreaIdArray(child, areaId);
            if (_areaArray?.length>0) {
                _areaArray.push(data.value as string);
                return _areaArray;
            }
        }
    }

    return [];
}

export function diffArray(arr1: string[], arr2: string[]) {
    return arr1.filter(item => !arr2.includes(item));
}







