import {
    AutoComplete,
    Button,
    Carousel,
    Cascader,
    Form,
    Input,
    Modal,
    Popconfirm,
    Select,
    Table,
    Tag,
    Tooltip,
    Image
} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import styles from "./index.module.css"
import {iHydrant, iPicture} from "../../../interface";
import {
    getParkRecordPage, checkpply,
} from "../../../api";
import {showFail, showNeedCompleteInfo, showSuccess} from "../../../components/functions";
import LocationModal from "../../../components/locationModal";
// @ts-ignore
import {debounce} from "lodash";


const ParkRecord = () => {

        const isSuperManager = JSON.parse(window.localStorage?.getItem("isSuperManager") as string);
        const companyId = window.localStorage?.getItem("companyId");

        const [pageNum, setPageNum] = useState<number>(1);
        const [pageSize, setPageSize] = useState<number>(10);
        const [total, setTotal] = useState<number>(0);
        const [loading, setLoading] = useState<boolean>(false)
        const [result, setResult] = useState<any>()
        const [boxList, setBoxList] = useState<any[]>([])
        const isUnitManager = JSON.parse(window.localStorage?.getItem("isUnitManager") as string);
        const isManager = JSON.parse(window.localStorage?.getItem("isManager") as string);
        const onPageChange = (_page: number) => {
            setPageNum(_page);
        };

        const onPageSizeChange = (_page: number, _pageSize: number) => {
            setPageSize(_pageSize);
        };

        const [stateList, setStateList] = useState<any[]>([{label:'待审核',value:'发起'},{label:'驳回',value:'驳回'},{label:'通过',value:'通过'}])

         const isCompany = JSON.parse(window.localStorage?.getItem("isCompany") as string);
        let params: any = {}
        const handleGetRecordList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
            setLoading(true);
            params.pageSize = pageSize;
            params.pageNum = pageNum;
            params = {...params, ...searchCondition}
            if(isCompany){
                params.companyId = window.localStorage?.getItem("companyId") ;
            }
            if(isUnitManager){
                params.unitId = window.localStorage?.getItem("unitId") ;
            }
            if(!isSuperManager){
                params.recordState ="进" ;
            }
            if(isManager){
                let areaId=window.localStorage?.getItem("areaId") ;
                if(areaId!=null && areaId!=='1'){
                    params.areaId = window.localStorage?.getItem("areaId") ;
                }
            }
            console.log('record')
            getParkRecordPage(params).then((res: any) => {
                setLoading(false);
                if (res?.code === 200) {
                    setTotal(Number(res?.data?.total));
                    setBoxList(res?.data?.records);
                } else {
                    showFail(res?.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        }

        useEffect(() => {
            handleGetRecordList(pageNum, pageSize, newSearchCondition);
        }, [pageSize, pageNum])

        const [searchCondition, setSearchCondition] = useState({
            companyName: "",
            carLicense: "",
        });
        const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);

        const handleCompanyNameChange = (e: any) => {
            setSearchCondition({...searchCondition, companyName: e?.target?.value})
        }
        const handleCarLicenseChange = (e: any) => {
            setSearchCondition({...searchCondition, carLicense: e?.target?.value})
        }



        // const handleBoxNameChange = (e: any) => {
        //     setSearchCondition({...searchCondition, boxName: e?.target?.value})
        // }

        //位置对话框
        const [hydrantLng, setHydrantLng] = useState<string | number>();
        const [hydrantLat, setHydrantLat] = useState();
        const [locationModalOpen, setLocationModalOpen] = useState(false);
        const handleCloseLocationModal = () => {
            setLocationModalOpen(false);
        }
    const [detailLook, setDetailLook] = useState(false);
    const [item, setItem] = useState<any>();
    const handleCloseDetailLook = () => {
        console.log('666')
        setDetailLook(false);
    }

        const columns: any = [
            {
                title: '停车场',
                dataIndex: ['parkCompany',"companyName"],
            },
            {
                title: '车牌',
                dataIndex:  ['parkCar',"carLicense"],
            },
            {
                title: '入场时间',
                dataIndex: 'enterRecordTime',
                key: 'enterRecordTime',
            },
            {
                title: '出场时间',
                dataIndex: 'outRecordTime',
                key: 'outRecordTime'
            },
            {
                title: '停车时间',
                dataIndex: 'parkTime',
                key: 'parkTime',
            },
            {
                title: '当前状态',
                dataIndex: 'operation',
                key: 'operation',
                fixed: 'right',
                render: (_: any, record: any) => (
                    <div>
                    {record.recordState === '进' ?
                            <div>
                               {"场内"}
                            </div> : <div>
                            {'场外'}
                            </div>}
                    </div>
                )
            },
        ];

        //新增
        const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
        const handleCloseAddModal = () => {
            setAddModalVisible(false);
        }
        const handleRecord = () => {
            form.validateFields().then((value) => {
                let params = {}
                if(value.applyState==='通过'){
                    params={
                        applyState:value.applyState,
                        applyId:item.applyId,
                    }
                }else {
                    params={
                        applyState:value.applyState,
                        applyId:item.applyId,
                        notPassReason:value.notPassReason
                    }
                }
                checkpply(params).then((res: any) => {
                    if (res?.code === 200) {
                        setResult(null)
                        setDetailLook(false)
                        handleGetRecordList(pageNum, pageSize, newSearchCondition);
                    }else {
                        showFail(res?.msg)
                    }
                })
            }).catch((err) => {
                console.log(err);
                showNeedCompleteInfo();
            })
        }
        //修改
        const [hydrantRecord, setHydrantRecord] = useState<iHydrant>();
        const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
        const handleCloseEditModal = () => {
            setEditModalVisible(false);
        }
    const [form] = Form.useForm();
        return (
            <div className={styles.main}>
                <div className={styles.mainHead}>
                    <div className={styles.searchContent}>

                            {/*<Select style={{width: '180px', marginRight: '30px', marginTop: '10px'}}*/}
                            {/*        options={stateList} placeholder="工单状态" allowClear onChange={handleStateChange}/>*/}

                        {!isCompany?  <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}} allowClear
                               placeholder="停车场名称" onChange={handleCompanyNameChange}/>:""}

                        <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                               onChange={handleCarLicenseChange} placeholder={'车牌号'} allowClear/>

                        {/*<Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}*/}
                        {/*       onChange={handleBoxNameChange} placeholder={'箱体名称'} allowClear*/}
                        {/*/>*/}


                    </div>
                    <div className={styles.searchButton}>

                        <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                            handleGetRecordList(1, 10, searchCondition);
                            setPageNum(1);
                            setPageSize(10);
                            setNewSearchCondition(searchCondition)
                        }}>查询
                        </Button>

                        {/*<Button type='primary' style={{marginRight: '20px', marginTop: '10px'}} onClick={() => {*/}
                        {/*    setAddModalVisible(true);*/}
                        {/*}}>新增*/}
                        {/*</Button>*/}

                    </div>

                </div>

                <Table rowKey={'boxCode'} columns={columns} dataSource={boxList} loading={loading}
                       pagination={{
                           current: pageNum,
                           onChange: onPageChange,
                           onShowSizeChange: onPageSizeChange,
                           total: total,
                           showSizeChanger: true,
                           defaultPageSize: pageSize,
                           pageSizeOptions: ['10', '20', '50', '100'],
                       }}/>

                <LocationModal open={locationModalOpen} onCancel={handleCloseLocationModal}
                               lat={hydrantLat} lng={hydrantLng}/>


                <Modal open={detailLook}
                       onCancel={  ()=>setDetailLook(false)}
                       footer={<div>
                           {item?.applyState === '发起' ?<Button type={"primary"}
                            onClick={debounce(handleRecord, 1000)}
                           >提交</Button>:''}
                           <Button onClick={() => {
                               setDetailLook(false)
                               setResult(null)
                               form.resetFields();
                           }}>关闭</Button>
                       </div>}
                >
                    <Form form={form}>
                        <Form.Item
                            name='applyName'
                            label={'申请人姓名'}>
                            <div>{item?.applyName} </div>
                        </Form.Item>
                        <Form.Item
                            name='applyName'
                            label={'申请人身份证'}>
                            <div>{item?.idNum} </div>
                        </Form.Item>
                        <Form.Item
                            name='carLicense'
                            label={'申请人车牌号'}>
                            <div>{item?.carLicense} </div>
                        </Form.Item>
                        <Form.Item
                            name='createTime'
                            label={'申请时间'}>
                            <div>{item?.createTime} </div>
                        </Form.Item>
                        <Form.Item
                            name='applyState'
                            label={'申请状态'}>
                            <div>{item?.applyState} </div>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
;

export default ParkRecord;