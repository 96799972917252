import {Button, Cascader, Input, Modal, Popconfirm, Select, Table, Tag, TreeSelect} from 'antd';
import React, {useEffect, useState} from 'react';
import {deleteDangerTask, deleteExerciseTask, getDeptTree, getExerciseDataPage} from "../../../api";
import {showFail, showSuccess} from "../../../components/functions";
import styles from "./index.module.css"
import {ExerciseDetailModal} from "../../../components/ExerciseDetailModal";
import AddExerciseTaskDrawer from "../../../components/addExerciseTaskDrawer";


interface depttree {
    value?: string,
    title?: string,
    children?: any[],
}

function tree(list: depttree[], res: any[]) {
    for (let i = 0; i < res.length; i++) {
        if (res[i].children) {
            // @ts-ignore
            list.push({value: res[i].deptId, title: res[i].name, children: []})
        } else {
            // @ts-ignore
            list.push({value: res[i].deptId, title: res[i].name})
        }
        // @ts-ignore
        if (res[i].children) {
            // @ts-ignore
            tree(list[i].children, res[i].children)
        }
    }
    return list
}

const Exercise = () => {
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageNum, setPageNum] = useState<number>(1);
    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [exerciseId, setExerciseId] = useState<string | number>("");
    const [exerciseData, setExerciseData] = useState<any[]>([]);
    const onPageChange = (_page: number) => {
        setPageNum(_page);
    };

    const onPageSizeChange = (_page: number, _pageSize: number) => {
        setPageSize(_pageSize);
    };

    const [searchCondition, setSearchCondition] = useState({});
    const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);

    let params: any = {}
    const handleGetExercise = async (pageNum: number, pageSize: number, searchCondition?: any) => {
        setLoading(true);
        params.pageSize = pageSize;
        params.pageNum = pageNum;
        if (searchCondition?.taskName) {
            params.taskName = searchCondition.taskName;
        }
        if (searchCondition?.exerciseState!==undefined) {
            params.exerciseState = searchCondition.exerciseState;
        }
        if (searchCondition?.deptId) {
            params.deptId = searchCondition.deptId;
        }
        getExerciseDataPage(params).then((res: any) => {
            if (res.code === 200) {
                setLoading(false);
                setTotal(Number(res?.data?.total));
                setExerciseData(res.data?.records)
            } else {
                showFail(res?.msg)
            }
        }).catch((err: any) => {
            console.error(err)
        })
    }

    useEffect(() => {
        handleGetExercise(pageNum, pageSize, newSearchCondition)
    }, [pageSize, pageNum])

    const [depts, setDepts] = useState<any[]>([])
    const handleGetDepts=()=>{
        getDeptTree().then((res: any) => {
            if (res.code === 200) {
                let a: depttree[] = []
                let b = []
                b.push(res.data)
                let list = tree(a, b)
                setDepts(list);
            } else {
                showFail(res?.msg)
            }
        }).catch((err: any) => {
            console.error(err)
        })
    }
    useEffect(()=>{
        handleGetDepts()
    },[])

    const columns = [
        {
            title: '名称',
            dataIndex: 'taskName',
            key: 'taskName',
        },
        {
            title: '状态',
            dataIndex: 'exerciseState',
            key: 'exerciseState',
            render: (text: any, record: any) => {
                return (
                    <div>
                        {record.exerciseState === 0 ? <Tag color="processing">待上报</Tag> :
                            <Tag color="success">已上报</Tag>}
                    </div>)
            }
        },
        {
            title: '地点',
            dataIndex:"communityName",
            key: 'communityName',
        },
        {
            title: '任务开始时间',
            dataIndex: 'taskStartDate',
            key: 'taskStartDate',
        },
        {
            title: '任务结束时间',
            dataIndex: 'taskEndDate',
            key: 'taskEndDate',
        },

        {
            title: '发起人',
            dataIndex: 'startUser',
            key: 'startUser',
            render: (startUser: any) => (<>
                {startUser?.username} {startUser?.mobile}
            </>)
        },
        {
            title: '详情',
            dataIndex: 'exerciseId',
            key: 'exerciseId',
            render: ((exerciseId: any, record: any) => (
                <>
                    <Button type={'primary'} onClick={() => {
                        setModalOpen(true);
                        setExerciseId(exerciseId)
                    }}>查看详情</Button>
                    <Popconfirm title={'是否确认删除该演练学习任务?'} onConfirm={()=>{
                        deleteExerciseTask({exerciseId}).then((res:any)=>{
                            if(res.code===200){
                                handleGetExercise(pageNum,pageSize,newSearchCondition).then(()=>{
                                    showSuccess(res?.msg)
                                })
                            }else{
                                showFail(res?.msg)
                            }
                        })
                    }}>
                        <Button style={{marginLeft:'20px'}} danger>
                            删除
                        </Button>
                    </Popconfirm>
                </>

            ))
        },
    ]


    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const handleDetailModalClose = () => {
        setModalOpen(false);
    }

    const handleNameChange = (e: any) => {
        setSearchCondition({...searchCondition, taskName: e.target.value})
    }

    const handleStateChange = (v: any) => {
        setSearchCondition({...searchCondition, exerciseState:v})
    }

    const handleDeptIdChange = (e:any) => {
        setSearchCondition({...searchCondition, deptId:e})
    }

   const [addDrawerVisible,setAddDrawerVisible]=useState<boolean>(false);
    const handleCloseAddDrawer=()=>{
        setAddDrawerVisible(false);
    }

    return (
        <div>
            <div className={styles.mainHead}>
                <div className={styles.searchContent}>
                    <Select style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                            options={[{value: 0, label: "待上报"}, {value: 1, label: "已上报"}]}
                            onChange={handleStateChange} placeholder={'状态'} allowClear/>

                    <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                           onChange={handleNameChange} placeholder={'任务名称'} allowClear/>

                    <TreeSelect
                        placeholder={"请选择发起机构"}
                        showSearch
                        style={{width: '250px', marginRight: '30px', marginTop: '10px'}}
                        filterTreeNode={(inputValue, treeNode) =>
                            (treeNode as any).title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
                        }
                        // value={deptTree}
                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                        allowClear
                        treeDefaultExpandAll
                        onChange={handleDeptIdChange}
                        treeData={depts || []}
                    />

                    {/*<Cascader onChange={handleAreaChange} style={{width: 250, marginRight: '30px', marginTop: '10px'}}*/}
                    {/*          options={areaTreeTran ? [areaTreeTran] : []}*/}
                    {/*          changeOnSelect placeholder="县区/街道/乡镇" allowClear/>*/}


                </div>
                <div className={styles.searchButton}>

                    <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                        handleGetExercise(1, 10, searchCondition);
                        setPageNum(1);
                        setPageSize(10);
                        setNewSearchCondition(searchCondition)
                    }}>查询
                    </Button>

                    <Button type='primary' style={{marginRight: '20px', marginTop: '10px'}} onClick={() => {
                        setAddDrawerVisible(true)
                    }}>新增
                    </Button>

                </div>

            </div>

            <Table rowKey={'exerciseId'} columns={columns} dataSource={exerciseData}
                   loading={loading}
                   pagination={{
                       current: pageNum,
                       onChange: onPageChange,
                       onShowSizeChange: onPageSizeChange,
                       total: total,
                       showSizeChanger: true,
                       defaultPageSize: pageSize,
                       pageSizeOptions: ['10', '20', '50', '100'],
                   }}/>
            <ExerciseDetailModal open={modalOpen} onCancel={handleDetailModalClose}
                                 exerciseId={exerciseId as string}/>

            <AddExerciseTaskDrawer open={addDrawerVisible} onCancel={handleCloseAddDrawer} type={2} handleGetTask={async () => {
                await handleGetExercise(pageNum, pageSize, newSearchCondition)
            }}/>
        </div>
    );
};

export default Exercise;