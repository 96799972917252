import React from 'react';
import {Navigate, useRoutes} from 'react-router-dom';
import Layout from "./pages/Layout";
import HomePage from "./pages/homepage";
import Personal from "./pages/personal";
import Login from './pages/login';
import Hydrant from "./pages/manage/hydrant";
import Dept from "./pages/manage/dept";
import Menu from "./pages/manage/menu";
import Role from "./pages/manage/role";
import User from "./pages/manage/user";
import Area from "./pages/manage/area";
import InspectTask from "./pages/manage/inspectTask";
import InspectTaskRegular from "./pages/manage/inspectTaskRegular";
import HydrantMap from "./pages/map";
import Road from "./pages/manage/unit";
import Company from "./pages/manage/company";
import Car from "./pages/manage/car";
import Demand from "./pages/manage/demand";
import Work from "./pages/manage/work";
import Community from "./pages/manage/community";
import Exercise from "./pages/manage/exercise";
import HiddenDanger from "./pages/manage/hiddenDanger";
import Dict from "./pages/manage/dict";
import Chemicals from "./pages/manage/chemicals";
import SpecialPlace from "./pages/manage/specialPlace";
import Sites from "./pages/manage/sites";
import GarbageBox from "./pages/manage/garbageBox";
import Order from "./pages/manage/order";
import Records from "./pages/manage/record";
import Unit from "./pages/manage/unit";
import TongJi from "./pages/tongji";
export default function Router() {
    // @ts-ignore
    return useRoutes([
        {
            path: '/',
            children: [
                // {path: '/', element: <Navigate to="/login" replace/>},
                {path: '/', element: <Navigate to="/tongJi" replace/>},
                {path: 'login', element: <Login/>},
                {path: 'xc', element: <Layout/>},
                {path: 'personal', element: <Personal/>},
                {path: 'home', element: <HomePage/>},
                {path: 'map', element: <HydrantMap/>},
                // {path: 'tongJi', element: <TongJi/>},
            ]
        },
        {
            path: '/',
            element: <Layout/>,
            children: [
                // {path: '/user', element: },
                {path: 'tongJi', element: <TongJi/>},]
        },
        {
            path: '/manage',
            element: <Layout/>,
            children: [
                // {path: '/user', element: },
                {path: 'hydrant', element: <Hydrant/>},
                {path: 'community', element: <Community/>},
                {path: 'company', element: <Company/>},
                {path: 'demand', element: <Demand/>},
                {path: 'work', element: <Work/>},
                {path: 'car', element: <Car/>},
                {path: 'sites', element: <Sites/>},
                {path: 'special_place', element: <SpecialPlace/>},
                {path: 'chemicals', element: <Chemicals/>},
                {
                    path: 'inspect_task', children: [
                        {path: '', element: <InspectTask/>},
                        {path: 'regular', element: <InspectTaskRegular/>}
                    ]
                },
                {path: 'exercise', element: <Exercise/>},
                {path: 'hiddenDanger', element: <HiddenDanger/>},
                {path: 'dept', element: <Dept/>},
                {path: 'menu', element: <Menu/>},
                {path: 'role', element: <Role/>},
                {path: 'user', element: <User/>},
                {path: 'area', element: <Area/>},
                {path: 'road', element: <Road/>},
                {path: 'dict', element: <Dict/>},
                {path: 'inspect_task', element: <InspectTask/>},
                {path: 'garbageBox', element: <GarbageBox/>},
                {path: 'order', element: <Order/>},
                {path: 'record', element: <Records/>},
                {path: 'unit', element: <Unit/>},
            ]
        },
    ]);
};
