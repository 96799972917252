import {Button, Cascader, Form, Input, InputNumber, Modal, Select} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import AddNewPictures from '../AddNewPictures';
// @ts-ignore
import {debounce} from "lodash"
import {showFail, showNeedCompleteInfo, showSuccess} from "../functions";
import PickLocationModal from '../pickLocationModal';
import {addUnit, addHydrant, getSiteNotPage} from "../../api";
import PickUser from "../pickUser";

const useResetFormOnCloseModal = ({form, open}: { form: any, open: boolean }) => {
    const prevOpenRef: any = useRef();
    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const [sites, setSites] = useState<any[]>([])
    useEffect(() => {

    }, []);
    const prevOpen = prevOpenRef.current;
    useEffect(() => {
        if (!open && prevOpen) {
            form.resetFields();
        }
    }, [form, prevOpen, open]);
};

const AddHydrantModal = ({
                             open,
                             onClose,
                             siteOptions,
                             handleGetHydrant
                         }: { open: boolean, onClose: () => void, siteOptions: any, handleGetHydrant: any }) => {
    const [form] = Form.useForm();
    useResetFormOnCloseModal({
        form,
        open,
    });

    const [loading, setLoading] = useState<boolean>(false);
    const handleAddNewUnit = () => {
        form.validateFields().then((value) => {
            let newUnit = {
                unitName:value?.unitName,
                unitLng: value?.location?.lng,
                unitLat: value?.location?.lat,
                parkNum:value?.parkNum,
                contactName:value?.contactName,
                contactMobile:value?.contactMobile
            }
            setLoading(true);
            addUnit(newUnit).then((res: any) => {
                setLoading(false);
                form.resetFields();
                setLoc(null);
                console.log(res)
                if (res?.code === 200) {
                    handleGetHydrant().then(() => {
                        showSuccess(res?.msg);
                        onClose();
                    })
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                console.log(err)
            })

        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }

    const [pickLocModalOpen, setPickLocModalOpen] = useState(false);
    const handleCancelLocModal = () => {
        setPickLocModalOpen(false);
    }


    const confirmLoc = (e: any) => {
        setLoc(e);
        form.setFieldsValue({"location": e})
    }
    const [loc, setLoc] = useState<any>()
    const [initLoc, setInitLoc] = useState({lng: 120.09, lat: 30.88});
    const getLocation = () => {
        console.log(navigator.geolocation)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((l) => {
                if (l?.coords) {
                    setInitLoc({lng: l?.coords?.longitude, lat: l?.coords?.latitude})
                } else {
                    showFail('获取定位失败')
                }
            });
        } else {
            alert("浏览器不支持地理定位。");
        }
    }
    const confirmName = () => {}


    return (
        <div>
            <Modal open={open} onCancel={() => {
                onClose();

                setLoading(false)
            }} title={'单位新增'} maskClosable={false} okText={'确认提交'}
                   onOk={debounce(handleAddNewUnit, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                <Form form={form} name="form" labelAlign="right" labelCol={{
                    xs: {span: 24},
                    sm: {span: 6},
                }} wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 14},
                }}>
                    <Form.Item
                        name="unitName"
                        label="单位名称"
                        rules={[
                            {
                                required: true, message: "请输入单位名称"
                            },
                        ]}
                    >
                        <Input placeholder="请输入单位名称"/>
                    </Form.Item>
                    <Form.Item
                        name="contactName"
                        label="联系人姓名"
                        // rules={[
                        //     {
                        //         required: true, message: "请输入联系人姓名"
                        //     },
                        // ]}
                    >
                        <Input placeholder="请输入联系人姓名"/>
                    </Form.Item>
                    <Form.Item
                        name="contactMobile"
                        label="联系人电话"
                        // rules={[
                        //     {
                        //         required: true, message: "请输入联系人电话"
                        //     },
                        // ]}
                    >
                        <InputNumber style={{ width: '100%' }} placeholder="请输入联系人电话"/>
                    </Form.Item>
                    {/*<Form.Item*/}
                    {/*    name="parkNum"*/}
                    {/*    label="车位数量"*/}
                    {/*    rules={[*/}
                    {/*        {*/}
                    {/*            required: true, message: "请输入车位数量"*/}
                    {/*        },*/}
                    {/*    ]}*/}
                    {/*>*/}
                    {/*    <InputNumber placeholder="请输入车位数量"/>*/}
                    {/*</Form.Item>*/}


                    {/*<Form.Item*/}
                    {/*    name="location"*/}
                    {/*    label="单位定位"*/}
                    {/*    rules={[*/}
                    {/*        {*/}
                    {/*            required: true, message: "请选择定位"*/}
                    {/*        },*/}
                    {/*    ]}*/}
                    {/*>*/}
                    {/*    {loc ? (<div style={{display: "flex", flexDirection: "row"}}>*/}
                    {/*            <div style={{color: "#235c7a"}}>*/}
                    {/*                <div>经度:{loc?.lng}</div>*/}
                    {/*                <div>纬度:{loc?.lat}</div>*/}
                    {/*            </div>*/}
                    {/*            <div style={{width: "20px"}}/>*/}
                    {/*            <div style={{display: "grid", placeItems: "center"}}>*/}
                    {/*                <Button type="primary" onClick={() => {*/}
                    {/*                    setPickLocModalOpen(true)*/}
                    {/*                }}>重新选择*/}
                    {/*                </Button>*/}
                    {/*            </div>*/}

                    {/*        </div>*/}
                    {/*    ) : (*/}
                    {/*        <Button type="primary" onClick={() => {*/}
                    {/*            getLocation();*/}
                    {/*            setPickLocModalOpen(true);*/}
                    {/*        }}>选择定位*/}
                    {/*        </Button>*/}
                    {/*    )}*/}
                    {/*</Form.Item>*/}


                </Form>
                <PickLocationModal loc={initLoc} open={pickLocModalOpen} onCancel={handleCancelLocModal}
                                   confirmLoc={(e: any) => {
                                       confirmLoc(e)
                                   }} confirmAddress={() => {}} confirmRoad={() => {}}
                                   confirmName={confirmName}
                                   repick={undefined}/>

            </Modal>

        </div>
    );
};

export default AddHydrantModal;