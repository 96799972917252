import {DatePicker, Divider, Form, Input, message, Modal, Select, Space} from 'antd';
import React, {useEffect, useState} from 'react';
// @ts-ignore
import {debounce} from "lodash"
import {getAreaIdArray, showFail, showNeedCompleteInfo, showSuccess, showWarning} from "../functions";
import {getUserList, updateInspectTask} from "../../api";
import {iInspectTask} from "../../interface";
import dayjs from "dayjs";
import moment from "moment"


const UpdateInspectTaskModal = ({
                                    open,
                                    onClose,
                                    handleGetInspectTask,
                                    inspectTask
                                }: { open: boolean, onClose: () => void, handleGetInspectTask: any, inspectTask: iInspectTask }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (open) {
            form.resetFields();
        }
    }, [open])

    const [userOptions, setUserOptions] = useState<any[]>([]);
    const changeName = (e: any) => {
        let username = e.target.value
        if (username) {
            getUserList({username}).then((res: any) => {
                let _user = res?.data?.map((d: any) => {
                    return {
                        label: `姓名:${d?.username}  手机号:${d?.mobile}`,
                        value: d?.userId
                    }
                })
                setUserOptions(_user)
            }).catch((err: any) => {
                console.log(err);
            })
        }
    }

    useEffect(() => {
        if (inspectTask?.userId) {
            getUserList({userId: inspectTask?.userId}).then((res: any) => {
                let _user = res?.data?.map((d: any) => {
                    return {
                        label: `姓名:${d?.username}  手机号:${d?.mobile}`,
                        value: d?.userId
                    }
                })
                setUserOptions(_user)
            }).catch((err: any) => {
                console.log(err);
            })
        }
    }, [open])

    const handleTaskTimeChange = (dates: any, dateStrings: [string, string]) => {
        if (dates?.length > 0) {
            let startTime = dates[0].$d.valueOf();
            const now = new Date();
            const nextHour = new Date(now.getTime() + 60 * 60 * 1000);
            if (startTime > nextHour) {
                form.setFieldsValue({taskTime:dates})
            } else {
                let nextHourStr= moment(nextHour).format('YYYY-MM-DD HH:mm:ss')
                message.warning({content: `任务开始时间必须晚于${nextHourStr}(当前时间的后一小时)`, duration: 2.5})
                form.setFieldsValue({taskTime: ""})
            }
        } else {
            form.setFieldsValue({taskTime:""})
        }
    }

    const [loading, setLoading] = useState<boolean>(false);
    const pushData = (values: any) => {
        setLoading(true);
        updateInspectTask(values).then((res: any) => {
            setLoading(false);
            if (res?.code === 200) {
                handleGetInspectTask().then(() => {
                    showSuccess(res?.msg);
                    onClose();
                    form.resetFields();
                    setUserOptions([]);
                })
            } else {
                showFail(res?.msg)
            }
        }).catch((err: any) => {
            console.log(err)
        })
    }
    const handleUpdateInspectTask = () => {
        form.validateFields().then((value) => {
            let updatedInspectTask = {
                ...value,
                taskId:inspectTask?.taskId,
                taskStartTime: value?.taskTime[0]?.$d.valueOf(),
                taskEndTime: value?.taskTime[1]?.$d.valueOf(),
            }
            pushData(updatedInspectTask)
        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }


    return (
        <div>
            <Modal open={open} onCancel={() => {
                onClose();
                setLoading(false);
                setUserOptions([]);
                form.resetFields()
            }} title={'巡检任务信息修改'} width={'60vw'} maskClosable={false} okText={'确认修改'}
                   onOk={debounce(handleUpdateInspectTask, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                <Form form={form} name="form" labelAlign="right" labelCol={{
                    xs: {span: 24},
                    sm: {span: 6},
                }} wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 14},
                }}>
                    <Form.Item
                        name="taskName"
                        label="任务名称"
                        initialValue={inspectTask?.taskName}
                        rules={[
                            {
                                required: true, message: "请输入任务名称"
                            },
                        ]}
                    >
                        <Input placeholder="请输入任务名称"/>
                    </Form.Item>

                    <Form.Item
                        name="taskTime"
                        label="任务起止时间"
                        initialValue={
                            [dayjs(inspectTask?.taskStartTime, 'YYYY-MM-DD HH:mm:ss'),
                                dayjs(inspectTask?.taskEndTime, 'YYYY-MM-DD HH:mm:ss')]}
                        rules={[
                            {
                                required: true, message: "请选择起至时间"
                            },
                        ]}
                    >
                        <DatePicker.RangePicker disabled={inspectTask?.taskState !== 0} style={{width: "100%"}}
                                                showTime={{format: 'HH'}}
                                                format="YYYY-MM-DD HH:00"
                                                placeholder={['开始时间', '结束时间']}
                                                onChange={handleTaskTimeChange}/>
                    </Form.Item>

                    {inspectTask?.taskState !== 0 && (
                        <Form.Item label="说明">
                            <div style={{color:'#be9f4e'}}>当前巡检任务已发布, 不可再修改任务起止时间</div>
                        </Form.Item>
                    )}

                    <Form.Item
                        name="userId"
                        label="负责人"
                        rules={[
                            {
                                required: true, message: "请选择任务负责人"
                            },
                        ]}
                        initialValue={inspectTask?.userId}
                    >
                        <Select
                            allowClear={true}
                            placeholder={"请输入用户名查询并选择"}
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider style={{margin: '8px 0'}}/>
                                    <Space style={{padding: '4px 8px 4px '}}>
                                        <Input
                                            allowClear
                                            placeholder="请输入用户名"
                                            onChange={debounce(changeName, 600)}
                                        />

                                    </Space>
                                </>
                            )}
                            options={userOptions}
                        />
                    </Form.Item>


                    <Form.Item
                        name="taskRemark"
                        label="备注"
                        initialValue={inspectTask?.taskRemark}
                    >
                        <Input placeholder="请输入任务备注"/>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default UpdateInspectTaskModal;