import React, {useEffect, useState} from 'react';
import {CommunityPoint, iCommunity} from "../../interface";
import {deleteCommunityPoint, getCommunity} from "../../api";
import {showFail, showNetError, showSuccess} from "../functions";
import {Button, Modal, Popconfirm, Table} from 'antd';
//@ts-ignore
import {InfoWindow, Map, Marker, Markers} from "react-amap";
import AddNewPoiModal from "../addNewPoiModal";
import EditPoiModal from "../editPoiModal";

const CommunityMapModal = ({
                               open,
                               onCancel,
                               communityId
                           }: { open: boolean, onCancel: () => void, communityId: string }) => {
    const [community, setCommunity] = useState<iCommunity | undefined>();
    const [markers, setMarkers] = useState<any[]>([]);
    const styleA: any = {
        background: `url("https://s.iterated.xyz//image/-MTcwMzYzNzU4Nw==-NjYwNjI5.png")`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '30px',
        height: '30px',
        color: '#000',
        textAlign: 'center',
        lineHeight: '30px',
    }
    const styleB: any = {
        background: `url("https://s.iterated.xyz//image/-MTcwMzY0NjkzOQ==-MzM5MjIwNQ==.png")`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '30px',
        height: '30px',
        color: '#000',
        textAlign: 'center',
        lineHeight: '30px',
    }
    const styleC: any = {
        background: `url("https://s.iterated.xyz//image/-MTcwMzY0Nzk5OA==-NzcwMzczOA==.png")`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '30px',
        height: '30px',
        color: '#000',
        textAlign: 'center',
        lineHeight: '30px',
    }
    console.log('community', community)
    useEffect(() => {
        if (open) {
            handleGetCommunity()
        }
    }, [open]);

    const handleGetCommunity = async () => {
        getCommunity({
            communityId
        }).then((res: any) => {
            if (res?.code === 200) {
                let community = res?.data[0]
                setCommunity(community);
                let markers = community?.communityPoints?.filter((c: any) => (
                    !c?.delFlag && c?.pointLng && c?.pointLat))?.map((d: any) => {
                    return {
                        ...d,
                        "position": {
                            longitude: d?.pointLng,
                            latitude: d?.pointLat
                        },
                    }
                });
                setMarkers(markers);
            } else {
                showFail(res?.msg)
            }
        }).catch((err: any) => {
            showNetError()
            console.log(err);
        })
    }

    const columns: any = [
        {
            title: '类型',
            dataIndex: 'pointType',
            key: 'pointType',
        },
        {
            title: '名称',
            dataIndex: 'pointName',
            key: 'pointName',
        },
        {
            title: '经纬度',
            dataIndex: 'location',
            key: 'location',
            render: (text: string, record: any) => (
                <div>
                    {record?.pointLng && record?.pointLat ? (
                        <div>
                            {record?.pointLng},{record?.pointLat}
                        </div>
                    ) : (
                        <div>
                            未配置
                        </div>
                    )}

                </div>
            )
        },
        {
            title: '备注',
            dataIndex: 'pointRemark',
            key: 'pointRemark',
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            render: (_: string, record: any) => (
                <>
                    <Button type={'link'} onClick={() => {
                        setEditPoiModalOpen(true);
                        setCommunityPoint(record)
                    }}>修改</Button>
                    <Popconfirm title={`是否确认删除该${record?.pointType}`} onConfirm={() => {
                        deleteCommunityPoint({communityPointId: record?.communityPointId}).then((res: any) => {
                            if (res?.code === 200) {
                                handleGetCommunity().then(() => {
                                    showSuccess(res?.msg)
                                })
                            } else {
                                showFail(res?.msg)
                            }
                        }).catch((err: any) => {
                            console.log('err', err)
                            showNetError();
                        })
                    }}>
                        <Button danger type={'link'}>删除</Button>
                    </Popconfirm>
                </>
            )
        },
    ]

    const markerEvents = {
        click: (e: any) => {
            setInfo(e?.target?.w?.extData);
            setInfoWindowVisible(true);
        },
        // ... 支持绑定所有原生的高德 Marker 事件
    };

    const [addNewPoiModalOpen, setAddNewPoiModalOpen] = useState<boolean>(false);
    const handleCloseAddNewPoiModal = () => {
        setAddNewPoiModalOpen(false)
    }

    const [communityPoint, setCommunityPoint] = useState<CommunityPoint | undefined>()
    const [editPoiModalOpen, setEditPoiModalOpen] = useState<boolean>(false);
    const handleCloseEditPoiModal = () => {
        setEditPoiModalOpen(false)
    }


    const [infoWindowVisible, setInfoWindowVisible] = useState<boolean>(false);
    const [info, setInfo] = useState<any>();


    let infoWindow = ` 
                    <div>
                        <h3>${info?.pointName}</h3>
                        <p>类型: ${info?.pointType}</p>
                        <p>备注: ${info?.pointRemark}</p>
                    </div>`

    return (
        <Modal open={open} onCancel={onCancel} title={`${community?.communityName}`} width={'800px'}
               onOk={onCancel}
               cancelButtonProps={{style: {display: 'none'}}}>
            {community?.communityLng && community?.communityLat && (
                <div style={{width: '100%', height: "40vh"}}>
                    <Map plugins={['ToolBar']}
                         center={{
                             longitude: Number(community?.communityLng),
                             latitude: Number(community?.communityLat)
                         }}
                         zoom={17}>
                        {markers?.map((m: any) => (
                            <Marker
                                key={m?.communityPointId}
                                events={markerEvents}
                                extData={m}
                                position={{longitude: m?.position?.longitude, latitude: m?.position?.latitude}}>
                                <div>
                                    {m?.pointType === "出入口" ? <div style={styleA}/> : m?.pointType === "停靠点" ?
                                        <div style={styleB}/> : <div style={styleC}/>}
                                </div>

                            </Marker>
                        ))}
                        <InfoWindow
                            position={{
                                longitude: Number(info?.pointLng) || Number(community?.communityLng),
                                latitude: Number(info?.pointLat) || Number(community?.communityLat)
                            }}
                            visible={infoWindowVisible}
                            isCustom={false}
                            content={infoWindow}
                            size={{
                                width: 220,
                                height: 150,
                            }}
                            offset={[0, 0]}
                            events={{
                                close: () => {
                                    setInfoWindowVisible(false);
                                }
                            }}
                        />
                    </Map>


                </div>
            )}

            <div>
                <div style={{
                    textAlign: 'center',
                    height: "50px",
                    lineHeight: '50px',
                    color: '#2962ee',
                    fontSize: '15px',
                    fontWeight: 'bold'
                }}>
                    社区点位
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    marginBottom: '15px'
                }}>
                    <Button type={'primary'} onClick={() => {
                        setAddNewPoiModalOpen(true)
                    }}>新增点位</Button>
                </div>
                <Table rowKey={'communityPointId'} columns={columns}
                       dataSource={community?.communityPoints?.filter((d: any) => (!d?.delFlag))}/>
            </div>

            <AddNewPoiModal open={addNewPoiModalOpen} onCancel={handleCloseAddNewPoiModal}
                            community={community as iCommunity}
                            handleGetCommunity={handleGetCommunity}
            />

            <EditPoiModal open={editPoiModalOpen} onCancel={handleCloseEditPoiModal}
                          community={community as iCommunity}
                          communityPoint={communityPoint as CommunityPoint}
                          handleGetCommunity={handleGetCommunity}
            />
        </Modal>
    );
};

export default CommunityMapModal;