import React, { useState, useEffect } from 'react';

import './index.css';
import { Column } from '@ant-design/plots';
import {AxiosError} from "axios";
import {Button, DatePicker, Divider, Input, message, Row, Select, Space, TreeSelect} from "antd";
import locale from "antd/es/date-picker/locale/zh_CN";
import typeIcon from "../../assets/icons/type.svg";
import {SearchOutlined} from "@ant-design/icons";
import {getAllCompanyNotPage, getAreaTree, getCount} from "../../api";
import {DualAxes} from "@ant-design/charts";
import {H3} from "@icon-park/react";
// @ts-ignore
import {debounce} from "lodash";
import {showWarning} from "../../components/functions";
const Home: React.FC = () => {

    const [data, setData] = useState<any[]>([]);
    interface areatree {
        value?: string,
        title?: string,
        children?: any[],
    }
    //保存数据
    const [areaTree, setAreaTree] = useState([]);
    const [areas, setAreas] = useState<any>([]);
    function tree(list:areatree[],res:any[]){
        for (let i = 0; i < res.length; i++) {
            if(res[i].children){
                    list.push({value: res[i].areaId, title: res[i].name, children: []})
            }else {
                // @ts-ignore
                    list.push({value: res[i].areaId, title: res[i].name})
            }
            // @ts-ignore
            if(res[i].children) {
                // @ts-ignore
                tree(list[i].children, res[i].children)
            }
        }
        return list

    }
    //查询权限Tree
    useEffect(()=>{
        let params = {}
        getAreaTree(params).then( (res:any)=>{
            setAreaTree(res.data);
            let a:areatree[]=[]
            let b=[]
            b.push(res.data)
            let list=tree(a,b)
            setAreas(list)
            console.log(list)
        }, (err:AxiosError)=>{
            console.log(err);
        });


    },[])
    useEffect(() => {
        let params2 = {}
        getCount(params2).then( (res:any)=>{
            let _data=[]
            for (let i = 0; i < res.data.length; i++) {
                if(i===0){
                    setCountTotal(res.data[i].countTotal)

                    console.log(res.data[i].countTotal)
                }
{}                _data.push({dayStr:res.data[i].dayStr,总数:res.data[i].count,line总数:res.data[i].count})
            }
            setData(_data.splice(0))

        }, (err:AxiosError)=>{
            console.log(err);
        });

    }, []);


    const config = {
        data: [data, data], // 两组数据
        xField: 'dayStr',
        yField: ['总数', 'line总数'],
        geometryOptions: [
            {
                geometry: 'column',
                isStack: true,
                label: {
                    position: 'middle', // 'top', 'bottom', 'middle'
                },
            },
            {
                geometry: 'line',
                // smooth: true, // 平滑曲线
                label: {
                    position: 'middle',
                },
            },
        ],
        title: {
            text: '',
            style: {
                fontSize: 16,
                fontWeight: 'bold',
                textAlign: 'center',
            },
        },
        xAxis: {
            title: {
                text: '日期',
                style: {
                    fontSize: 12,
                    fontWeight: 'bold',
                },
            },
        },
        yAxis: {
            title: {
                text: '总数',
                style: {
                    fontSize: 12,
                    fontWeight: 'bold',
                },
            },
        },
    };

    let [startDate, setStartDate] = useState<string>("");
    const onStartDateChange = (dateString:string) => {
        setStartDate(dateString);
    }

    let [endDate, setEndDate] = useState<string>("");
    let [areaId, setAreaId] = useState<string>("");
    let [countTotal, setCountTotal] = useState<string>("");
    let [companyId, setCompanyId] = useState<string>("");
    const onEndDateChange = (dateString:string) => {
        setEndDate(dateString);
    }
    const [companyList, setCompanyList] = useState<any[]>([]);

    const changeName = (e: any) => {
        let v = e?.target?.value;
        if (v) {
            let params: any = {companyName: v};

            getAllCompanyNotPage(params).then((res: any) => {
                console.log(res)
                if (res?.code === 200) {
                    setCompanyList(res?.data?.map((d: any) => {
                        return {
                            value: d?.companyId,
                            label: d.companyName
                        }
                    }))
                }
            })
        } else {
            showWarning("请输入归属人姓名或手机号")
        }
    }
    const getStatics=()=>{
        let params2 = {startDate:startDate,endDate:endDate,areaId:areaId,companyId:companyId}
        getCount(params2).then( (res:any)=>{
            let _data=[]
            for (let i = 0; i < res.data.length; i++) {
                if(i===0){
                    setCountTotal(res.data[i].countTotal)

                    console.log(res.data[i].countTotal)
                }
                _data.push({dayStr:res.data[i].dayStr,总数:res.data[i].count,line总数:res.data[i].count})
            }
            setData(_data.splice(0))

        }, (err:AxiosError)=>{
            console.log(err);
        });
    }
    return (
        //<div className='home-layout' style={{overflow: 'hidden' ,backgroundImage: `url(${BackgroundImage})` ,backgroundSize: 'cover', height: '100%' }}>
        <div style={{overflow: 'hidden', width: '100%', height: '100%'}}>
            <div className="cust-service-right-select" style={{marginTop: '50px', marginLeft: '3%'}}>
                <Row>
                    {/*<CalendarOutlined style={{ color:'dodgerblue',marginRight:'10px',fontSize:'23px'}}/>*/}
                    <DatePicker style={{boxShadow: ' 0 0  10px rgba(0, 0, 0, .2)', paddingTop: '5px'}} allowClear
                                format="YYYY-MM-DD" locale={locale} placeholder="开始时间"
                                onChange={(e, dateString: any) => onStartDateChange(dateString)}/>
                    {/*<DashboardOutlined style={{ color:'dodgerblue',marginRight:'10px',fontSize:'23px'}}/>*/}
                    <DatePicker style={{boxShadow: ' 0 0  10px rgba(0, 0, 0, .2)', marginLeft: '10px'}} allowClear
                                format="YYYY-MM-DD" locale={locale} placeholder="结束时间"
                                onChange={(e, dateString: any) => onEndDateChange(dateString)}/>
                    <TreeSelect
                        showSearch
                        style={{ marginLeft: '10px',height:'20%',width:'10%' ,boxShadow: ' 0 0  10px rgba(0, 0, 0, .2)',}}
                        // value={areaTree}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder="归属区域"
                        allowClear
                        treeDefaultExpandAll
                        onChange={(e)=>{
                            if(e!=='1'){
                                setAreaId(e)
                           }else {
                                setAreaId("")
                            }
                        }}
                        treeData={areas}
                    />
                    <Select
                        style={{ marginLeft: '10px',height:'20%',width:'10%',boxShadow: ' 0 0  10px rgba(0, 0, 0, .2)',}}
                        allowClear={true}
                        placeholder={"请选择归属单位"}
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider style={{margin: '8px 0'}}/>
                                <Space style={{padding: '4px 8px 4px '}}>
                                    <Input
                                        allowClear
                                        placeholder="请输入单位名称"
                                        onChange={debounce(changeName, 600)}
                                    />
                                </Space>
                            </>
                        )}
                        onChange={(e)=>{
                            setCompanyId(e)
                        }}
                        options={companyList}
                    />

                    <Button value="large" style={{
                        boxShadow: ' 0 0  20px rgba(0, 0, 0, .2)',
                        fontSize: '18px',
                        height: '38px',
                        marginLeft: '10px'
                    }} onClick={() => getStatics()} type="primary"><SearchOutlined/>查询</Button>
                </Row>

            </div>
            <div style={{width: '100%', height: '90%'}}>
                <h2 style={{marginTop: "2%"}}>{"总累计停车次数："+countTotal}</h2>
                <h3 style={{marginTop: "1%"}}>{"日累计入场车辆统计表"}</h3>
                <DualAxes  {...config as any} />
            </div>

        </div>
    )
}


export default Home;