import {Button, Cascader, Form, Input, Modal, Select} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import AddNewPictures from '../AddNewPictures';
// @ts-ignore
import {debounce} from "lodash"
import {getAreaIdArray, showFail, showNeedCompleteInfo, showNetError, showSuccess, showWarning} from "../functions";
import PickLocationModal from '../pickLocationModal';
import {addCommunity, addHydrant, getCommunityId, updateCommunity} from "../../api";
import {iCommunity} from "../../interface";
import UpdatePictures from '../modifyPictures';
import AddNewFile from "../AddNewFile";
import UpdateFile from "../UpdateFile";

const useResetFormOnCloseModal = ({form, open}: { form: any, open: boolean }) => {
    const prevOpenRef: any = useRef();
    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;
    useEffect(() => {
        if (!open && prevOpen) {
            form.resetFields();
        }
    }, [form, prevOpen, open]);
};

const EditCommunityModal = ({
                                open,
                                onClose,
                                areaOptions,
                                community,
                                handleGetCommunity
                            }: {
    open: boolean, onClose: () => void, areaOptions: any,
    community: iCommunity,
    handleGetCommunity: any
}) => {
    const [form] = Form.useForm();
    useResetFormOnCloseModal({
        form,
        open,
    });


    const [loc, setLoc] = useState<any>()
    const [initLoc, setInitLoc] = useState({lng: 120.09, lat: 30.88});


    //新增宿舍中的选择位置对话框
    const [pickLocModalOpen, setPickLocModalOpen] = useState(false);
    const handleCancelLocModal = () => {
        setPickLocModalOpen(false);
    }


    const confirmLoc = (e: any) => {
        setLoc(e);
        form.setFieldsValue({"location": e})
    }

    const confirmAddress = (e: any) => {
        form.setFieldsValue({"communityAddress": e})
    }


    const getLocation = () => {
        console.log(navigator.geolocation)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((l) => {
                if (l?.coords) {
                    setInitLoc({lng: l?.coords?.longitude, lat: l?.coords?.latitude})
                } else {
                    showFail('获取定位失败')
                }
            });
        } else {
            alert("浏览器不支持地理定位。");
        }
    }

    const [areaIdArrays, setAreaIdArrays] = useState<any[]>([])
    useEffect(() => {
        if (open) {
            form.resetFields();
            setLoc({lng: community?.communityLng, lat: community?.communityLat});
            setInitLoc({lng: Number(community?.communityLng), lat: Number(community?.communityLat)});
            try {
                let _areaIdArrays = getAreaIdArray(areaOptions[0], community?.areaId as string).reverse();
                setAreaIdArrays(_areaIdArrays);
            }catch (e) {
                showWarning("网络超时, 请刷新页面!")
            }
        }
    }, [open])


    const [loading, setLoading] = useState<boolean>(false);
    const handleAddNewHydrant = () => {
        form.validateFields().then((value) => {
            console.log('value',value);
            let pictures = value?.pictures;
            let data: any = {};
            if (pictures && pictures?.length > 0) {
                data = {
                    ...value,
                    communityMapUrl:pictures[0]?.response?.msg
                }
            }else{
                data=value
            }

            let updatedCommunity = {
                ...data,
                communityLng: value?.location?.lng,
                communityLat: value?.location?.lat,
                areaId: value?.area && value?.area[value?.area?.length - 1],
                communityId: community?.communityId,
                pdfUrl
            }
            setLoading(true);
            updateCommunity(updatedCommunity).then((res: any) => {
                setLoading(false);
                console.log(res)
                if (res?.code === 200) {
                    handleGetCommunity().then(() => {
                        showSuccess(res?.msg);
                        onClose();
                        setLoc(undefined);
                    })
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                console.log(err)
            })

        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }

    const [pdfUrl,setPdfUrl]=useState<string>("");
    return (
        <div>
            <Modal open={open} onCancel={() => {
                onClose();
                setLoc(undefined);
                setLoading(false)
            }} width={'800px'} title={`${community?.type===1?'社区':community?.type===2?'企业':"特殊场所"}修改`} maskClosable={false} okText={'确认提交'}
                   onOk={debounce(handleAddNewHydrant, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                <Form form={form} name="form" labelAlign="right" labelCol={{
                    xs: {span: 24},
                    sm: {span: 6},
                }} wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 14},
                }}>
                    <Form.Item
                        name="area"
                        label="街道"
                        initialValue={areaIdArrays}
                        rules={[
                            {
                                required: true, message: "请选择所处街道"
                            },
                        ]}
                    >
                        <Cascader style={{width: 250}}
                                  options={areaOptions}
                                  changeOnSelect={false} placeholder="街道/乡镇"/>
                    </Form.Item>


                    <Form.Item
                        name="location"
                        label="定位"
                        initialValue={loc}
                        rules={[
                            {
                                required: true, message: "请选择定位"
                            },
                        ]}
                    >
                        {loc ? (<div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{color: "#235c7a"}}>
                                    <div>经度:{loc?.lng}</div>
                                    <div>纬度:{loc?.lat}</div>
                                </div>
                                <div style={{width: "20px"}}/>
                                <div style={{display: "grid", placeItems: "center"}}>
                                    <Button type="primary" onClick={() => {
                                        setPickLocModalOpen(true)
                                    }}>重新选择
                                    </Button>
                                </div>

                            </div>
                        ) : (
                            <Button type="primary" onClick={() => {
                                getLocation();
                                setPickLocModalOpen(true);
                            }}>选择定位
                            </Button>
                        )}
                    </Form.Item>


                    {loc && (
                        <>
                            <Form.Item
                                name="communityAddress"
                                label="具体地址"
                                initialValue={community?.communityAddress}
                                rules={[
                                    {
                                        required: true, message: "请输入具体地址"
                                    },
                                ]}
                            >
                                <Input.TextArea placeholder="请输入具体地址"/>
                            </Form.Item>
                        </>
                    )}

                    <Form.Item
                        name="communityName"
                        label="名称"
                        initialValue={community?.communityName}
                        rules={[
                            {
                                required: true, message: "请输入名称"
                            },
                        ]}
                    >
                        <Input placeholder="请输入名称"/>
                    </Form.Item>



                    <Form.Item
                        name="communityArea"
                        label="占地面积"
                        initialValue={community?.communityArea}
                    >
                        <Input placeholder="请输入占地面积"/>
                    </Form.Item>

                    <Form.Item
                        name="buildingArea"
                        label="建筑面积"
                        initialValue={community?.buildingArea}
                    >
                        <Input placeholder="请输入建筑面积"/>
                    </Form.Item>

                    <Form.Item
                        name="level"
                        label="层数"
                        initialValue={community?.level}
                    >
                        <Input placeholder="请输入层数"/>
                    </Form.Item>

                    {community?.type===1&&(
                        <>
                            <Form.Item
                                name="communityChannel"
                                label="通道"
                                rules={[
                                    {
                                        required: true, message: "请输入通道"
                                    },
                                ]}
                                initialValue={community?.communityChannel}
                            >
                                <Input placeholder="请输入通道"/>
                            </Form.Item>

                            <Form.Item
                                name="code"
                                label="编号"
                                initialValue={community?.code}
                            >
                                <Input placeholder="请输入编号"/>
                            </Form.Item>

                            <Form.Item
                                name="houseDetail"
                                label="房屋幢数"
                                initialValue={community?.houseDetail}
                            >
                                <Input.TextArea placeholder="请输入房屋幢数信息"/>
                            </Form.Item>

                            <Form.Item
                                name="electricDetail"
                                label="配电设施"
                                initialValue={community?.electricDetail}
                            >
                                <Input.TextArea placeholder="请输入配电设施信息"/>
                            </Form.Item>

                            <Form.Item
                                name="publicPlace"
                                label="公众聚众场所"
                                initialValue={community?.publicPlace}
                            >
                                <Input.TextArea placeholder="请输入公众聚众场所信息"/>
                            </Form.Item>

                            <Form.Item
                                name="parkingPlace"
                                label="停车库"
                                initialValue={community?.parkingPlace}
                            >
                                <Input.TextArea placeholder="请输入停车库信息"/>
                            </Form.Item>

                            <Form.Item
                                name="innerTools"
                                label="内部固定消防设施"
                                initialValue={community?.innerTools}
                            >
                                <Input.TextArea placeholder="请输入内部固定消防设施信息"/>
                            </Form.Item>

                            <Form.Item
                                name="travelLine"
                                label="行驶路线"
                                initialValue={community?.travelLine}
                            >
                                <Input.TextArea placeholder="请输入行驶路线"/>
                            </Form.Item>

                            <Form.Item
                                name="travelDistance"
                                label="行驶距离"
                                initialValue={community?.travelDistance}
                            >
                                <Input placeholder="请输入行驶距离"/>
                            </Form.Item>

                            <Form.Item
                                name="travelTime"
                                label="行驶时间"
                                initialValue={community?.travelTime}
                            >
                                <Input placeholder="请输入行驶时间"/>
                            </Form.Item>

                            <Form.Item
                                name="waterMapDescription"
                                label="水源备注"
                                initialValue={community?.waterMapDescription}
                            >
                                <Input.TextArea placeholder="请输入水源备注"/>
                            </Form.Item>


                            <Form.Item
                                name="communityHeight"
                                label="建筑高度"
                                initialValue={community?.communityHeight}
                            >
                                <Input placeholder="请输入建筑高度"/>
                            </Form.Item>

                            <Form.Item
                                name="buildingFeatures"
                                label="建筑特点"
                                initialValue={community?.buildingFeatures}
                            >
                                <Input.TextArea placeholder="请输入建筑特点"/>
                            </Form.Item>

                            <Form.Item
                                name="fireRate"
                                label="耐火等级"
                                initialValue={community?.fireRate}
                            >
                                <Input placeholder="请输入耐火等级"/>
                            </Form.Item>

                            <Form.Item
                                name="natureUse"
                                label="使用性质"
                                initialValue={community?.natureUse}
                            >
                                <Input placeholder="请输入使用性质"/>
                            </Form.Item>

                            <Form.Item
                                name="totalHeight"
                                label="总高度"
                                initialValue={community?.totalHeight}
                            >
                                <Input placeholder="请输入总高度"/>
                            </Form.Item>

                            <Form.Item
                                name="east"
                                label="毗邻情况(东)"
                                initialValue={community?.east}
                            >
                                <Input placeholder="请输入东边毗邻情况"/>
                            </Form.Item>

                            <Form.Item
                                name="west"
                                label="毗邻情况(西)"
                                initialValue={community?.west}
                            >
                                <Input placeholder="请输入西边毗邻情况"/>
                            </Form.Item>

                            <Form.Item
                                name="south"
                                label="毗邻情况(南)"
                                initialValue={community?.south}
                            >
                                <Input placeholder="请输入南边毗邻情况"/>
                            </Form.Item>

                            <Form.Item
                                name="nouth"
                                label="毗邻情况(北)"
                                initialValue={community?.nouth}
                            >
                                <Input placeholder="请输入北边毗邻情况"/>
                            </Form.Item>


                            <Form.Item
                                name="minDistance"
                                label="距最近消防站行车距离"
                                initialValue={community?.minDistance}
                            >
                                <Input placeholder="请输入距最近消防站行车距离"/>
                            </Form.Item>

                            <Form.Item
                                name="keySafety"
                                label="重点安全隐患"
                                initialValue={community?.keySafety}
                            >
                                <Input placeholder="请输入重点安全隐患"/>
                            </Form.Item>
                        </>
                    )}

                    <Form.Item
                        name="communityRemark"
                        label="备注"
                        initialValue={community?.communityRemark}
                    >
                        <Input placeholder="请输入备注"/>
                    </Form.Item>

                    {/*<Form.Item*/}
                    {/*    name="pictures"*/}
                    {/*    label="总平面图"*/}
                    {/*    valuePropName="fileList"*/}
                    {/*>*/}
                    {/*    <UpdatePictures defaultList={*/}
                    {/*        [{*/}
                    {/*            url: community?.communityMapUrl,*/}
                    {/*            pictureName: 'plan'*/}
                    {/*        }]} id={community?.communityId} name={'plan'} type={2} max={1}/>*/}
                    {/*</Form.Item>*/}

                    {/*{(community?.type===2||community?.type===3)&&(*/}
                    {/*    <Form.Item*/}
                    {/*        name="floorPictures"*/}
                    {/*        label='平面图'*/}
                    {/*        valuePropName="fileList"*/}
                    {/*    >*/}
                    {/*        <UpdatePictures defaultList={community?.floorPictures?.map((d:any)=>{*/}
                    {/*            return {*/}
                    {/*                url: d?.url,*/}
                    {/*                pictureName: d?.pictureName*/}
                    {/*            }*/}
                    {/*        })} id={community?.communityId} name={'plan'} type={3} max={1000}/>*/}
                    {/*    </Form.Item>*/}
                    {/*)}*/}

                    <Form.Item
                        name="pdfUrl"
                        label="作战辅助图"
                        valuePropName="fileList"
                    >
                        <UpdateFile type={2} max={1} fileUrl={community?.pdfUrl} afterSuccess={async (e:any) => {
                            setPdfUrl(e)
                        }}/>
                    </Form.Item>
                </Form>


                <PickLocationModal loc={initLoc} open={pickLocModalOpen} onCancel={handleCancelLocModal}
                                   confirmLoc={(e: any) => {
                                       confirmLoc(e)
                                   }} confirmAddress={confirmAddress} confirmRoad={() => {
                }} confirmName={() => {}} repick={undefined}/>

            </Modal>


        </div>
    );
};

export default EditCommunityModal;