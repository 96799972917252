import React, {useEffect, useState} from 'react';
import {Modal} from "antd";
import {iHydrant} from "../../interface";
import styles from "./index.module.css"
import {getAreaTree} from "../../api";
import {getAreaPath, transform} from "../functions";
import {Map, Marker} from "react-amap";

const HydrantDetailModal = ({open, onClose, hydrant}: { open: boolean, onClose: () => void, hydrant: iHydrant }) => {

    const [areaTree, setAreaTree] = useState<any>();
    const handleGetArea = () => {
        getAreaTree().then((res) => {
            setAreaTree(res?.data);
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        if (open) {
            handleGetArea()
        }
    }, [open])

    return (
        <Modal open={open} onOk={onClose} onCancel={onClose} title={`XHS${hydrant?.hydrantName}详情`}
               cancelButtonProps={{style: {display: 'none'}}} okText={'知道了'}>
            <div>
                <div className={styles.row}>
                    <div className={styles.title}>名称</div>
                    <div className={styles.content}>{hydrant?.hydrantName}</div>
                </div>

                <div className={styles.row}>
                    <div className={styles.title}>县区</div>
                    <div className={styles.content}>
                        {areaTree ? getAreaPath(areaTree, hydrant?.areaId as string) : "数据加载中"}
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.title}>详细地址</div>
                    <div className={styles.content}>{hydrant?.hydrantAddress}</div>
                </div>

                <div className={styles.row}>
                    <div className={styles.title}>二维码</div>
                    <div className={styles.content}>{hydrant?.qrCode}</div>
                </div>

                <div className={styles.row}>
                    <div className={styles.title}>XHS状态</div>
                    <div className={styles.content}>{hydrant?.hydrantState}</div>
                </div>

                <div className={styles.row}>
                    <div className={styles.title}>描述</div>
                    <div className={styles.content}>{hydrant?.description}</div>
                </div>

                <div style={{width: '100%', height: "40vh"}}>
                    <Map plugins={['ToolBar']}
                         center={{longitude: Number(hydrant?.lng), latitude: Number(hydrant?.lat)}} zoom={17}>
                        <Marker position={{longitude: Number(hydrant?.lng), latitude: Number(hydrant?.lat)}}/>
                    </Map>
                </div>
            </div>
        </Modal>
    );
};

export default HydrantDetailModal;