import {Button, Image, Modal} from "antd";
import styles from "../../pages/manage/exercise/index.module.css";
import React, {useEffect, useState} from "react";
import {getHiddenDangerData} from "../../api";
import {showFail, showNetError} from "../functions";
import locationIcon from "../../assets/icons/location.svg";
import LocationModal from "../locationModal";

export const HiddenDangerDetailModal = ({
                                        open,
                                        onCancel,
                                        dangerId
                                    }: { open: boolean, onCancel: () => void, dangerId: string }) => {
    const [hiddenDangerData, setHiddenDangerData] = useState<any>()
    const [images, setImages] = useState<any[]>([]);
    const [imageVisible, setImageVisible] = useState<boolean>(false);
    const handleGetExerciseDetail = () => {
        console.log('hiddenDangerId',dangerId)
        if(dangerId){
            getHiddenDangerData({dangerId}).then((res: any) => {
                if (res?.code === 200) {
                    setHiddenDangerData(res?.data[0])
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                showNetError();
                console.log(err)
            })
        }else{
            showFail("隐患id为空!")
        }
    }

    useEffect(() => {
        if (open) {
            handleGetExerciseDetail()
        }
    }, [open])

    const [locationModalOpen, setLocationModalOpen] = useState(false);
    const handleCloseLocationModal = () => {
        setLocationModalOpen(false);
    }

    return (
        <><Modal open={open} onCancel={onCancel} title={'隐患排查详情'} maskClosable={false}>
            {hiddenDangerData?.communityName && (
                <div className={styles.row}>
                    <div className={styles.title}>地点</div>
                    <div
                        className={styles.content1}>
                        {hiddenDangerData?.communityName}
                        <img style={{marginLeft: '10px', cursor: 'pointer'}} width={25} height={25} src={locationIcon}
                             alt="" onClick={() => {
                            setLocationModalOpen(true);
                        }}/>
                    </div>
                </div>
            )}

            {hiddenDangerData?.backUser && (
                <div className={styles.row}>
                    <div className={styles.title}>检查人</div>
                    <div
                        className={styles.content}>
                        {hiddenDangerData?.backUser?.username} {hiddenDangerData?.backUser?.mobile}
                    </div>
                </div>
            )}

            {hiddenDangerData?.isDanger!==undefined && (
                <div className={styles.row}>
                    <div className={styles.title}>是否存在隐患</div>
                    <div
                        className={styles.content}>
                        {hiddenDangerData?.isDanger===1?"是":"否"}
                    </div>
                </div>
            )}


            {hiddenDangerData?.dangerEnterTime && (
                <div className={styles.row}>
                    <div className={styles.title}>隐患上报时间</div>
                    <div
                        className={styles.content}>
                        {hiddenDangerData?.dangerEnterTime}
                    </div>
                </div>
            )}


            {hiddenDangerData?.dangerDetail && (
                <div className={styles.row}>
                    <div className={styles.title}>隐患描述</div>
                    <div
                        className={styles.content}>
                        {hiddenDangerData?.dangerDetail}
                    </div>
                </div>
            )}

            {hiddenDangerData?.dangerPictures && hiddenDangerData?.dangerPictures?.filter((d:any)=>(d?.pictureType===1))?.length > 0 && (
                <div className={styles.row}>
                    <div className={styles.title}>隐患照片</div>
                    <div className={styles.content}>
                        <Button type={'link'} onClick={() => {
                            let picList = hiddenDangerData?.dangerPictures?.map((d: any) => {
                                return {
                                    src: d?.url,
                                    key: d?.dangerPictureId
                                }
                            });
                            setImages(picList);
                            setImageVisible(true);
                        }}>查看</Button>
                    </div>
                </div>
            )}

            {hiddenDangerData?.dangerCompleteTime && (
                <div className={styles.row}>
                    <div className={styles.title}>办结时间</div>
                    <div
                        className={styles.content}>
                        {hiddenDangerData?.dangerCompleteTime}
                    </div>
                </div>
            )}

            {hiddenDangerData?.dangerPictures && hiddenDangerData?.dangerPictures?.filter((d:any)=>(d?.pictureType===2))?.length > 0 && (
                <div className={styles.row}>
                    <div className={styles.title}>办结照片</div>
                    <div className={styles.content}>
                        <Button type={'link'} onClick={() => {
                            let picList = hiddenDangerData?.dangerPictures?.map((d: any) => {
                                return {
                                    src: d?.url,
                                    key: d?.dangerPictureId
                                }
                            });
                            setImages(picList);
                            setImageVisible(true);
                        }}>查看</Button>
                    </div>
                </div>
            )}
        </Modal>
            <Image.PreviewGroup
                items={images}
                preview={{
                    visible: imageVisible,
                    onVisibleChange: (value) => {
                        setImageVisible(value);
                    },
                }}
            >
                {images.map((image: any) => (
                    <Image
                        key={image.key}
                        width={200}
                        height={200}
                        style={{display: 'none'}}
                        src={image.src}
                        preview={{
                            scaleStep: 0.5,
                            src: image.src,
                        }}
                    />
                ))}
            </Image.PreviewGroup>

            <LocationModal open={locationModalOpen} onCancel={handleCloseLocationModal}
                           lat={hiddenDangerData?.communityLat} lng={hiddenDangerData?.communityLng}/>
        </>
    )
}