import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space, Spin,
    Table, Tooltip, TreeSelect,
} from "antd";
import './index.css'
import {PlusOutlined, TeamOutlined} from "@ant-design/icons";
import {AxiosError} from "axios";
// @ts-ignore
import {debounce} from "lodash";
import {
    getUser,
    addUser,
    deleteUser,
    updateUser,
    searchForUser,
    getDeptTree, getDeptById, getAllCompanyNotPage, getUnits, getAreaTree
} from "../../../api";
import {showWarning} from "../../../components/functions";

const {Option} = Select;

const {AMap} = window as any
//用户管理
const UserManagement = () => {
    const isSuperManager = JSON.parse(window.localStorage?.getItem("isSuperManager") as string);
    const isManager = JSON.parse(window.localStorage?.getItem("isManager") as string);

    //数据接口
    interface DataType {
        key?: React.Key,
        pageNum?: number,
        pageSize?: number,
        username?: string,
        xqName?: string,
        zjName?: string,
        Authorization?: string,
    }
    interface areatree {
        value?: string,
        title?: string,
        children?: any[],
    }
    //保存数据
    const [areaTree, setAreaTree] = useState([]);
    const [areas, setAreas] = useState<any>([]);
    function tree(list:areatree[],res:any[]){
        for (let i = 0; i < res.length; i++) {
            if(res[i].children){
                // @ts-ignore
                if(res[i].areaId!=="2") {

                    list.push({value: res[i].areaId, title: res[i].name, children: []})
                }
            }else {
                // @ts-ignore
                if(res[i].areaId!=="2") {
                    list.push({value: res[i].areaId, title: res[i].name})
                }
            }
            // @ts-ignore
            if(res[i].children) {
                // @ts-ignore
                tree(list[i].children, res[i].children)
            }
        }
        return list

    }
    //查询权限Tree
    useEffect(()=>{
        let params = {}
        getAreaTree(params).then( (res:any)=>{
            setAreaTree(res.data);
            let a:areatree[]=[]
            let b=[]
            b.push(res.data)
            let list=tree(a,b)
            setAreas(list)
            console.log(list)
        }, (err:AxiosError)=>{
            console.log(err);
        });


    },[])
    let [dataSource, setDataSource] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    let [orderTotal, setOrderTotal] = useState(0);
    const [addSpin, setAddSpin] = useState<boolean>(false);
    const [updateSpin, setUpdateSpin] = useState<boolean>(false);

    useEffect(() => {
        let paramsw = {roleType: 0}
        searchForUser(paramsw).then((res: any) => {
            setSelectSysRoles(res.data)
        })


        let params: DataType = {
            pageNum: 1,
            pageSize: 10,
        };
        getUser(params).then((res: any) => {
            if (/^4[0-9][0-9]/.test(res.code)) {
                message.error(res.msg);
            } else {
                for (let i = 0; i < res.data.records.length; i++) {
                    if (res.data.records[i].sysRoles.length > 0) {
                        let a = ''
                        for (let j = 0; j < res.data.records[i].sysRoles.length; j++) {
                            if (j < res.data.records[i].sysRoles.length - 1) {
                                a += res.data.records[i].sysRoles[j].name + ','
                            } else {
                                a += res.data.records[i].sysRoles[j].name
                            }
                        }
                        res.data.records[i].roleName = a;
                    }
                }
                setDataSource(res.data.records);
                setOrderTotal(res.data.total);
                setCurrentPage(res.data.current);
            }
        }, (err: AxiosError) => {
            console.log(err);
        })
    }, [])

    let [inputName, setInputName] = useState<any>('');
    let [inputMobile, setInputMobile] = useState<any>('');


    //查询用户
    const onGetUser = (pageNum: number, pageSize: number) => {
        let params = {
            username: inputName,
            mobile: inputMobile,
            pageNum: pageNum,
            pageSize: pageSize,
        };

        getUser(params).then((res: any) => {
            if (/^4[0-9][0-9]/.test(res.code)) {
                message.error(res.msg);
                //setCustOrderLoading(false);
            } else {

                for (let i = 0; i < res.data.records.length; i++) {
                    if (res.data.records[i].sysRoles.length > 0) {
                        let a = ''
                        for (let j = 0; j < res.data.records[i].sysRoles.length; j++) {
                            if (j < res.data.records[i].sysRoles.length - 1) {
                                a += res.data.records[i].sysRoles[j].name + ','
                            } else {
                                a += res.data.records[i].sysRoles[j].name
                            }
                        }
                        res.data.records[i].roleName = a;
                    }
                }
                setDataSource(res.data.records);
                setOrderTotal(res.data.total);
                setCurrentPage(res.data.current);
            }
        }, (err: AxiosError) => {
            console.log(err);
        })
    }

    //新增用户数据
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [username, setUserName] = useState<any>('');
    const [mobile, setMobile] = useState<any>('');
    const [isMaintenance, setIsMaintenance] = useState<any>('');
    const [sysRoles, setSysRoles] = useState<any[]>([]);
    const [selectSysRoles, setSelectSysRoles] = useState<any[]>([]);
    const [isCompany, setIsCompany] = useState<boolean>(false);
    const [isUnit, setIsUnit] = useState<boolean>(false);
    const [isArea, setIsArea] = useState<boolean>(false);

    //新增用户
    const onAddUser = (e: any) => {
        console.log('add')

        let a=false
        let b=false
        let c=false
        let _sysRole2 = [];
            for (let i = 0; i < e.roles1.length; i++) {
                _sysRole2.push({'id': e.roles1[i]})
                if(e.roles1[i]=='1814106347779866626'){
                    a=true
                }
                if(e.roles1[i]=='1814677525359742977'){
                    b=true
                }
                if(e.roles1[i]=='1819694010151743490'){
                    c=true
                }
            }
        if(a&&b){
            message.error('单位管理员和停车场管理员不能同时选择')
            return
        }
        setAddSpin(true)
        let params2 = {
            username: e.username,
            dept: e.dept,
            deptId: e.deptId,
            mobile: e.mobile,
            wxMobile: e.wxMobile,
            companyId:e.companyId,
            sysRoles:_sysRole2,
            areaId: c?1:e.areaId,
            unitId: e.unitId,
        }

        addUser(params2).then((res: any) => {
            if (/^5[0-9][0-9]/.test(res.code)) {
                message.error(res.msg);
                setAddSpin(false)
                setIsCompany(false)
                setIsUnit(false)
                setIsArea(false)
            } else {
                message.success(res.msg);
                form.resetFields()
                setSysRoles([])
                setAddSpin(false)
                setIsAddModalOpen(false);
                onGetUser(currentPage, 10);
                setCompanyList([])
            }
        }, (err: AxiosError) => {
            setAddSpin(false)
            console.log(err);
        })
    }

    const onupdateUser = (e: any) => {

        let a=false
        let b=false
        let _sysRole2 = [];
            for (let i = 0; i < e.roles1.length; i++) {
                if(e.roles1[i]=='1814106347779866626'){
                    a=true
                }
                if(e.roles1[i]=='1814677525359742977'){
                    b=true
                }
                _sysRole2.push({'id': e.roles1[i]})
            }
        if(a&&b){
            message.error('单位管理员和停车场管理员不能同时选择')
            return
        }
        setUpdateSpin(true)
        let params2 = {
                userId: updateUserdata.userId,
                username: e.username,
                mobile: e.mobile,
                wxMobile: e.wxMobile,
                companyId:e.companyId,
                sysRoles: _sysRole2,
            areaId: e.areaId,
            unitId: e.unitId,

        }
        updateUser(params2).then((res: any) => {
            if (/^5[0-9][0-9]/.test(res.code)) {
                message.error(res.msg);
                setUpdateSpin(false)
            } else {
                message.success(res.msg);
                form2.resetFields()
                setUpdateSpin(false)
                setUpdateModalOpen(false);
                setSysRoles([])
                setUpdateUserdata('')
                onGetUser(currentPage, 10);
            }
        }, (err: AxiosError) => {
            setUpdateSpin(false)
            console.log(err);
        })
    }


    //删除用户
    const onDeleteUser = (val: any) => {
        console.log(val)
        let params = {
            userId: val.userId,
        }

        deleteUser(params).then((res: any) => {
            if (/^5[0-9][0-9]/.test(res.code)) {
                message.error(res.msg);
            } else {
                message.success(res.msg);
                onGetUser(currentPage, 10);

            }
        }, (err: AxiosError) => {
            console.log(err);
        })

    };


    //修改对话框
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const handleUpdateCancel = () => {
        setUpdateModalOpen(false);
    };
    //保存原修改数据
    const [updateUserdata, setUpdateUserdata] = useState<any>('');

    const DetailModal = ({open, onCancel, record}: { open: any, onCancel: any, record: any }) => {

        return (
            <Modal
                title='维修人员详情'
                open={open}
                onCancel={onCancel}
                footer={[
                    <Button key="back" onClick={() => onCancel()}>
                        取消
                    </Button>]}
            >

                <Form>
                    <Form.Item style={{width: '300px', marginBottom: '10px'}}
                               label={"服务区域"}>{record?.staffArea}</Form.Item>
                    <Form.Item style={{width: '300px', marginBottom: '10px'}}
                               label={"维护单位名"}>{record?.maintenanceOrganization?.maintenanceName}</Form.Item>
                    {record?.maintenanceTeam ? <Form.Item style={{width: '300px', marginBottom: '10px'}}
                                                          label={"维护团队"}>{record?.maintenanceTeam?.teamName}</Form.Item> : ''}
                    <Form.Item style={{width: '300px', marginBottom: '10px'}}
                               label={"在线情况"}>{record?.staffIsonline}</Form.Item>
                    <Form.Item style={{width: '300px', marginBottom: '10px'}}
                               label={"在职情况"}>{record?.staffState}</Form.Item>
                </Form>
            </Modal>
        )
    }

    //保存角色数据
    const [role, setRole] = useState<any>('');


    //修改权限对话框
    const [userRoleModalOpen, setUserRoleModalOpen] = useState(false);
    const handleUserRoleCancel = () => {
        setUserRoleModalOpen(false);
    };


    const [handleDetailOpen, setHandleDetailOpen] = useState(false);
    const handleDetailCancel = () => {
        setHandleDetailOpen(false);
    };

    const [companyList, setCompanyList] = useState<any[]>([]);
    const [unitList, setUnitList] = useState<any[]>([]);

    const [areaList, setAreaList] = useState<any[]>([]);



    //表头数据
    const Columns = [
        {
            title: '用户名',
            dataIndex: 'username',
            width: 200
        },

       {
            title: '联系电话',
            dataIndex: 'mobile',
            width: 200
        },
        {
            title: '微信手机号',
            dataIndex: 'wxMobile',
            width: 200
        },
        {
            title: '停车场',
            dataIndex: ['parkCompany','companyName'],
            width: 200
        },
        {
            title: '单位',
            dataIndex: ['unit','unitName'],
            width: 200
        },
        {
            title: '区域',
            dataIndex: ['area','name'],
            width: 200
        },
        {
            title: '角色',
            dataIndex: 'roleName',
            width: 200
        },
        {
            title: '操作',
            dataIndex: 'operate',
            width: 200,
            render: (_: any, record: any) => (
                (dataSource.length >= 1 && (isSuperManager || isManager)) ? (
                    <Space>
                        <Popconfirm title="确认删除吗?" onConfirm={() => onDeleteUser(record)}>
                            <a>删除</a>
                        </Popconfirm>

                        <a onClick={() => {
                            setUpdateUserdata(record);
                            let params = {roleType: 0}
                            searchForUser(params).then((res2: any) => {
                                setSelectSysRoles(res2.data)
                                let _sysRole: any = []
                                let a=false
                                let b=false
                                let c=false
                                for (let i = 0; i < record.sysRoles.length; i++) {
                                    _sysRole.push(record.sysRoles[i].id)
                                    if(record.sysRoles[i].id==='1814106347779866626'){
                                        a=true
                                        setCompanyList([{label: record.parkCompany.companyName, value: record.companyId}])
                                    }
                                    if(record.sysRoles[i].id==='1814677525359742977'){
                                        b=true
                                        setUnitList([{label: record.unit.unitName, value: record.unitId}])
                                    }
                                    if(record.sysRoles[i].id==='2'){
                                        c=true
                                        setAreaList([{label: record.area.name, value: record.areaId}])
                                    }
                                }
                                setIsCompany(a)
                                setIsUnit(b)
                                setIsArea(c)
                                setUpdateModalOpen(true);
                                setSysRoles(record.sysRoles)
                                form2.setFieldsValue({
                                    username: record.username,
                                    mobile: record.mobile,
                                    wxMobile: record.wxMobile,
                                    roles1: _sysRole,
                                    companyId:record.companyId,
                                    unitId:record.unitId,
                                    areaId:record.areaId
                                })
                            })
                        }}>修改</a>

                    </Space>
                ) : null
            )
        },

    ]

    const [form] = Form.useForm()
    const [form2] = Form.useForm()

    const changeName = (e: any) => {
        let v = e?.target?.value;
        if (v) {
            let params: any = {companyName: v};

            getAllCompanyNotPage(params).then((res: any) => {
                console.log(res)
                if (res?.code === 200) {
                    setCompanyList(res?.data?.map((d: any) => {
                        return {
                            value: d?.companyId,
                            label: d.companyName
                        }
                    }))
                }
            })
        } else {
            showWarning("请输入归属人姓名或手机号")
        }
    }
    const changeUnitName = (e: any) => {
        let v = e?.target?.value;
        if (v) {
            let params: any = {unitName: v};

            getUnits(params).then((res: any) => {
                console.log(res)
                if (res?.code === 200) {
                    setUnitList(res?.data?.map((d: any) => {
                        return {
                            value: d?.unitId,
                            label: d.unitName
                        }
                    }))
                }
            })
        } else {
            showWarning("请输入归属人姓名或手机号")
        }
    }

    return (
        <div className='user_management_layout'>


            <div className='user_management_search'>
                <div className='user_management_header'><TeamOutlined/> 工号管理</div>
                <div style={{width: '80%'}}>
                    <Row gutter={24}>
                        <Col span={4}>
                            <Input placeholder="请输入用户名" onChange={(event: any) => {
                                setInputName(event.target.value)
                            }} allowClear={true}/>
                        </Col>
                        <Col span={4}>
                            <Input placeholder={"手机号"} onChange={(event: any) => {
                                setInputMobile(event.target.value)
                            }} allowClear={true}/>
                        </Col>

                        <Col span={4}>
                            <Button style={{float: 'right'}} type="primary"
                                    onClick={debounce(() => onGetUser(1, 10), 300)}>查询</Button>
                        </Col>
                    </Row>
                </div>
            </div>
            <Divider style={{margin: 0}}/>

            {(isSuperManager || isManager) && (
                <div className='user_management_add'>
                    <Button onClick={() => {
                        setIsAddModalOpen(true)
                    }} type="primary"><PlusOutlined/>新增</Button>
                </div>
            )}

            <div>
                <Table
                    rowKey="id"
                    columns={Columns}
                    dataSource={dataSource}
                    pagination={{
                        //current: currentPage,
                        onChange: (page: number, pagesize: number) => onGetUser(page, pagesize),
                        total: orderTotal,
                        pageSize: 10,
                        showSizeChanger: false,
                        onShowSizeChange: (page: number, pagesize: number) => onGetUser(page, pagesize),
                        showTotal: ((total: number) => {
                            return `共${total}条`
                        })
                    }}
                />
            </div>

            <Modal
                title='新增用户'
                open={isAddModalOpen}
                onCancel={() => {setIsAddModalOpen(false)
                    setCompanyList([])
                    setIsCompany(false)
                    setIsUnit(false)
                    setIsArea(false)
                    form.resetFields()
                }}
                footer={[
                    <Button key="back" onClick={() => {
                        setIsAddModalOpen(false)
                        setCompanyList([])
                        setIsCompany(false)
                        setIsUnit(false)
                        setIsArea(false)
                        form.resetFields()
                    }

                    }>
                        取消
                    </Button>]}
            >
                <Spin tip="Loading..." spinning={addSpin}>
                    <Form name="basic"
                          style={{maxWidth: 600}}
                          onFinish={(e: any) => onAddUser(e)}
                          form={form}>
                        <Form.Item name={"username"} rules={[{required: true, message: '请输入用户名！'}]} label={'用户名'}><Input
                            onChange={(e) => setUserName(e.target.value)}/></Form.Item>
                        <Form.Item name={"mobile"} rules={[{required: true, message: '请输入手机号!'}]} label={'手机号'}><Input
                            style={{width: '300px'}} onChange={(event: any) => {
                            setMobile(event.target.value)
                        }}/></Form.Item>
                        <Form.Item name={"wxMobile"} label={'微信手机号'}><Input style={{width: '300px'}}/></Form.Item>

                        <Form.Item name={"roles1"} style={{width: '100%'}} label={'用户角色'}
                                   rules={[{required: true, message: '请选择用户角色!'}]}>
                            <Select mode={"multiple"} style={{width: '100%'}} onChange={(e: any) => {
                                setSysRoles(e)
                                console.log(e)
                                let a=false
                                let b=false
                                let c=false
                                for (let i = 0; i < e.length; i++) {
                                    if(e[i]==='1814106347779866626'){
                                        a=true
                                    }
                                    if(e[i]==='1814677525359742977'){
                                        b=true
                                    }
                                    if(e[i]==='2'){
                                        c=true
                                    }
                                }
                                setIsCompany(a)
                                setIsUnit(b)
                                setIsArea(c)
                            }}>
                                {selectSysRoles.map((value => {
                                    return (<Option key={value.name} value={value.id}>{value.name}</Option>)
                                }))}
                            </Select>
                        </Form.Item>
                        {isCompany?
                            <Form.Item
                                name="companyId"
                                label="归属停车场"
                                rules={[
                                    {
                                        required: true, message: "归属单位不能为空"
                                    },
                                ]}
                            >
                                <Select
                                    allowClear={true}
                                    placeholder={"请选择归属单位"}
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            <Divider style={{margin: '8px 0'}}/>
                                            <Space style={{padding: '4px 8px 4px '}}>
                                                <Input
                                                    allowClear
                                                    placeholder="请输入单位名称"
                                                    onChange={debounce(changeName, 600)}
                                                />
                                            </Space>
                                        </>
                                    )}
                                    options={companyList}
                                />
                            </Form.Item>:''}
                        {isUnit?
                        <Form.Item
                            name="unitId"
                            label="归属单位"
                            rules={[
                                {
                                    required: true, message: "归属单位不能为空"
                                },
                            ]}
                        >

                            <Select
                                allowClear={true}
                                placeholder={"请选择归属单位"}
                                dropdownRender={(area) => (
                                    <>
                                        {area}
                                        <Divider style={{margin: '8px 0'}}/>
                                        <Space style={{padding: '4px 8px 4px '}}>
                                            <Input
                                                allowClear
                                                placeholder="请输入单位名称"
                                                onChange={debounce(changeUnitName, 600)}
                                            />
                                        </Space>
                                    </>
                                )}
                                options={unitList}
                            />
                        </Form.Item>:''}
                        {isArea?
                        <Form.Item
                            name="areaId"
                            label="归属区域"
                            rules={[
                                {
                                    required: true, message: "归属区域不能为空"
                                },
                            ]}
                        ><TreeSelect
                            showSearch
                            style={{ width: '100%' }}
                            // value={areaTree}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder="归属区域"
                            allowClear
                            treeDefaultExpandAll
                            onChange={(e)=>{
                                if(e==='1'){
                                    message.error("区县管理员归属区域不能为湖州市")
                                    form.resetFields(["areaId"])
                                }}}
                            treeData={areas}
                        />
                        </Form.Item>:''}

                       {} <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form>
                </Spin>
            </Modal>


            <Modal
                title='修改用户'
                open={updateModalOpen}
                onCancel={() => {
                    setUpdateModalOpen(false)
                    form2.resetFields()
                    setSysRoles([])
                    setUpdateUserdata('')
                }}
                footer={[
                    <Button key="back" onClick={() => setUpdateModalOpen(false)}>
                        取消
                    </Button>]}
            >
                <Spin tip="Loading..." spinning={updateSpin}>
                    <Form name="basic"

                          style={{maxWidth: 600}}
                          onFinish={(e: any) => onupdateUser(e)}
                          form={form2}>
                        <Form.Item name={"username"} rules={[{required: true, message: '请输入用户名！'}]} label={'用户名'}><Input
                            onChange={(e) => setUserName(e.target.value)}/></Form.Item>
                        <Form.Item name={"mobile"} rules={[{required: true, message: '请输入手机号!'}]} label={'手机号'}><Input
                            style={{width: '300px'}} onChange={(event: any) => {
                            setMobile(event.target.value)
                        }}/></Form.Item>
                        <Form.Item name={"wxMobile"} label={'微信手机号'}><Input style={{width: '300px'}}/></Form.Item>

                        <Form.Item name={"roles1"} style={{width: '100%'}} label={'用户角色'}
                                   rules={[{required: true, message: '请选择用户角色!'}]}>
                            <Select mode={"multiple"} style={{width: '100%'}} onChange={(e: any) => {
                                setSysRoles(e)
                                let a=false
                                let b=false
                                let c=false
                                for (let i = 0; i < e.length; i++) {
                                    if(e[i]==='1814106347779866626') {
                                        a=true;
                                    }
                                    if(e[i]==='1814677525359742977'){
                                        b=true
                                    }
                                    if(e[i]==='2'){
                                        c=true
                                    }
                                }
                                setIsUnit(b)
                                setIsArea(c)
                                setIsCompany(a)
                            }}>
                                {selectSysRoles.map((value => {
                                    return (<Option key={value.name} value={value.id}>{value.name}</Option>)
                                }))}
                            </Select>
                        </Form.Item>

                        {isCompany?
                            <Form.Item
                                name="companyId"
                                label="归属停车场"
                                rules={[
                                    {
                                        required: true, message: "归属停车场不能为空"
                                    },
                                ]}
                            >
                                <Select
                                    allowClear={true}
                                    placeholder={"请选择归属单位"}
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            <Divider style={{margin: '8px 0'}}/>
                                            <Space style={{padding: '4px 8px 4px '}}>
                                                <Input
                                                    allowClear
                                                    placeholder="请输入停车场名称"
                                                    onChange={debounce(changeName, 600)}
                                                />
                                            </Space>
                                        </>
                                    )}
                                    options={companyList}
                                />
                            </Form.Item>:''}
                        {isUnit?
                            <Form.Item
                                name="unitId"
                                label="归属单位"
                                rules={[
                                    {
                                        required: true, message: "归属单位不能为空"
                                    },
                                ]}
                            >

                                <Select
                                    allowClear={true}
                                    placeholder={"请选择归属单位"}
                                    dropdownRender={(area) => (
                                        <>
                                            {area}
                                            <Divider style={{margin: '8px 0'}}/>
                                            <Space style={{padding: '4px 8px 4px '}}>
                                                <Input
                                                    allowClear
                                                    placeholder="请输入单位名称"
                                                    onChange={debounce(changeUnitName, 600)}
                                                />
                                            </Space>
                                        </>
                                    )}
                                    options={unitList}
                                />
                            </Form.Item>:''}
                        {isArea?
                            <Form.Item
                                name="areaId"
                                label="归属区域"
                                rules={[
                                    {
                                        required: true, message: "归属区域不能为空"
                                    },
                                ]}
                            ><TreeSelect
                                showSearch
                                style={{ width: '100%' }}
                                // value={areaTree}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                placeholder="归属区域"
                                allowClear
                                treeDefaultExpandAll
                                onChange={(e)=>{
                                    if(e==='1'){
                                        message.error("区县管理员归属区域不能为湖州市")
                                        form2.resetFields(["areaId"])
                                    }}}
                                treeData={areas}
                            />
                            </Form.Item>:''}
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form>
                </Spin>
            </Modal>
            <DetailModal record={updateUserdata} onCancel={handleDetailCancel} open={handleDetailOpen}/>
        </div>
    );
};

export default UserManagement;