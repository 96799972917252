import {
    AutoComplete,
    Button,
    Carousel,
    Cascader,
    Form,
    Input,
    Modal,
    Popconfirm,
    Select,
    Table,
    Tag,
    Tooltip,
    Image, Space
} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import styles from "./index.module.css"
import {iHydrant, iPicture} from "../../../interface";
import {
    addCar, cancelBan,
    deleteCar, getAllCar, getParkRecordPage, updateCar,
} from "../../../api";
import {getAreaPath, showFail, showNeedCompleteInfo, showSuccess, transform} from "../../../components/functions";
import AddRoadModal from "../../../components/addRoadModal";
// @ts-ignore
import {debounce} from "lodash";
import CarNumberInputDialog from "../../../components/carNumberInpuDialog";
// @ts-ignore

const Car = () => {
        const [pageNum, setPageNum] = useState<number>(1);
        const [pageSize, setPageSize] = useState<number>(10);

         const [pageNumRecord, setPageNumRecord] = useState<number>(1);
         const [pageSizeRecord, setPageSizeRecord] = useState<number>(10);
         const [totalRecord, setTotalRecord] = useState<number>(0);

        const [total, setTotal] = useState<number>(0);
        const [loading, setLoading] = useState<boolean>(false)

        const [hydrantList, setHydrantList] = useState<iHydrant[]>([])
        const [recordList, setRecordList] = useState<any[]>([])
    const onPageChangeRecord = (_page: number) => {
        setPageNumRecord(_page);
    };

    const onPageSizeChangeRecord = (_page: number, _pageSize: number) => {
        setPageSizeRecord(_pageSize);
    };


        const onPageChange = (_page: number) => {
            setPageNum(_page);
        };

        const onPageSizeChange = (_page: number, _pageSize: number) => {
            setPageSize(_pageSize);
        };

        let params: any = {}
        const handleGetParkCarList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
            setLoading(true);
            if (searchCondition?.carLicense) {
                params.carLicense = searchCondition?.carLicense
            }
            params.pageSize = pageSize;
            params.pageNum = pageNum;
            // params.companyId = window.localStorage.getItem('companyId');
            getAllCar(params).then((res: any) => {
                setLoading(false);
                if (res?.code === 200) {
                    setTotal(Number(res?.data?.total));
                    setHydrantList(res?.data?.records);
                } else {
                    showFail(res?.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        }

        useEffect(() => {
            handleGetParkCarList(pageNum, pageSize);
        }, [pageSize, pageNum])

        const [form] = Form.useForm();
        const [form2] = Form.useForm();

        const [keyBoardType, setKeyBoardType] = useState<number>(1);
        const [newCarLicense, setNewCarLicense] = useState<string>("浙E")
        const confirmCarLicense = (e: any) => {
            form2.setFieldValue('carLicense', e);
            setNewCarLicense(e);
        }

        const [updatedCarLicense, setUpdatedCarLicense] = useState<string>("浙E")
        const confirmUpdatedCarLicense = (e: any) => {
            form.setFieldValue('carLicense', e);
            setUpdatedCarLicense(e);
        }

        const [searchCondition, setSearchCondition] = useState({
            carLicense: "",
        });
        const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);

        const recordColumns: any = [
            {
                title: '停放单位',
                dataIndex: ['parkCompany','companyName'],
            },
            {
                title: '入库时间',
                dataIndex: 'enterRecordTime',
            },
            {
                title: '出库时间',
                dataIndex: 'outRecordTime',
            },
            {
                title: '停车时间',
                dataIndex: 'parkTime',
            },
            ]

        const columns: any = [
            {
                title: '车牌号',
                dataIndex: 'carLicense',
            },
            {
                title: '归属人',
                dataIndex: ['owner', 'username'],
            },
            {
                title: '归属人手机号',
                dataIndex: ['owner', 'mobile'],
            },
            {
                title: '车辆状态',
                dataIndex: 'carState',
            },
            {
                title: '当前状态',
                dataIndex: 'operation',
                key: 'operation',
                render: (_: any, record: any) => (
                    <div>
                    {record.isEnter === 1 ? <div>
                            <Space>停放中</Space>
                        </div> : <div>
                            <Space>未停放</Space>
                        </div>}
                    </div>
                )
            },
            {
                title: '停车记录',
                dataIndex: 'operation',
                key: 'operation',
                render: (_: any, record: any) => (
                    <Button type='primary' style={{marginTop: '10px'}} onClick={() => {
                        getParkRecordPage({carId: record?.carId, pageNum:pageNumRecord, pageSize:pageSizeRecord}).then(
                            (res: any) => {
                                if(res.code === 200){
                                    setRecordList(res?.data?.records)
                                    setTotalRecord(res?.data?.total)
                                }else {
                                    showFail(res?.msg)
                                }
                            }
                        )
                        setAddModalVisible(true);
                          }}>查看记录
            </Button>
                )
            },
            {
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                render: (_: any, record: any) => (
                    <div>
                        {record.carState==='可通行'? <Popconfirm title={"是否确认封禁该车辆"} onConfirm={() => {
                            deleteCar({carId: record?.carId}).then((res: any) => {
                                if (res?.code === 200) {
                                    handleGetParkCarList(pageNum, pageSize, newSearchCondition).then(() => {
                                        showSuccess(res?.msg);
                                    })
                                } else {
                                    showFail(res?.msg)
                                }
                            }).catch((err) => {
                                console.log(err)
                            })
                        }}>
                            <Button danger  >封禁</Button>
                        </Popconfirm>:record.carState==='不可通行'?<Popconfirm title={"是否确认封禁该车辆"} onConfirm={() => {
                            cancelBan({carId: record?.carId}).then((res: any) => {
                                if (res?.code === 200) {
                                    handleGetParkCarList(pageNum, pageSize, newSearchCondition).then(() => {
                                        showSuccess(res?.msg);
                                    })
                                } else {
                                    showFail(res?.msg)
                                }
                            }).catch((err) => {
                                console.log(err)
                            })
                        }}>
                            <Button    style={{color:'green'}}>解封</Button>
                        </Popconfirm>:''}

                        {/*<Button type={'link'} color={'primary'}*/}
                        {/*        onClick={() => {*/}
                        {/*            setUpdateModalVisible(true);*/}
                        {/*            form.setFieldValue('carLicense', record.carLicense)*/}
                        {/*            form.setFieldValue('brandAndType', record.brandAndType)*/}
                        {/*            form.setFieldValue('password', record.password);*/}
                        {/*            setUpdatedCarLicense(record.carLicense)*/}
                        {/*            setUpdateCarId(record.carId)*/}
                        {/*        }}*/}
                        {/*>修改</Button>*/}
                    </div>
                )
            },
        ];

        //新增
        const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
        const handleCloseAddModal = () => {
            setAddModalVisible(false);
        }
        const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);
        const handleCloseUpdateModal = () => {
            setUpdateModalVisible(false);
        }
        const [updateCarId, setUpdateCarId] = useState<boolean>(false);
        const handleUpdateNewCar = () => {
            form.validateFields().then((value) => {
                let newCar = {
                    carLicense: value?.carLicense,
                    companyId: form.getFieldValue('companyId'),
                    brandAndType: value?.brandAndType,
                    password: value?.password,
                    carId: updateCarId
                }
                setLoading(true);
                updateCar(newCar).then((res: any) => {
                    setLoading(false);
                    console.log(res)
                    if (res?.code === 200) {
                        handleGetParkCarList(pageNum, pageSize).then(() => {
                            showSuccess(res?.msg);
                            handleCloseUpdateModal();
                        })
                    } else {
                        showFail(res?.msg)
                    }
                }).catch((err: any) => {
                    console.log(err)
                })

            }).catch((err) => {
                console.log(err);
                showNeedCompleteInfo();
            })
        }

        const handleAddNewCar = () => {
            form2.validateFields().then((value) => {
                let newCar = {
                    carId: value?.carId,
                    carLicense: value?.carLicense,
                    companyId: window.localStorage.getItem('companyId'),
                    brandAndType: value?.brandAndType,
                    password: value?.password ? value?.password : value.carLicense
                }
                console.log(value)
                setLoading(true);
                addCar(newCar).then((res: any) => {
                    setLoading(false);
                    console.log(res)
                    if (res?.code === 200) {
                        form2.resetFields();
                        handleGetParkCarList(pageNum, pageSize).then(() => {
                            showSuccess(res?.msg);
                            handleCloseAddModal();
                        })
                    } else {
                        showFail(res?.msg)
                    }
                }).catch((err: any) => {
                    console.log(err)
                })

            }).catch((err) => {
                console.log(err);
                showNeedCompleteInfo();
            }).finally(() => {
                setLoading(false)
            })
        }


        const [carNumberKeyboardOpen, setCarNumberKeyboardOpen] = useState<boolean>(false);
        const handleCloseCarNumberKeyboard = () => {
            setCarNumberKeyboardOpen(false);
        }

        return (
            <div className={styles.main}>

                <div className={styles.mainHead}>
                    <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}} onChange={(e:any)=>{setSearchCondition({carLicense: e.target.value})}} placeholder={'车牌号'} allowClear></Input>

                    <div>

                        <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                            handleGetParkCarList(1, 10, searchCondition);
                            setPageNum(1);
                            setPageSize(10);
                            setNewSearchCondition(searchCondition)
                        }}>查询
                        </Button>

                        {/*<Button type='primary' style={{marginTop: '10px'}} onClick={() => {*/}
                        {/*    setAddModalVisible(true);*/}
                        {/*}}>新增*/}
                        {/*</Button>*/}

                    </div>

                </div>

                <Table rowKey={'carId'} columns={columns} dataSource={hydrantList} loading={loading}
                       pagination={{
                           current: pageNum,
                           onChange: onPageChange,
                           onShowSizeChange: onPageSizeChange,
                           total: total,
                           showSizeChanger: true,
                           defaultPageSize: pageSize,
                           pageSizeOptions: ['10', '20', '50', '100'],
                       }}/>

                {/*修改*/}
                <Modal width={'700px'} open={updateModalVisible} onCancel={() => {
                    handleCloseUpdateModal();
                    setLoading(false)
                }} title={'清运车辆修改'} maskClosable={false} okText={'确认提交'}
                       onOk={debounce(handleUpdateNewCar, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                    <Form form={form} name="form" labelAlign="right" labelCol={{
                        xs: {span: 24},
                        sm: {span: 6},
                    }} wrapperCol={{
                        xs: {span: 24},
                        sm: {span: 14},
                    }}>
                        <Form.Item
                            name="carLicense"
                            label="车牌号"
                            rules={[
                                {
                                    required: true, message: "请输入车牌号"
                                },
                                {
                                    min: 5,
                                    message: "车牌号长度至少大于5位",
                                },
                            ]}
                        >
                            <div className={styles.carLine} onClick={() => {
                                setKeyBoardType(2)
                                setCarNumberKeyboardOpen(true);
                            }}>
                                <div className={styles.carBlock}
                                     style={{backgroundColor: updatedCarLicense.length === 8 ? "#53ce34" : "#0e2774"}}>
                                    {updatedCarLicense[0] || ""}
                                </div>
                                <div className={styles.carBlock}
                                     style={{backgroundColor: updatedCarLicense.length === 8 ? "#53ce34" : "#0e2774"}}
                                >{updatedCarLicense[1] || ""}</div>
                                <div className={styles.carDotBlock}>
                                    <div className={styles.carDot}/>
                                </div>
                                <div className={styles.carBlock}
                                     style={{backgroundColor: updatedCarLicense.length === 8 ? "#53ce34" : "#0e2774"}}>{updatedCarLicense[2] || ""}</div>
                                <div className={styles.carBlock}
                                     style={{backgroundColor: updatedCarLicense.length === 8 ? "#53ce34" : "#0e2774"}}>{updatedCarLicense[3] || ""}</div>
                                <div className={styles.carBlock}
                                     style={{backgroundColor: updatedCarLicense.length === 8 ? "#53ce34" : "#0e2774"}}>{updatedCarLicense[4] || ""}</div>
                                <div className={styles.carBlock}
                                     style={{backgroundColor: updatedCarLicense.length === 8 ? "#53ce34" : "#0e2774"}}>{updatedCarLicense[5] || ""}</div>
                                <div className={styles.carBlock}
                                     style={{backgroundColor: updatedCarLicense.length === 8 ? "#53ce34" : "#0e2774"}}>{updatedCarLicense[6] || ""}</div>
                                {updatedCarLicense?.length === 8 ? (
                                    <div className={styles.carBlock}
                                         style={{backgroundColor: updatedCarLicense.length === 8 ? "#53ce34" : "#0e2774"}}>{updatedCarLicense[7] || ""}</div>
                                ) : (
                                    <div className={styles.newPowerBlock}>
                                        <div>新能源</div>
                                    </div>
                                )}

                            </div>
                        </Form.Item>
                        <Form.Item
                            name="brandAndType"
                            label="品牌型号"
                        >
                            <Input placeholder="请输入品牌型号"/>
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="密码"
                        ><Input placeholder="请输入密码"/></Form.Item>
                        <label style={{marginLeft: '50px'}}>非必填，不填车辆司机登录密码同车牌</label>
                    </Form>
                </Modal>

                <Modal width={'700px'} open={addModalVisible} onCancel={() => {
                    handleCloseAddModal();
                    setLoading(false)
                }} title={'停车记录'} maskClosable={false}
                       footer={<Button onClick={() => setAddModalVisible(false)}>取消</Button>}
                       destroyOnClose>
                    <Table rowKey={'record'} columns={recordColumns} dataSource={recordList} loading={loading}
                           pagination={{
                               current: pageNumRecord,
                               onChange: onPageChangeRecord,
                               onShowSizeChange: onPageSizeChangeRecord,
                               total: totalRecord,
                               showSizeChanger: true,
                               defaultPageSize: pageSizeRecord,
                               pageSizeOptions: ['10', '20', '50', '100'],
                           }}/>
                </Modal>


                <CarNumberInputDialog open={carNumberKeyboardOpen} onCancel={handleCloseCarNumberKeyboard}
                                      licensePlateNumber={keyBoardType === 1 ? newCarLicense : updatedCarLicense}
                                      confirmCarNumber={keyBoardType === 1 ? confirmCarLicense : confirmUpdatedCarLicense}/>


            </div>
        );
    }
;

export default Car;