import {useEffect, useState} from "react";
import {Button, Input, Modal, Table} from "antd";
import {message} from "antd/es";
import s from "./index.module.css"
import {getUserList} from "../../api";
import {showFail} from "../functions";

const PickUser = ({
                      open,
                      onCancel,
                      confirmUser,
                      initUser,
                      type
                  }: {
    open: boolean,
    onCancel: () => void,
    confirmUser: any,
    initUser: any,
    type: number
}) => {
    const [userLoading, setUserLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState(initUser?.userId ? [initUser?.userId] : []);
    const [selectedRows, setSelectedRows] = useState(initUser?.userId ? [initUser] : []);
    const onSelectChange = (newSelectedRowKeys: any, selectedRows: any) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedRows(selectedRows)
        // setSelectedRows(selectedRows.map((sr: any) => {
        //     return {
        //         userId: sr?.sysUser?.userId,
        //         name: sr?.sysUser?.username,
        //         mobile: sr?.sysUser?.mobile,
        //     }
        // }))
    };

    const rowSelection: any = {
        selectedRowKeys,
        onChange: onSelectChange,
        preserveSelectedRowKeys: true,
        type: 'radio'
    };
    const [searchCondition, setSearchCondition] = useState<any>({
        username: "",
        mobile: "",
        userId: ""
    })
    const params: any = {}

    const handleUsernameChange = (e: any) => {
        setSearchCondition({
            ...searchCondition,
            username: e?.target?.value
        })
    }

    const handleMobileChange = (e: any) => {
        setSearchCondition({
            ...searchCondition,
            mobile: e?.target?.value
        })
    }


    const handleSearchUser = (searchCondition: any) => {
        if (searchCondition.username) {
            params.username = searchCondition?.username;
        }
        if (searchCondition.mobile) {
            params.mobile = searchCondition?.mobile;
        }
        if (searchCondition.userId) {
            params.userId = searchCondition?.userId;
        }
        setUserLoading(true);
        getUserList(params).then((res: any) => {
            setUserLoading(false)
            if (res?.code === 200) {
                setUsers(res?.data)
            } else {
                showFail(res?.msg)
            }
        }).catch((err: any) => {
            console.log(err)
        })
    }

    useEffect(() => {
        if (open) {
            if (initUser?.userId) {
                handleSearchUser({userId: initUser?.userId})
            }
        }
    }, [open])

    users?.map((user: any) => (user.key = user.userId));
    const hasSelected = selectedRowKeys.length > 0;
    const columns = [
        {
            title: '姓名',
            dataIndex: 'username',
            key: 'username'
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: '部门',
            dataIndex: 'dept',
            key: 'dept',
            render: (text: string, record: any) => (<div>
                {record?.sysUser?.dept}
            </div>)
        },
    ]

    return (
        <Modal width="40vw" maskClosable={false} title="选择任务人员" open={open} onOk={() => {
            if (selectedRows.length !== 0) {
                confirmUser(selectedRows[0])
                onCancel();
            } else {
                confirmUser("")
                message.warning("未选择人员!", 0.5).then(() => {
                    onCancel();
                })
            }
        }} onCancel={() => {
            setUserLoading(false);
            onCancel();
        }} okText="确认" cancelText="取消">
            <div className={s.userModalSearchBar}>
                <Input style={{width: "250px", marginRight: "30px"}} placeholder="用户名" allowClear
                       onChange={handleUsernameChange}/>
                <Input style={{width: "250px", marginRight: "30px"}} placeholder="手机号" allowClear
                       onChange={handleMobileChange}/>
                <Button type="primary" onClick={() => {
                    handleSearchUser(searchCondition);
                }}>查询</Button>
            </div>

            <div style={{height: "2vh"}}/>
            {hasSelected && (<div style={{display: 'flex', flexDirection: 'row', fontSize: '15px'}}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row'
                    }}>已选中 {selectedRows[0]?.username}
                </div>
                <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                    <Button style={{fontSize: '15px'}} type='link' onClick={() => {
                        setSelectedRows([]);
                        setSelectedRowKeys([])
                    }}>
                        取消选中
                    </Button>
                </div>
            </div>)}
            <Table rowSelection={rowSelection} columns={columns}
                   dataSource={users} loading={userLoading} pagination={{pageSize: 5}}/>
        </Modal>
    );
};

export default PickUser;

