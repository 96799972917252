import {
    AutoComplete,
    Button,
    Carousel,
    Cascader,
    Form,
    Input,
    Modal,
    Popconfirm,
    Select,
    Table,
    Tag,
    Tooltip,
    Image
} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import styles from "./index.module.css"
import {iHydrant, iPicture} from "../../../interface";
import {
    deleteParkApply,
    deleteHydrant, getAllCompanyNotPage,
    getParkApplyPage, addParkApply, checkpply,
} from "../../../api";
import {showFail, showNeedCompleteInfo, showSuccess} from "../../../components/functions";
import LocationModal from "../../../components/locationModal";
import AddParkApplyModal from "../../../components/addParkApplyModal";
import EditParkApplyModal from "../../../components/editParkApplyModal";
// @ts-ignore
import {debounce} from "lodash";


const ParkApply = () => {
        const streetOptions = [
            {
                "value": "石淙镇",
                "label": "石淙镇"
            },
            {
                "value": "和孚镇",
                "label": "和孚镇"
            },
            {
                "value": "旧馆街道",
                "label": "旧馆街道"
            },
            {
                "value": "练市镇",
                "label": "练市镇"
            },
            {
                "value": "菱湖镇",
                "label": "菱湖镇"
            },
            {
                "value": "南浔镇",
                "label": "南浔镇"
            },
            {
                "value": "千金镇",
                "label": "千金镇"
            },
            {
                "value": "双林镇",
                "label": "双林镇"
            },
            {
                "value": "善琏镇",
                "label": "善琏镇"
            }
        ];
        const isSuperManager = JSON.parse(window.localStorage?.getItem("isSuperManager") as string);
        const isDispatcher = JSON.parse(window.localStorage?.getItem("isDispatcher") as string);
        const companyId = window.localStorage?.getItem("companyId");

        const [pageNum, setPageNum] = useState<number>(1);
        const [pageSize, setPageSize] = useState<number>(10);
        const [total, setTotal] = useState<number>(0);
        const [loading, setLoading] = useState<boolean>(false)
        const [result, setResult] = useState<any>()
        const [boxList, setBoxList] = useState<any[]>([])
        const onPageChange = (_page: number) => {
            setPageNum(_page);
        };

        const onPageSizeChange = (_page: number, _pageSize: number) => {
            setPageSize(_pageSize);
        };

        const [stateList, setStateList] = useState<any[]>([{label:'待审核',value:'发起'},{label:'驳回',value:'驳回'},{label:'通过',value:'通过'}])
        // useEffect(() => {
        //     if (isSuperManager) {
        //         getAllCompanyNotPage().then((res: any) => {
        //             if (res?.code === 200) {
        //                 setCleanCompanyList(res?.data?.map((d: any) => {
        //                     return {
        //                         value: d?.companyId,
        //                         label: d?.companyName
        //                     }
        //                 }))
        //             }
        //         })
        //     }
        // }, [])

        let params: any = {}
        const handleGetApplyList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
            setLoading(true);
            params.pageSize = pageSize;
            params.pageNum = pageNum;
            params = {...params, ...searchCondition}
            getParkApplyPage(params).then((res: any) => {
                setLoading(false);
                if (res?.code === 200) {
                    setTotal(Number(res?.data?.total));
                    setBoxList(res?.data?.records);
                } else {
                    showFail(res?.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        }

        useEffect(() => {
            handleGetApplyList(pageNum, pageSize, newSearchCondition);
        }, [pageSize, pageNum])

        const [searchCondition, setSearchCondition] = useState({
            applyState: "发起",
            applyName: "",
            idNum: "",
        });
        const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);

        const handleApplyNameChange = (e: any) => {
            setSearchCondition({...searchCondition, applyName: e?.target?.value})
        }
        const handleStateChange = (e: any) => {
            setSearchCondition({...searchCondition, applyState: e})
        }

        const handleIdNumChange = (e: any) => {
            setSearchCondition({...searchCondition, idNum: e?.target?.value})
        }

        // const handleBoxNameChange = (e: any) => {
        //     setSearchCondition({...searchCondition, boxName: e?.target?.value})
        // }

        //位置对话框
        const [hydrantLng, setHydrantLng] = useState<string | number>();
        const [hydrantLat, setHydrantLat] = useState();
        const [locationModalOpen, setLocationModalOpen] = useState(false);
        const handleCloseLocationModal = () => {
            setLocationModalOpen(false);
        }
    const [detailLook, setDetailLook] = useState(false);
    const [item, setItem] = useState<any>();
    const handleCloseDetailLook = () => {
        console.log('666')
        setDetailLook(false);
    }

        const columns: any = [
            {
                title: '申请人姓名',
                dataIndex: 'applyName',
                key: 'applyName',
                fixed: 'left',
                width: 180,
            },
            {
                title: '身份证号码',
                dataIndex: 'idNum',
                key: 'idNum',
            },
            {
                title: '车牌号',
                dataIndex: 'carLicense',
                key: 'carLicense',
            },
            {
                title: '申请时间',
                dataIndex: 'createTime',
                key: 'createTime'
            },
            {
                title: '申请状态',
                dataIndex: 'applyState',
                key: 'applyState',
            },
            {
                title: '未通过原因',
                dataIndex: 'notPassReason',
                key: 'notPassReason',
            },
            {
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                fixed: 'right',
                render: (_: any, record: any) => (
                    <div>
                    {record.applyState === '发起' ?
                            <div>
                                <Button onClick={() => {
                                    setItem(record)
                                    setDetailLook(true)
                                }} type={"primary"}>审核</Button>
                            </div> : <div>
                                <Button onClick={() => {
                                    setItem(record)
                                    setDetailLook(true)
                                }}>查看</Button>
                            </div>}
                    </div>
                )
            },
        ];

        //新增
        const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
        const handleCloseAddModal = () => {
            setAddModalVisible(false);
        }
        const handleApply = () => {
            form.validateFields().then((value) => {
                let params = {}
                if(value.applyState==='通过'){
                    params={
                        applyState:value.applyState,
                        applyId:item.applyId,
                    }
                }else {
                    params={
                        applyState:value.applyState,
                        applyId:item.applyId,
                        notPassReason:value.notPassReason
                    }
                }
                checkpply(params).then((res: any) => {
                    if (res?.code === 200) {
                        setItem(null)
                        setResult(null)
                        setDetailLook(false)
                        handleGetApplyList(pageNum, pageSize, newSearchCondition);
                    }else {
                        showFail(res?.msg)
                    }
                })
            }).catch((err) => {
                console.log(err);
                showNeedCompleteInfo();
            })
        }
        //修改
        const [hydrantRecord, setHydrantRecord] = useState<iHydrant>();
        const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
        const handleCloseEditModal = () => {
            setEditModalVisible(false);
        }
    const [form] = Form.useForm();


    const [images, setImages] = useState<any[]>([]);
    const [loadingImages, setLoadingImages] = useState(true);

    useEffect(() => {
        if (detailLook) {
            // 当Modal打开时，清空图片数据并设置为加载中
            setImages([]);
            setLoadingImages(true);
            // 模拟图片加载
            setTimeout(() => {
                if (item?.parkApplyPictures) {
                    setImages(item.parkApplyPictures);
                }
                setLoadingImages(false);
            }, 1000); // 假设1秒后图片加载完成
        }
    }, [detailLook, item]);
        return (
            <div className={styles.main}>
                <div className={styles.mainHead}>
                    <div className={styles.searchContent}>

                            <Select style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                                    options={stateList} placeholder="工单状态"
                                    defaultValue={"发起"}
                                    onChange={handleStateChange}/>

                        <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}} allowClear
                               placeholder="姓名" onChange={handleApplyNameChange}/>

                        <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                               onChange={handleIdNumChange} placeholder={'身份证'} allowClear/>

                        {/*<Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}*/}
                        {/*       onChange={handleBoxNameChange} placeholder={'箱体名称'} allowClear*/}
                        {/*/>*/}


                    </div>
                    <div className={styles.searchButton}>

                        <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                            handleGetApplyList(1, 10, searchCondition);
                            setPageNum(1);
                            setPageSize(10);
                            setNewSearchCondition(searchCondition)
                        }}>查询
                        </Button>

                        {/*<Button type='primary' style={{marginRight: '20px', marginTop: '10px'}} onClick={() => {*/}
                        {/*    setAddModalVisible(true);*/}
                        {/*}}>新增*/}
                        {/*</Button>*/}

                    </div>

                </div>

                <Table rowKey={'boxCode'} columns={columns} dataSource={boxList} loading={loading}
                       pagination={{
                           current: pageNum,
                           onChange: onPageChange,
                           onShowSizeChange: onPageSizeChange,
                           total: total,
                           showSizeChanger: true,
                           defaultPageSize: pageSize,
                           pageSizeOptions: ['10', '20', '50', '100'],
                       }}/>

                <LocationModal open={locationModalOpen} onCancel={handleCloseLocationModal}
                               lat={hydrantLat} lng={hydrantLng}/>

                <AddParkApplyModal open={addModalVisible} onClose={handleCloseAddModal} streetOptions={streetOptions}
                                    handleGetBox={async () => {
                                        await handleGetApplyList(pageNum, pageSize, newSearchCondition)
                                    }}/>

                <EditParkApplyModal open={editModalVisible} onClose={handleCloseEditModal} streetOptions={streetOptions}
                                     handleGetBox={async () => {
                                         await handleGetApplyList(pageNum, pageSize, newSearchCondition)
                                     }} boxRecord={hydrantRecord}/>
                <Modal
                    open={detailLook}
                    onCancel={() => {
                        setDetailLook(false);
                        form.resetFields();
                        setItem(null);
                    }}
                    footer={
                        <div>
                            {item?.applyState === '发起' ? (
                                <Button type="primary" onClick={debounce(handleApply, 1000)}>提交</Button>
                            ) : (
                                ''
                            )}
                            <Button onClick={() => {
                                setDetailLook(false);
                                setResult(null);
                                setItem(null);
                                form.resetFields();
                            }}>关闭</Button>
                        </div>
                    }
                >
                    <Form form={form}>
                        <Form.Item name='applyName' label='申请人姓名'>
                            <div>{item?.applyName}</div>
                        </Form.Item>
                        <Form.Item name='idNum' label='申请人身份证'>
                            <div>{item?.idNum}</div>
                        </Form.Item>
                        <Form.Item name='carLicense' label='申请人车牌号'>
                            <div>{item?.carLicense}</div>
                        </Form.Item>
                        <Form.Item name='createTime' label='申请时间'>
                            <div>{item?.createTime}</div>
                        </Form.Item>
                        <Form.Item name='applyState' label='申请状态'>
                            <div>{item?.applyState}</div>
                        </Form.Item>
                        {item?.notPassReason && (
                            <Form.Item name='notPassReason' label='驳回原因'>
                                <div>{item?.notPassReason}</div>
                            </Form.Item>
                        )}
                        {loadingImages ? (
                            <div>Loading images...</div>
                        ) : (
                            images.map((picture, index) => (
                                <Form.Item
                                    key={index}
                                    label={
                                        picture.pictureType === '1' ? '车辆照片' :
                                            picture.pictureType === '2' ? '身份证正面照片' :
                                                picture.pictureType === '3' ? '身份证背面照片' :
                                                    '行驶证'
                                    }
                                >
                                    <Image src={picture.pictureUrl} style={{ width: '200px', height: '100px' }} />
                                </Form.Item>
                            ))
                        )}
                        {item?.applyState === '发起' && (
                            <Form.Item
                                name='applyState'
                                label='审核结果'
                                rules={[{ required: true, message: '审核结果不能为空' }]}
                            >
                                <Select placeholder='请输入' onChange={(value) => setResult(value)}>
                                    <Select.Option value="通过">通过</Select.Option>
                                    <Select.Option value="驳回">驳回</Select.Option>
                                </Select>
                            </Form.Item>
                        )}
                        {result === '驳回' && (
                            <Form.Item
                                name='notPassReason'
                                label='驳回理由'
                                rules={[{ required: true, message: '驳回理由不能为空' }]}
                            >
                                <Input.TextArea rows={4}></Input.TextArea>
                            </Form.Item>
                        )}
                    </Form>
                </Modal>
            </div>
        );
    }
;

export default ParkApply;