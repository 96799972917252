import React, {useEffect, useState} from 'react';
import {Button, Divider, Input, Popconfirm, Select, Space, Table, Tag, TreeSelect} from "antd";
import {deleteDangerTask, getDeptTree, getHiddenDangerDataPage} from "../../../api";
import {showFail, showSuccess} from "../../../components/functions";
import styles from "../exercise/index.module.css";
import AddExerciseTaskDrawer from "../../../components/addExerciseTaskDrawer";
import {HiddenDangerDetailModal} from "../../../components/HiddenDangerDetailModal";

interface depttree {
    value?: string,
    title?: string,
    children?: any[],
}

function tree(list: depttree[], res: any[]) {
    for (let i = 0; i < res.length; i++) {
        if (res[i].children) {
            // @ts-ignore
            list.push({value: res[i].deptId, title: res[i].name, children: []})
        } else {
            // @ts-ignore
            list.push({value: res[i].deptId, title: res[i].name})
        }
        // @ts-ignore
        if (res[i].children) {
            // @ts-ignore
            tree(list[i].children, res[i].children)
        }
    }
    return list
}

const HiddenDanger = () => {
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageNum, setPageNum] = useState<number>(1);
    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [dangerId, setDangerId] = useState<string | number>("");
    const [hiddenDangerData, setHiddenDangerData] = useState<any[]>([]);

    const [depts, setDepts] = useState<any[]>([]);
    const handleGetDepts=()=>{
        getDeptTree().then((res: any) => {
            if (res.code === 200) {
                let a: depttree[] = []
                let b = []
                b.push(res.data)
                let list = tree(a, b)
                setDepts(list);
            } else {
                showFail(res?.msg)
            }
        }).catch((err: any) => {
            console.error(err)
        })
    }
    useEffect(()=>{
        handleGetDepts()
    },[])
    const onPageChange = (_page: number) => {
        setPageNum(_page);
    };

    const onPageSizeChange = (_page: number, _pageSize: number) => {
        setPageSize(_pageSize);
    };

    const [searchCondition, setSearchCondition] = useState({});
    const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);

    let params: any = {}
    const handleGetHiddenDanger = async (pageNum: number, pageSize: number, searchCondition?: any) => {
        setLoading(true);
        params.pageSize = pageSize;
        params.pageNum = pageNum;
        if (searchCondition?.taskName) {
            params.taskName = searchCondition.taskName;
        }
        if (searchCondition?.dangerState!==undefined) {
            params.dangerState = searchCondition.dangerState;
        }
        if (searchCondition?.deptId) {
            params.deptId = searchCondition.deptId;
        }
        if (searchCondition?.communityId) {
            params.communityId = searchCondition.communityId;
        }
        getHiddenDangerDataPage(params).then((res: any) => {
            if (res.code === 200) {
                setLoading(false);
                setTotal(Number(res?.data?.total));
                setHiddenDangerData(res.data?.records)
            } else {
                showFail(res?.msg)
            }
        }).catch((err: any) => {
            console.error(err)
        })
    }

    useEffect(() => {
        handleGetHiddenDanger(pageNum, pageSize, newSearchCondition)
    }, [pageSize, pageNum])

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const handleDetailModalClose = () => {
        setModalOpen(false);
    }

    const columns = [
        {
            title: '名称',
            dataIndex: 'taskName',
            key: 'taskName',
        },
        {
            title: '状态',
            dataIndex: 'dangerState',
            key: 'dangerState',
            render: (text: any, record: any) => {
                return (
                    <div>
                        {record.dangerState === 0 ? <Tag  color="processing">待检查</Tag>  :
                            record.dangerState === 1 ?<Tag  color="default">待确认</Tag> :
                                record.dangerState === 2 ?<Tag  color="error">待办结</Tag> :
                                    <Tag color="success">已办结</Tag>}
                    </div>)
            }
        },
        {
            title: '地点',
            dataIndex: 'communityName',
            key: 'communityName',
        },
        {
            title: '开始时间',
            dataIndex: 'taskStartDate',
            key: 'taskStartDate',
        },
        {
            title: '结束时间',
            dataIndex: 'taskEndDate',
            key: 'taskEndDate',
        },
        {
            title: '发起人',
            dataIndex: 'startUser',
            key: 'startUser',
            render:(startUser:any)=>(<>
                {startUser?.username} {startUser?.mobile}
            </>)
        },
        {
            title: '详情',
            dataIndex: 'dangerId',
            key: 'dangerId',
            render: ((dangerId: any, record: any) => (
                <>
                    <Button type={'primary'} onClick={() => {
                        setModalOpen(true);
                        setDangerId(dangerId)
                    }}>查看详情</Button>
                    <Popconfirm title={'是否确认删除该隐患排查任务?'} onConfirm={()=>{
                        deleteDangerTask({dangerId}).then((res:any)=>{
                            if(res.code===200){
                                handleGetHiddenDanger(pageNum,pageSize,newSearchCondition).then(()=>{
                                    showSuccess(res?.msg)
                                })
                            }else{
                                showFail(res?.msg)
                            }
                        })
                    }}>
                        <Button style={{marginLeft:'20px'}} danger>
                            删除
                        </Button>
                    </Popconfirm>

                </>

            ))
        },
    ]


    const handleStateChange = (v: any) => {
        setSearchCondition({...searchCondition, dangerState:v})
    }

    const handleNameChange = (e: any) => {
        setSearchCondition({...searchCondition, taskName: e.target.value})
    }

    const handleDeptIdChange = (e:any) => {
        setSearchCondition({...searchCondition, deptId:e})
    }

    const handleCommunityIdChange = (e:any) => {
        setSearchCondition({...searchCondition, communityId:e})
    }

    const [addDrawerVisible,setAddDrawerVisible]=useState<boolean>(false);
    const handleCloseAddDrawer=()=>{
        setAddDrawerVisible(false);
    }


    const [communityList,setCommunityList]=useState<any[]>([])

    return (
        <div>
            <div className={styles.mainHead}>
                <div className={styles.searchContent}>
                    <Select style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                            options={[
                                {value: 0, label: "待检查"},
                                {value: 1, label: "待确认"},
                                {value: 2, label: "待办结"},
                                {value: 3, label: "已办结"},
                            ]}
                            onChange={handleStateChange} placeholder={'状态'} allowClear/>

                    <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                           onChange={handleNameChange} placeholder={'任务名称'} allowClear/>



                    <TreeSelect
                        placeholder={"请选择发起机构"}
                        showSearch
                        style={{width: '250px', marginRight: '30px', marginTop: '10px'}}
                        filterTreeNode={(inputValue, treeNode) =>
                            (treeNode as any).title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
                        }
                        // value={deptTree}
                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                        allowClear
                        treeDefaultExpandAll
                        onChange={handleDeptIdChange}
                        treeData={depts || []}
                    />

                    {/*<Cascader onChange={handleAreaChange} style={{width: 250, marginRight: '30px', marginTop: '10px'}}*/}
                    {/*          options={areaTreeTran ? [areaTreeTran] : []}*/}
                    {/*          changeOnSelect placeholder="县区/街道/乡镇" allowClear/>*/}


                </div>
                <div className={styles.searchButton}>

                    <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                        handleGetHiddenDanger(1, 10, searchCondition);
                        setPageNum(1);
                        setPageSize(10);
                        setNewSearchCondition(searchCondition)
                    }}>查询
                    </Button>

                    <Button type='primary' style={{marginRight: '20px', marginTop: '10px'}} onClick={() => {
                        setAddDrawerVisible(true)
                    }}>新增
                    </Button>

                </div>

            </div>


            <Table rowKey={'hiddenDangerId'} columns={columns} dataSource={hiddenDangerData}
                   loading={loading}
                   pagination={{
                       current: pageNum,
                       onChange: onPageChange,
                       onShowSizeChange: onPageSizeChange,
                       total: total,
                       showSizeChanger: true,
                       defaultPageSize: pageSize,
                       pageSizeOptions: ['10', '20', '50', '100'],
                   }}/>

            <HiddenDangerDetailModal open={modalOpen}
                                     onCancel={handleDetailModalClose}
                                     dangerId={dangerId as string}
            />

            <AddExerciseTaskDrawer open={addDrawerVisible} onCancel={handleCloseAddDrawer} type={1} handleGetTask={async () => {
                await handleGetHiddenDanger(pageNum, pageSize, newSearchCondition)
            }}/>
        </div>
    );
};

export default HiddenDanger;