import React, {useEffect, useState} from 'react';
import {iRegularInspectPlan} from "../../../interface";
import {
    deleteRegularInspectPlan,
    getRegularInspectionPlanPage,
    releaseRegularPlan
} from "../../../api";
import {showFail, showSuccess} from "../../../components/functions";
import {Button, Input, Modal, Popconfirm, Table, Tag} from "antd";
import styles from "../hydrant/index.module.css";
import AddRegularInspectPlanModal from "../../../components/addRegularInspectPlan";
import RegularPlanRecordDrawer from "../../../components/regularPlanRecordDrawer";
import UpdateRegularInspectPlanModal from "../../../components/updateRegularInspectPlanModal";
// @ts-ignore
import {debounce} from "lodash"

const InspectTaskRegular = () => {
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false)
    const [regularInspectPlanList, setRegularInspectPlanList] = useState<iRegularInspectPlan[]>([])
    const onPageChange = (_page: number) => {
        setPageNum(_page);
    };

    const onPageSizeChange = (_page: number, _pageSize: number) => {
        setPageSize(_pageSize);
    };

    let params: any = {}
    const handleGetRegularInspectTaskPLanList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
        setLoading(true);
        params.pageSize = pageSize;
        params.pageNum = pageNum;
        if (searchCondition?.name) {
            params.name = searchCondition?.name
        }
        getRegularInspectionPlanPage(params).then((res: any) => {
            setLoading(false);
            if (res?.code === 200) {
                setTotal(Number(res?.data?.total));
                setRegularInspectPlanList(res?.data?.records);
            } else {
                showFail(res?.msg)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        handleGetRegularInspectTaskPLanList(pageNum, pageSize, newSearchCondition);
    }, [pageSize, pageNum])

    const [searchCondition, setSearchCondition] = useState({
        name: "",
        state: "",
    });
    const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);


    // const handleIdChange = (e: any) => {
    //     setSearchCondition({...searchCondition, taskId: e?.target?.value})
    // }

    const handleNameChange = (e: any) => {
        setSearchCondition({...searchCondition, name: e?.target?.value})
    }

    const hnandleTaskStateChange = (v: any) => {
        setSearchCondition({...searchCondition, state: v})
    }


    const columns: any = [
        {
            title: 'id',
            dataIndex: 'regularInspectionId',
            key: 'regularInspectionId',
            fixed: 'left'
        },
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left'
        },
        {
            title: '负责人',
            dataIndex: 'sysUser',
            key: 'sysUser',
            render: (sysUser: any) => (<div>
                {sysUser?.username} {sysUser?.mobile}
            </div>)
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            render: (state: any) => (
                <div>
                    {state === 0 ? <Tag color={'blue'}>未发布</Tag> :
                        <Tag color={'green'}>已发布</Tag>}
                </div>
            )
        },
        {
            title: '首次任务开始时间',
            dataIndex: 'regularStartTime',
            key: 'regularStartTime'
        },
        {
            title: '任务持续时间(天)',
            dataIndex: 'executionDay',
            key: 'executionDay',
        },
        {
            title: '任务运行周期(天)',
            dataIndex: 'cycle',
            key: 'cycle',
        },
        {
            title: '下次任务开始时间',
            dataIndex: 'nextReleaseTime',
            key: 'nextReleaseTime',
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            fixed: 'right',
            width: 380,
            render: (_: any, record: any) => (
                <div style={{display: 'flex', flexDirection: 'row'}}>

                    <div>
                        <Button disabled={record?.state!==0} type={'link'} onClick={() => {
                            Modal.confirm({
                                title: '是否确认发布该定期计划?',
                                content: "计划到首次任务开始时间会自动发布, 是否提前发布该计划?",
                                onOk: debounce(() => {
                                    releaseRegularPlan({regularInspectionId: record?.regularInspectionId}).then((res: any) => {
                                        if (res?.code === 200) {
                                            handleGetRegularInspectTaskPLanList(pageNum, pageSize, newSearchCondition).then(() => {
                                                showSuccess(res?.msg);
                                            })
                                        } else {
                                            showFail(res?.msg)
                                        }
                                    }).catch((err) => {
                                        console.log(err)
                                    })
                                }, 1000)
                            })
                        }}>手动发布任务</Button>

                    </div>

                    <div>
                        <Button type={'link'} onClick={() => {
                            setRecordDrawerVisible(true);
                            setRegularPlan(record);
                        }}>定期巡检清单</Button>
                    </div>

                    <div>
                        <Button type={'link'} onClick={() => {
                            setRegularPlan(record)
                            setUpdateModalVisible(true);
                        }}>修改</Button>
                    </div>

                    <div>
                        <Popconfirm title={"是否确认删除该定期计划"} placement={'topLeft'} onConfirm={
                            debounce(() => {
                                deleteRegularInspectPlan({regularInspectionId: record?.regularInspectionId}).then((res: any) => {
                                    if (res?.code === 200) {
                                        handleGetRegularInspectTaskPLanList(pageNum, pageSize, newSearchCondition).then(() => {
                                            showSuccess(res?.msg);
                                        })
                                    } else {
                                        showFail(res?.msg)
                                    }
                                }).catch((err) => {
                                    console.log(err)
                                })
                            }, 1000)

                        }>
                            <Button danger type={'link'} color={'danger'}>删除</Button>
                        </Popconfirm>
                    </div>


                </div>
            )
        },
    ];

    //新增
    const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
    const handleCloseAddModal = () => {
        setAddModalVisible(false);
    }

    //新增
    const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);
    const handleCloseUpdateModal = () => {
        setUpdateModalVisible(false);
    }

    //巡检记录
    const [regularPlan, setRegularPlan] = useState<iRegularInspectPlan>();
    const [recordDrawerVisible, setRecordDrawerVisible] = useState<boolean>(false);
    const handleCloseRecordDrawer = () => {
        setRecordDrawerVisible(false);
    }

    return (
        <div className={styles.main}>
            <div className={styles.mainHead}>
                <div className={styles.searchContent}>
                    {/*<Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}*/}
                    {/*       onChange={handleIdChange} placeholder={'任务id'} allowClear/>*/}

                    <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                           onChange={handleNameChange} placeholder={'计划名称'} allowClear/>
                </div>

                <div className={styles.searchButton}>
                    <Button style={{marginRight: '20px'}} type='primary' onClick={() => {
                        handleGetRegularInspectTaskPLanList(1, 10, searchCondition);
                        setPageNum(1);
                        setPageSize(10);
                        setNewSearchCondition(searchCondition)
                    }}>查询
                    </Button>

                    <Button type='primary' onClick={() => {
                        setAddModalVisible(true);
                    }}>新增
                    </Button>
                </div>

            </div>
            <Table rowKey={'regularInspectionId'} scroll={{x: 1500}} columns={columns}
                   dataSource={regularInspectPlanList}
                   loading={loading}
                   pagination={{
                       current: pageNum,
                       onChange: onPageChange,
                       onShowSizeChange: onPageSizeChange,
                       total: total,
                       showSizeChanger: true,
                       defaultPageSize: pageSize,
                       pageSizeOptions: ['10', '20', '50', '100'],
                   }}/>


            <AddRegularInspectPlanModal open={addModalVisible} onClose={handleCloseAddModal}
                                        handleGetRegularInspectPlan={async () => {
                                            await handleGetRegularInspectTaskPLanList(pageNum, pageSize, newSearchCondition)
                                        }}/>

            <UpdateRegularInspectPlanModal open={updateModalVisible} onClose={handleCloseUpdateModal}
                                           handleGetRegularInspectPlan={async () => {
                                               await handleGetRegularInspectTaskPLanList(pageNum, pageSize, newSearchCondition)
                                           }} regularPlan={regularPlan as iRegularInspectPlan}/>

            <RegularPlanRecordDrawer open={recordDrawerVisible} onCancel={handleCloseRecordDrawer}
                                     plan={regularPlan as iRegularInspectPlan}/>
        </div>
    );
};

export default InspectTaskRegular;