import {Button, Image, Modal} from "antd";
import styles from "../../pages/manage/exercise/index.module.css";
import React, {useEffect, useState} from "react";
import {getExerciseData} from "../../api";
import {showFail, showNetError} from "../functions";
import locationIcon from "../../assets/icons/location.svg"
import LocationModal from "../locationModal";

export const ExerciseDetailModal = ({
                                        open,
                                        onCancel,
                                        exerciseId
                                    }: { open: boolean, onCancel: () => void, exerciseId: string }) => {
    const [exerciseData, setExerciseData] = useState<any>()
    const [images, setImages] = useState<any[]>([]);
    const [imageVisible, setImageVisible] = useState<boolean>(false);
    const handleGetExerciseDetail = () => {
        if (exerciseId) {
            getExerciseData({exerciseId}).then((res: any) => {
                if (res?.code === 200) {
                    setExerciseData(res?.data[0])
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                showNetError();
                console.log(err)
            })
        } else {
            showFail("演练id为空!")
        }

    }

    useEffect(() => {
        if (open) {
            handleGetExerciseDetail()
        }
    }, [open])


    const [locationModalOpen, setLocationModalOpen] = useState(false);
    const handleCloseLocationModal = () => {
        setLocationModalOpen(false);
    }

    return (
        <><Modal open={open} onCancel={onCancel} title={'演练详情'} maskClosable={false}>
            {exerciseData?.communityName && (
                <div className={styles.row}>
                    <div className={styles.title}>演练地点</div>
                    <div
                        className={styles.content1}>
                        {exerciseData?.communityName}
                        <img style={{marginLeft: '10px', cursor: 'pointer'}} width={25} height={25} src={locationIcon}
                             alt="" onClick={() => {
                            setLocationModalOpen(true);
                        }}/>
                    </div>
                </div>
            )}

            {exerciseData?.organizeUser && (
                <div className={styles.row}>
                    <div className={styles.title}>上报人</div>
                    <div
                        className={styles.content}>
                        {exerciseData?.organizeUser?.username} {exerciseData?.organizeUser?.mobile}
                    </div>
                </div>
            )}

            {exerciseData?.exerciseStartTime && (
                <div className={styles.row}>
                    <div className={styles.title}>上报时间</div>
                    <div
                        className={styles.content}>
                        {exerciseData?.exerciseStartTime}
                    </div>
                </div>
            )}

            {exerciseData?.exerciseDetail && (
                <div className={styles.row}>
                    <div className={styles.title}>演练内容</div>
                    <div
                        className={styles.content}>
                        {exerciseData?.exerciseDetail}
                    </div>
                </div>
            )}

            {exerciseData?.exercisePictures && exerciseData?.exercisePictures?.length > 0 && (
                <div className={styles.row}>
                    <div className={styles.title}>演练照片</div>
                    <div className={styles.content}>
                        <Button type={'link'} onClick={() => {
                            let picList = exerciseData?.exercisePictures?.map((d: any) => {
                                return {
                                    src: d?.url,
                                    key: d?.exercisePictureId
                                }
                            });
                            setImages(picList);
                            setImageVisible(true);
                        }}>查看</Button>
                    </div>
                </div>
            )}
        </Modal>
            <Image.PreviewGroup
                items={images}
                preview={{
                    visible: imageVisible,
                    onVisibleChange: (value) => {
                        setImageVisible(value);
                    },
                }}
            >
                {images.map((image: any) => (
                    <Image
                        key={image.key}
                        width={200}
                        height={200}
                        style={{display: 'none'}}
                        src={image.src}
                        preview={{
                            scaleStep: 0.5,
                            src: image.src,
                        }}
                    />
                ))}
            </Image.PreviewGroup>

            <LocationModal open={locationModalOpen} onCancel={handleCloseLocationModal}
                           lat={exerciseData?.communityLat} lng={exerciseData?.communityLng}/>
        </>
    )
}