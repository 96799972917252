import {Form, Input, Modal} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
// @ts-ignore
import {debounce} from "lodash"
import AddNewFile from '../AddNewFile';

const useResetFormOnCloseModal = ({form, open}: { form: any, open: boolean }) => {
    const prevOpenRef: any = useRef();
    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;
    useEffect(() => {
        if (!open && prevOpen) {
            form.resetFields();
        }
    }, [form, prevOpen, open]);
};
const AddChemicalModal = ({
                              open,
                              onCancel,
                              handleGetChemical
                          }: { open: boolean, onCancel: () => void, handleGetChemical: any }) => {
    const [form] = Form.useForm();
    useResetFormOnCloseModal({
        form,
        open,
    });

    const [name, setName] = useState<string>("");


    useEffect(()=>{
        if(open){
            setName("")
        }
    },[open])

    return (
        <div>
            <Modal open={open} onCancel={onCancel} title={'新增危化品'} maskClosable={false}
                   okButtonProps={{style: {display: "none"}}} destroyOnClose>
                <Form form={form} name="form" labelAlign="right" labelCol={{
                    xs: {span: 24},
                    sm: {span: 6},
                }} wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 14},
                }}>
                    <Form.Item
                        name="name"
                        label="名称"
                        rules={[
                            {
                                required: true, message: "名称不能为空"
                            },
                        ]}
                    >
                        <Input placeholder="请输入名称" value={name} onChange={(e: any) => {
                            setName(e?.target?.value)
                        }}/>
                    </Form.Item>

                    {name && (
                        <Form.Item
                            name="file"
                            label="文档(PDF)"
                            rules={[
                                {
                                    required: true, message: "请上传危化品pdf文档"
                                },
                            ]}
                        >
                            <AddNewFile type={1} max={1} name={name} afterSuccess={async () => {
                                onCancel();
                                handleGetChemical()
                            }}/>
                        </Form.Item>
                    )}

                </Form>
            </Modal>
        </div>
    );
};

export default AddChemicalModal;