import {Button, Cascader, Form, Input, Modal, Select} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import AddNewPictures from '../AddNewPictures';
// @ts-ignore
import {debounce} from "lodash"
import {getAreaIdArray, showFail, showNeedCompleteInfo, showNetError, showSuccess, showWarning} from "../functions";
import PickLocationModal from '../pickLocationModal';
import {addCommunity, addHydrant, addSite, getCommunityId, updateSite} from "../../api";
import AddNewFile from "../AddNewFile";

const useResetFormOnCloseModal = ({form, open}: { form: any, open: boolean }) => {
    const prevOpenRef: any = useRef();
    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;
    useEffect(() => {
        if (!open && prevOpen) {
            form.resetFields();
        }
    }, [form, prevOpen, open]);
};

const EditSiteModal = ({
                           open,
                           onClose,
                           handleGetSite,
                           siteRecord
                       }: { open: boolean, onClose: () => void, handleGetSite: any, siteRecord: any }) => {
    const [form] = Form.useForm();
    useResetFormOnCloseModal({
        form,
        open,
    });
    const [record, setRecord] = useState<any>()

    useEffect(() => {
        if (open) {
            form.resetFields();
            setRecord(siteRecord);
            setLoc({lng: siteRecord?.siteLng, lat: siteRecord?.siteLat});
            setInitLoc({lng: siteRecord?.siteLng, lat: siteRecord?.siteLat});
        }
    }, [open])


    const [loc, setLoc] = useState<any>()
    const [initLoc, setInitLoc] = useState({lng: 120.09, lat: 30.88});


    //新增宿舍中的选择位置对话框
    const [pickLocModalOpen, setPickLocModalOpen] = useState(false);
    const handleCancelLocModal = () => {
        setPickLocModalOpen(false);
    }


    const confirmLoc = (e: any) => {
        setLoc(e);
        form.setFieldsValue({"location": e})
    }

    const confirmName = (e: any) => {
        // form.setFieldsValue({"siteName": e})
    }


    const getLocation = () => {
        console.log(navigator.geolocation)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((l) => {
                if (l?.coords) {
                    setInitLoc({lng: l?.coords?.longitude, lat: l?.coords?.latitude})
                } else {
                    showFail('获取定位失败')
                }
            });
        } else {
            alert("浏览器不支持地理定位。");
        }
    }


    const [loading, setLoading] = useState<boolean>(false);
    const handleUpdateNewSite = () => {
        form.validateFields().then((value) => {
            let newSite = {
                ...value,
                siteId:record?.siteId,
                siteLng: value?.location?.lng,
                siteLat: value?.location?.lat,
            }
            setLoading(true);
            updateSite(newSite).then((res: any) => {
                setLoading(false);
                if (res?.code === 200) {
                    handleGetSite().then(() => {
                        showSuccess(res?.msg);
                        onClose();
                        setLoc(undefined);
                    })
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                console.log(err)
            })

        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }

    return (
        <div>
            <Modal open={open} onCancel={() => {
                onClose();
                setLoc(undefined);
                setLoading(false)
            }} width={'800px'} title={'处置单位修改'} maskClosable={false}
                   okText={'确认提交'}
                   onOk={debounce(handleUpdateNewSite, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                <Form form={form} name="form" labelAlign="right" labelCol={{
                    xs: {span: 24},
                    sm: {span: 6},
                }} wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 14},
                }}>
                    <Form.Item
                        name="location"
                        label="定位"
                        initialValue={loc}
                    >
                        {loc ? (<div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{color: "#235c7a"}}>
                                    <div>经度:{loc?.lng}</div>
                                    <div>纬度:{loc?.lat}</div>
                                </div>
                                <div style={{width: "20px"}}/>
                                <div style={{display: "grid", placeItems: "center"}}>
                                    <Button type="primary" onClick={() => {
                                        setPickLocModalOpen(true)
                                    }}>重新选择
                                    </Button>
                                </div>

                            </div>
                        ) : (
                            <Button type="primary" onClick={() => {
                                getLocation();
                                setPickLocModalOpen(true);
                            }}>选择定位
                            </Button>
                        )}

                    </Form.Item>


                    {/*{loc && (*/}
                        <Form.Item
                            name="siteName"
                            label="名称"
                            initialValue={record?.siteName}
                            rules={[
                                {
                                    required: true, message: "请输入名称"
                                },
                            ]}
                        >
                            <Input placeholder="请输入名称"/>
                        </Form.Item>
                    {/*)}*/}
                    <Form.Item
                        name="managerMobile"
                        label="管理员手机号"
                        initialValue={record?.managerMobile}
                        rules={[
                            {
                                required: true, message: "请输入管理员手机号"
                            },
                        ]}
                    >
                        <Input placeholder="请输入管理员手机号"/>
                    </Form.Item>
                    <Form.Item
                        name="managerPassword"
                        label="管理员密码"
                        initialValue={record?.managerPassword}
                        rules={[
                            {
                                required: true, message: "请输入管理员密码"
                            },
                        ]}
                    >
                        <Input placeholder="请输入管理员密码"/>
                    </Form.Item>
                </Form>

                <PickLocationModal loc={initLoc} open={pickLocModalOpen} onCancel={handleCancelLocModal}
                                   confirmLoc={(e: any) => {
                                       confirmLoc(e)
                                   }} confirmAddress={() => {
                }} confirmRoad={() => {
                }} confirmName={confirmName} repick={true}/>

            </Modal>


        </div>
    );
};

export default EditSiteModal;