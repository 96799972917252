import {Button, Cascader, Divider, Drawer, Input, Modal, Popconfirm, Select, Space, Table, Tooltip} from 'antd';
import React, {useEffect, useState} from 'react';
import {iHydrant, iInspectRecord, iRegularInspectPlan} from "../../interface";
import {
    addRegularHydrantList,
    deleteRegularHydrantList,
    getAreaTree, getHydrantList,
    getHydrantPage,
    getRegularInspectRecord, getRegularRecord
} from "../../api";
import {diffArray, getAreaPath, showFail, showSuccess, showWarning, transform} from "../functions";
import LocationModal from '../locationModal';
import locationIcon from "../../assets/icons/location.svg";
import noneIcon from "../../assets/icons/none.svg";
import styles from "./index.module.css";
import HydrantDetailModal from "../hydrantDetailModal";
// @ts-ignore
import {debounce} from 'lodash';

const RegularPlanRecordDrawer = ({
                                     open,
                                     onCancel,
                                     plan
                                 }: { open: boolean, onCancel: () => void, plan: iRegularInspectPlan }) => {
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false)
    const [recordList, setRecordList] = useState<iInspectRecord[] | []>([]);
    const onPageChange = (_page: number) => {
        setPageNum(_page);
    };

    const onPageSizeChange = (_page: number, _pageSize: number) => {
        setPageSize(_pageSize);
    };

    let params: any = {}
    const handleGetInspectRecord = async (pageNum: number, pageSize: number, searchCondition?: any) => {
        setLoading(true);
        params.regularInspectionId = plan?.regularInspectionId
        params.pageSize = pageSize;
        params.pageNum = pageNum;
        if (searchCondition?.hydrantId) {
            params.hydrantId = searchCondition?.hydrantId
        }
        getRegularRecord(params).then((res: any) => {
            setLoading(false)
            if (res?.code === 200) {
                setTotal(Number(res?.data?.total));
                setRecordList(res?.data?.records);
            } else {
                showFail(res?.msg)
            }
        }).catch((err: any) => {
            console.log(err)
        })
    }

    useEffect(() => {
        if (open) {
            handleGetInspectRecord(pageNum, pageSize, {})
        }
    }, [pageSize, pageNum, open])

    const [searchCondition, setSearchCondition] = useState({
        hydrantId: "",
        recordState: ""
    });
    const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);

    const handleHydrantIdChange = (v: any) => {
        setSearchCondition({
            ...searchCondition,
            hydrantId: v
        })
    }

    const [hydrantList, setHydrantList] = useState<iHydrant[] | []>([]);
    const changeHydrantName = (e: any) => {
        if (e?.target?.value?.length > 0) {
            getHydrantList({hydrantName: e?.target?.value}).then((res: any) => {
                setHydrantList(res?.data)
            }).catch((err: any) => {
                console.log(err)
            })
        } else {
            showWarning("请输入XHS名称!")
        }
    }


    const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
    const handleCloseAddModal = () => {
        setAddModalVisible(false)
    }


    const AddModal = ({
                          open,
                          onCancel,
                          regularInspectionId,
                          handleGetInspectRecords
                      }: { open: boolean, onCancel: () => void, regularInspectionId: string, handleGetInspectRecords: any }) => {
        const [pageNum, setPageNum] = useState<number>(1);
        const [pageSize, setPageSize] = useState<number>(10);
        const [total, setTotal] = useState<number>(0);
        const [loading, setLoading] = useState<boolean>(false)
        const [hydrantList, setHydrantList] = useState<iHydrant[]>([])
        const onPageChange = (_page: number) => {
            setPageNum(_page);
        };

        const onPageSizeChange = (_page: number, _pageSize: number) => {
            setPageSize(_pageSize);
        };

        let params: any = {}
        const handleGetHydrantList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
            setLoading(true);
            params.pageSize = pageSize;
            params.pageNum = pageNum;
            if (searchCondition?.hydrantName) {
                params.hydrantName = searchCondition?.hydrantName
            }
            if (searchCondition?.hydrantAddress) {
                params.hydrantAddress = searchCondition?.hydrantAddress
            }
            if (searchCondition?.areaId) {
                params.areaId = searchCondition?.areaId
            }
            if (searchCondition?.qrCode) {
                params.qrCode = searchCondition?.qrCode
            }
            getHydrantPage(params).then((res: any) => {
                setLoading(false);
                if (res?.code === 200) {
                    setTotal(Number(res?.data?.total));
                    setHydrantList(res?.data?.records);
                } else {
                    showFail(res?.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        }

        useEffect(() => {
            if (open) {
                handleGetHydrantList(pageNum, pageSize, newSearchCondition);
            }
        }, [pageSize, pageNum, open])

        const [areaTree, setAreaTree] = useState<any>();
        const [areaTreeTran, setAreaTreeTran] = useState<any>();
        const handleGetArea = () => {
            getAreaTree().then((res) => {
                setAreaTree(res?.data);
                setAreaTreeTran(transform(res?.data))
            }).catch((err) => {
                console.log(err)
            })
        }

        const [initHydrantList, setInitHydrantList] = useState<string[]>([]);
        const handleGetInspectHydrantList = () => {
            getRegularInspectRecord({regularInspectionId}).then((res: any) => {
                if (res?.code === 200) {
                    console.log('getInspectRecordRes', res);
                    setSelectedRowKeys(res?.data?.map((i: any) => (i?.hydrantId)));
                    setInitHydrantList(res?.data?.map((i: any) => (i?.hydrantId)))
                }
            }).catch((err: any) => {
                console.log(err)
            })
        }
        useEffect(() => {
            if (open) {
                handleGetArea();
                handleGetInspectHydrantList();
            }
        }, [open])

        const [searchCondition, setSearchCondition] = useState({
            id: "",
            areaId: "",
            hydrantName: "",
            hydrantAddress: "",
            qrCode: ""
        });
        const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);


        const handleNameChange = (e: any) => {
            setSearchCondition({...searchCondition, hydrantName: e?.target?.value})
        }

        const handleAddressChange = (e: any) => {
            setSearchCondition({...searchCondition, hydrantAddress: e?.target?.value})
        }

        const handleQrcodeChange = (e: any) => {
            setSearchCondition({...searchCondition, qrCode: e?.target?.value})
        }

        const handleAreaChange = (v: any) => {
            if (v) {
                setSearchCondition({...searchCondition, areaId: v[v?.length - 1]})
            } else {
                setSearchCondition({...searchCondition, areaId: ""})
            }
        }

        //位置对话框
        const [hydrantLng, setHydrantLng] = useState<string | number>();
        const [hydrantLat, setHydrantLat] = useState();
        const [locationModalOpen, setLocationModalOpen] = useState(false);
        const handleCloseLocationModal = () => {
            setLocationModalOpen(false);
        }

        const [selectedRowKeys, setSelectedRowKeys] = useState<string[] | []>([]);
        const [selectedRows, setSelectedRows] = useState<any[]>([]);
        const onSelectChange = (newSelectedRowKeys: any, selectedRows: any) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedRows(selectedRows);
        };

        let finalSelected = diffArray(selectedRowKeys, initHydrantList);
        const hasSelected = finalSelected.length > 0;
        let finalSelectedRows = selectedRows.filter((item: any) => finalSelected.includes(item?.hydrantId as string))


        const rowSelection: any = {
            selectedRowKeys,
            onChange: onSelectChange,
            preserveSelectedRowKeys: true,
            type: 'checkbox',
            getCheckboxProps: (record: any) => {
                let disabled: boolean = false;
                let _init = initHydrantList?.map((s: string) => (Number(s)));
                if (_init.includes(Number(record?.hydrantId))) {
                    disabled = true
                }
                return {
                    ...record,
                    disabled
                }
            },
        };


        const columns: any = [
            {
                title: '名称',
                dataIndex: 'hydrantName',
                key: 'hydrantName',
            },
            {
                title: '县区及街道',
                dataIndex: 'areaId',
                key: 'areaId',
                render: (areaId: string) =>
                    (<div>{areaTree ? getAreaPath(areaTree, areaId) : "数据加载中"}</div>)
            },
            {
                title: '地址',
                dataIndex: 'hydrantAddress',
                key: 'hydrantAddress'
            },
            {
                title: '二维码',
                dataIndex: 'qrCode',
                key: 'qrCode',
            },
            {
                title: '定位',
                dataIndex: 'location',
                key: 'location',
                render: (_: any, record: any) => (
                    <>
                        {(record?.lng && record?.lat) ? (
                            <img style={{cursor: 'pointer'}} src={locationIcon} width={20} height={20} alt=""
                                 onClick={() => {
                                     setLocationModalOpen(true);
                                     setHydrantLat(record?.lat);
                                     setHydrantLng(record?.lng);
                                 }}/>
                        ) : (<div>
                            <Tooltip title={'该XHS暂无定位'}>
                                <img src={noneIcon} alt="" width={20} height={20}/>
                            </Tooltip>
                        </div>)}
                    </>
                )
            },
        ];

        const handleAddInspectHydrantList = () => {
            let _hydrantList = finalSelectedRows?.map((r: any) => {
                return {
                    regularInspectionId: regularInspectionId,
                    hydrantId: r?.hydrantId,
                    userId: r?.userId
                }
            })
            addRegularHydrantList(_hydrantList).then((res: any) => {
                if (res?.code === 200) {
                    onCancel();
                    handleGetInspectRecords().then(() => {
                        showSuccess(res?.msg);
                    })
                } else {
                    showFail(res?.msg)
                }
            })
        }

        return <Modal open={open} onCancel={onCancel} title={'新增待巡检XHS'} width={'800px'}
                      okText={'确认提交'} onOk={handleAddInspectHydrantList}>
            <div className={styles.mainHead}>
                <div className={styles.searchContent}>
                    <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                           onChange={handleNameChange} placeholder={'XHS名称'} allowClear/>

                    <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                           onChange={handleAddressChange} placeholder={'XHS地址'} allowClear/>

                    <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                           onChange={handleQrcodeChange} placeholder={'XHS二维码'} allowClear/>

                    <Cascader onChange={handleAreaChange} style={{width: 250, marginTop: '10px'}}
                              options={areaTreeTran ? [areaTreeTran] : []}
                              changeOnSelect placeholder="县区/街道/乡镇"/>

                </div>
                <div className={styles.searchButton}>
                    <Button style={{marginRight: '20px'}} type='primary' onClick={() => {
                        handleGetHydrantList(1, 10, searchCondition);
                        setPageNum(1);
                        setPageSize(10);
                        setNewSearchCondition(searchCondition)
                    }}>查询
                    </Button>

                </div>
            </div>


            <div style={{height: "1vh"}}/>
            {hasSelected && (<div style={{display: 'flex', flexDirection: 'row', fontSize: '15px'}}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row'
                    }}>已选中 {finalSelected.length} 个XHS
                </div>
                <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                    <Button style={{fontSize: '15px'}} type='link' onClick={() => {
                        setSelectedRowKeys(initHydrantList)
                    }}>
                        清除勾选
                    </Button>
                </div>
            </div>)}

            <Table rowKey={'hydrantId'} columns={columns} dataSource={hydrantList} loading={loading}
                   rowSelection={rowSelection}
                   pagination={{
                       current: pageNum,
                       onChange: onPageChange,
                       onShowSizeChange: onPageSizeChange,
                       total: total,
                       showSizeChanger: true,
                       defaultPageSize: pageSize,
                       pageSizeOptions: ['10', '20', '50', '100','200','500'],
                   }}/>
            <LocationModal open={locationModalOpen} onCancel={handleCloseLocationModal}
                           lat={hydrantLat} lng={hydrantLng}/>
        </Modal>
    }

    const [hydrantDetail, setHydrantDetail] = useState<iHydrant>();
    const [hydrantDetailModalVisible, setHydrantDetailModalVisible] = useState<boolean>(false);
    const handleCloseHydrantDetailModal = () => {
        setHydrantDetailModalVisible(false)
    }

    const handleDeleteRecord = (data: any) => {
        deleteRegularHydrantList(data).then((res: any) => {
            if (res?.code === 200) {
                handleGetInspectRecord(pageNum, pageSize, newSearchCondition).then(() => {
                    showSuccess(res?.msg);
                    setSelectedRowKeys([]);
                })
            } else {
                showFail(res?.msg)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const columns: any = [
        {
            title: 'XHS',
            dataIndex: 'hydrant',
            key: 'hydrant',
            render: (_: any, {hydrant}: any) => (
                <div style={{cursor: 'pointer', color: '#79cfef'}} onClick={() => {
                    setHydrantDetail(hydrant);
                    setHydrantDetailModalVisible(true);
                }}>
                    {hydrant?.hydrantName}
                </div>
            )
        },
        {
            title: '负责人',
            dataIndex: 'sysUser',
            key: 'sysUser',
            render: (sysUser: any) => (
                <div>
                    {sysUser?.username} {sysUser?.mobile}
                </div>
            )
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            fixed: 'right',
            render: (_: any, record: any) => (
                <div>
                    <Popconfirm title={"是否确认删除该巡检记录"} onConfirm={() => {
                        handleDeleteRecord([{
                            regularHydrantId: record?.regularHydrantId,
                            regularInspectionId: record?.regularInspectionId,
                            hydrantId: record?.hydrantId
                        }])
                    }}>
                        <Button danger type={'link'} color={'danger'}>删除</Button>
                    </Popconfirm>
                </div>
            )
        },
    ];

    const [selectedRowKeys, setSelectedRowKeys] = useState<string[] | []>([]);
    const [selectedRows,setSelectedRows]=useState<any[]>([])
    const onSelectChange = (newSelectedRowKeys: any, selectedRows: any) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedRows(selectedRows)
    };

    const hasSelected = selectedRowKeys.length > 0;
    const rowSelection: any = {
        selectedRowKeys,
        onChange: onSelectChange,
        preserveSelectedRowKeys: true,
        type: 'checkbox',
    };


    return (
        <Drawer open={open} onClose={() => {
            onCancel();
            setSelectedRowKeys([])
        }} width={'85vw'} title={`${plan?.name}巡检清单`} destroyOnClose
                extra={
                    <Space>
                        <Button onClick={() => {
                            setAddModalVisible(true)
                        }} type="primary">
                            新增待巡检XHS
                        </Button>
                    </Space>
                }
        >
            <div className={styles.mainHead}>
                <div className={styles.searchContent}>

                    <Select style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                            options={[
                                {value: 1, label: '待巡检'},
                                {value: 2, label: '已巡检'},
                            ]}
                            placeholder={'巡检状态'} allowClear/>

                    <Select
                        style={{width: '280px', marginRight: '30px', marginTop: '10px'}}
                        allowClear={true}
                        placeholder={"请选择XHS"}
                        onChange={handleHydrantIdChange}
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider style={{margin: '8px 0'}}/>
                                <Space style={{padding: '4px 8px 4px '}}>
                                    <Input
                                        placeholder="请输入XHS名称搜索"
                                        onChange={debounce(changeHydrantName, 1000)}
                                        allowClear
                                    />
                                </Space>
                            </>
                        )}
                        options={hydrantList.map((item) => ({
                            label: `名称:${item.hydrantName},详细地址:${item.hydrantAddress},编码:${item.qrCode})`,
                            value: item.hydrantId
                        }))}
                    />


                </div>
                <div className={styles.searchButton}>
                    <Button style={{marginRight: '20px'}} type='primary' onClick={() => {
                        handleGetInspectRecord(1, 10, searchCondition);
                        setPageNum(1);
                        setPageSize(10);
                        setNewSearchCondition(searchCondition)
                    }}>查询
                    </Button>
                </div>

            </div>

            <div style={{height: "1vh"}}/>
            {hasSelected && (<div style={{display: 'flex', flexDirection: 'row', fontSize: '15px'}}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row'
                    }}>已选中 {selectedRowKeys.length} 个XHS
                </div>
                <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                    <Button style={{fontSize: '15px', width: '80px'}} type='link' onClick={() => {
                        setSelectedRowKeys([])
                    }}>
                        取消勾选
                    </Button>
                    <Popconfirm title={'是否确认删除选中的记录?'} onConfirm={() => {
                        let deleteData = selectedRows?.map((s: any) => {
                            return {
                                regularHydrantId: s?.regularHydrantId,
                                regularInspectionId: s?.regularInspectionId,
                                hydrantId: s?.hydrantId
                            }
                        });
                        // console.log('deleteData',deleteData)
                        handleDeleteRecord(deleteData);
                    }}>
                        <Button style={{fontSize: '15px'}} danger type={'link'}>删除选中</Button>
                    </Popconfirm>
                </div>
            </div>)}


            <Table rowKey={'regularHydrantId'} columns={columns} dataSource={recordList} loading={loading}
                   rowSelection={rowSelection}
                   pagination={{
                       current: pageNum,
                       onChange: onPageChange,
                       onShowSizeChange: onPageSizeChange,
                       total: total,
                       showSizeChanger: true,
                       defaultPageSize: pageSize,
                       pageSizeOptions: ['10', '20', '50', '100'],
                   }}/>

            <AddModal open={addModalVisible} onCancel={handleCloseAddModal}
                      regularInspectionId={plan?.regularInspectionId as string} handleGetInspectRecords={async () => {
                handleGetInspectRecord(pageNum, pageSize, newSearchCondition)
            }}/>

            <HydrantDetailModal open={hydrantDetailModalVisible} onClose={handleCloseHydrantDetailModal}
                                hydrant={hydrantDetail as iHydrant}/>
        </Drawer>
    );
};

export default RegularPlanRecordDrawer;