import React, {useEffect, useState} from 'react';
import {Button, Divider, Input, message, Modal, Popconfirm, Select, Space, Spin, Table} from "antd";
import {AxiosError} from "axios/index";
import {PlusOutlined, TeamOutlined} from "@ant-design/icons";
import {
    addUser,
    deleteRole,
    searchAll,
    searchRole,
    updateRole,
    updateMenu, updateroleMenu, addRole
} from "../../../api";
// @ts-ignore
import {debounce} from "lodash";
import {Option} from "antd/es/mentions";
//角色管理
const RoleManagement = () => {
    //保存角色数据
    const [role, setRole] = useState<any>('');
    const [roleMenu, setRoleMenu] = useState([]);

    const [addMenuS, setAddMenuS] = useState<boolean>(false);
    const [updateRoleS, setUpdateRoleS] = useState<boolean>(false);
    const [updateMenuS, setUpdateMenuS] = useState<boolean>(false);
    useEffect(()=> {
        let params = {
            pageNum: 1,
            pageSize: 15,
        }
        searchRole(params).then( (res: any) => {
            let roleData = res.data.records;
            // let roleMenuData = res.data.records[0].sysMenus.map((v: any) => ({value: v.id, label: v.name}))
            // setRoleMenu(roleMenuData)
            for (let i = 0; i <res.data.records.length ; i++) {
                if(res.data.records[i].roleType===2){
                    res.data.records[i].roleType="管理权限"
                }
                if(res.data.records[i].roleType===0){
                    res.data.records[i].roleType="区域管理"
                }
            }
            setRole(roleData);
        }, (err: AxiosError) => {
            console.log(err);
        });
        let params2 = {
        }
        searchAll(params2).then( (res: any) => {
            let roleMenuData = res.data.map((v: any) => ({value: v.id, label: v.name}))
            setRoleMenu(roleMenuData)
        }, (err: AxiosError) => {
            console.log(err);
        });
    },[])

    let [inputName, setInputName] = useState<string>('');

    const changeVal= (event:any) =>{setInputName(event.target.value)}


    //角色分页查询包含权限
    const onGetRole = () => {
        let params = {
            pageNum: 1,
            pageSize: 10,
            name: inputName,
        }
        searchRole(params).then((res:any)=>{
            let roleData = res.data.records;
            // let roleMenuData = res.data.records[0].sysMenus.map((v:any)=>({value: v.id, label: v.name}))
            // setRoleMenu(roleMenuData)
            //console.log(roleMenuData)
            //console.log(roleData)
            for (let i = 0; i <res.data.records.length ; i++) {

                if(res.data.records[i].roleType===2){
                    res.data.records[i].roleType="管理权限"
                }
                if(res.data.records[i].roleType===0){
                    res.data.records[i].roleType="区域管理"
                }
            }
            setRole(roleData);
        }, (err:AxiosError)=>{
            console.log(err);
        });
        let params2 = {
        }
        searchAll(params2).then( (res: any) => {
            let roleMenuData = res.data.map((v: any) => ({value: v.id, label: v.name}))
            setRoleMenu(roleMenuData)
        }, (err: AxiosError) => {
            console.log(err);
        });

    }

    //新增用户数据
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [addName, setAddName] = useState<string>('');
    const [addRemark, setAddRemark] = useState<string>('');

    //新增角色
    const onAddRole = () =>{
        let params  = {
            name: addName,
            remark: addRemark,
            roleType:updateType
        };
        if(addName  && updateType) {
            setAddMenuS(true)
            addRole(params).then((res: any) => {
                if (/^4[0-9][0-9]/.test(res.code)) {
                    message.error(res.msg);
                    setAddMenuS(false)
                } else {
                    message.success(res.msg);
                    console.log(res)
                    setIsAddModalOpen(false);
                    setAddMenuS(false)
                    onGetRole();
                }
            }, (err: AxiosError) => {
                console.log(err);
                setAddMenuS(false)
            })
        }else {
            message.error("角色名、备注、角色类型不能为空");
        }
    }

    //删除角色
    const onDeleteRole =(val:any)=>{
        let params = {
            id: val.id,
        }
        console.log(params)

        deleteRole(params).then((res:any)=> {
            if (/^4[0-9][0-9]/.test(res.code)) {
                message.error(res.msg);
            }else {
                message.success(res.msg);
                onGetRole();

            }
        }, (err:AxiosError)=>{
            console.log(err);
        })
    }



    //修改对话框
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const handleUpdateCancel = () => {
        setUpdateModalOpen(false);
    };
    //保存原修改数据
    const [updateData, setUpdateData] = useState<any>('');

    const UpdateModal = ({open, onCancel, record}:{open:any, onCancel:any, record:any}) => {

        //待修改数据，需放在内部
        const [updateName, setupdateName] = useState('');
        const [updateRemark, setUpdateRemark] = useState('');
        const [updateType, setUpdateType] = useState('');
        const onUpdateRole = () => {
            setUpdateRoleS(true)
            let params = {
                id: record.id,
                name: updateName,
                remark: updateRemark,
                roleType:updateType
            }
            updateRole(params).then( (res:any)=> {
                if (/^4[0-9][0-9]/.test(res.code)) {
                    message.error(res.msg);
                }else {
                    message.success(res.msg);
                    setUpdateModalOpen(false);
                    setUpdateRoleS(false)
                    onGetRole();

                }
            }, (err:AxiosError)=>{
                console.log(err);
                setUpdateModalOpen(false);
                setUpdateRoleS(false)
            })

        };
        return (
            <Modal
                title='修改角色'
                open={open}
                onCancel={onCancel}
                onOk={onUpdateRole}
            >
                <Spin spinning={updateRoleS}>
                <div >角色名：<Input style={{width: '300px', marginBottom: '10px'}} defaultValue={record.name} onChange={e=>setupdateName(e.target.value)} /></div>
                <div>权限类型：  <Select defaultValue={record.roleType} style={{width: '300px', marginBottom: '10px'}} onChange={(e)=>setUpdateType(e)}>
                    <Option key={'2'} value={'2'}>管理权限</Option>
                    <Option key={'3'} value={'0'}>区域管理</Option>
                </Select></div>
                {/*<div >备注：<Input style={{width: '300px', marginBottom: '10px', marginLeft: '14px'}} defaultValue={record.remark} onChange={e=>setUpdateRemark(e.target.value)} /></div>*/}
                </Spin>
            </Modal>
        )
    }

    //修改权限对话框
    const [updateMenuModalOpen, setUpdateMenuModalOpen] = useState(false);
    const handleUpdateMenuCancel = () => {
        setUpdateMenuModalOpen(false);
    };

    const UpdateMenuModal = ({open, onCancel, record}:{open:any, onCancel:any, record:any}) => {

        const [menuList, setMenuList] = useState('');
        let currentMenu = record?.sysMenus?.length >= 1 ? (record.sysMenus.map((v:any)=>(v.id))) : undefined

        const onUpdateRole = () => {
            setUpdateMenuS(true)
            let params = {
                roleId: record.id,
                menuIdList: menuList,
            }

            updateroleMenu(params).then((res:any)=> {
                if (/^4[0-9][0-9]/.test(res.code)) {
                    message.error(res.msg);
                    setUpdateMenuS(false)
                }else {
                    message.success(res.msg);
                    setUpdateMenuS(false)
                    setUpdateMenuModalOpen(false);
                    onGetRole();
                }
            }, (err:AxiosError)=>{
                setUpdateMenuS(false)
                console.log(err);
            })

        };
        return (
            <Modal
                title='修改角色权限'
                open={open}
                onCancel={onCancel}
                onOk={onUpdateRole}
            >
                <Spin spinning={updateMenuS}>
                <div >角色名：<Input style={{width: '300px', marginBottom: '10px', color: "black"}} defaultValue={record.name} disabled /></div>
                <div >权限：
                    <Select
                        style={{width: '300px', marginBottom: '10px', marginLeft:'14px' }}
                        mode="multiple"
                        allowClear
                        defaultValue={currentMenu}
                        options={roleMenu}
                        onChange={(list) => {
                            setMenuList(list)
                        }}
                    />
                </div>
                </Spin>
            </Modal>
        )
    }


    //表头数据
    const Columns = [
        {
            title: '角色名',
            dataIndex: 'name',
            width: 200
        },
        // {
        //     title: '备注',
        //     dataIndex: 'remark',
        //     width: 200
        // },
        {
            title: '类型',
            dataIndex: 'roleType',
            width: 200
        },

        {
            title: '操作',
            dataIndex: 'operate',
            width: 300,
            render: (_: any, record: any) =>(
                role.length >= 1 ? (
                    <Space>
                        <Popconfirm title="确认删除吗?" onConfirm={() => onDeleteRole(record)}>
                            <a>删除</a>
                        </Popconfirm>

                        <a onClick={()=> {
                            setUpdateData(record);
                            setUpdateModalOpen(true);
                        }}>修改</a>

                        <a onClick={()=> {
                            setUpdateData(record);
                            setUpdateMenuModalOpen(true);
                        }}>修改权限</a>


                    </Space>
                ):null
            )
        },

    ]
    const [updateType, setUpdateType] = useState('');
    return (

        <div className='user_management_layout'>

            <div className='user_management_search'>
                <div className='user_management_header'><TeamOutlined/> 角色管理</div>
                <Input placeholder="请输入角色名" style={{width: '150px',}} onChange={changeVal}/>
                <Button onClick={debounce(()=>onGetRole(),300)} type="primary" style={{marginRight: '30%',}} >查询</Button>
            </div>

            <Divider style={{margin: 0}}/>

            <div className='user_management_add'>
                <Button onClick={()=>{setIsAddModalOpen(true)}} type="primary" ><PlusOutlined />新增</Button>
            </div>

            <div>
                <Table
                    rowKey="id"
                    columns={Columns}
                    dataSource={role}
                />
            </div>

            <Modal
                title='新增角色'
                open={isAddModalOpen}
                onCancel={()=>setIsAddModalOpen(false)}
                onOk={onAddRole}
            >
                <Spin spinning={addMenuS}>
                <div style={{padding: '20px'}}>
                    <div>角色名：<Input style={{width: '300px', marginBottom: '10px'}} onChange={(e)=>setAddName(e.target.value)} /></div>
                    <div>权限类型：  <Select style={{width: '300px', marginBottom: '10px'}} onChange={(e)=>setUpdateType(e)}>
                        <Option key={'2'} value={'2'}>管理权限</Option>
                        <Option key={'3'} value={'0'}>区域管理</Option>
                    </Select></div>
                    {/*<div>备注：<Input style={{width: '300px', marginBottom: '10px', marginLeft: '14px'}} onChange={(event:any)=>{setAddRemark(event.target.value)}} /></div>*/}

                </div>
                </Spin>
            </Modal>

            <UpdateModal record={updateData} onCancel={handleUpdateCancel} open={updateModalOpen}/>

            <UpdateMenuModal record={updateData} onCancel={handleUpdateMenuCancel} open={updateMenuModalOpen}/>


        </div>
    );
};

export default RoleManagement;