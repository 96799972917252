import {
    AutoComplete,
    Button,
    Carousel,
    Cascader,
    Form,
    Input,
    Modal,
    Popconfirm,
    Select,
    Table,
    Tag,
    Tooltip,
    Image
} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import styles from "./index.module.css"
import {iHydrant, iPicture} from "../../../interface";
import {deleteHydrant, getAllHydrantDoc, getAreaTree, getHydrantDoc, getHydrantPage,} from "../../../api";
import {getAreaPath, showFail, showSuccess, transform} from "../../../components/functions";
import LocationModal from "../../../components/locationModal";
import locationIcon from "../../../assets/icons/location.svg"
import noneIcon from "../../../assets/icons/none.svg"
import AddHydrantModal from "../../../components/addHydrantModal";
import EditHydrantModal from '../../../components/editHydrantModal';
import HydrantInspectRecordDrawer from '../../../components/hydrantInspectRecordModal';
import reportIcon from "../../../assets/icons/report.svg"
// @ts-ignore
import {debounce} from "lodash";
import {useLocation} from "react-router-dom";
import axios from 'axios';
import Axios from "../../../api/apiService.config";

const Hydrant = () => {
    const isSuperManager = JSON.parse(window.localStorage?.getItem("isSuperManager") as string);
    const location = useLocation();
        let oriQrCode = location?.state?.qrCode;
        const [pageNum, setPageNum] = useState<number>(1);
        const [pageSize, setPageSize] = useState<number>(10);
        const [total, setTotal] = useState<number>(0);
        const [loading, setLoading] = useState<boolean>(false)
        const [docOpen, setDocOpen] = useState<boolean>(false)
        const [docUrl, setDocUrl] = useState<string>('');

        const [generateLoading, setGenerateLoading] = useState<boolean>(false)
        const [allHydrantDocOpen, setAllHydrantDocOpen] = useState<boolean>(false)
        const [allHydrantDocUrl, setAllHydrantDocUrl] = useState<string>('')

        const [hydrantList, setHydrantList] = useState<iHydrant[]>([])
        const onPageChange = (_page: number) => {
            setPageNum(_page);
        };

        const onPageSizeChange = (_page: number, _pageSize: number) => {
            setPageSize(_pageSize);
        };

        let params: any = {}
        const handleGetHydrantList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
            setLoading(true);
            params.pageSize = pageSize;
            params.pageNum = pageNum;
            if (searchCondition?.hydrantName) {
                params.hydrantName = searchCondition?.hydrantName
            }
            if (searchCondition?.hydrantAddress) {
                params.hydrantAddress = searchCondition?.hydrantAddress
            }
            if (searchCondition?.areaId) {
                params.areaId = searchCondition?.areaId
            }
            if (searchCondition?.qrCode) {
                params.qrCode = searchCondition?.qrCode
            }
            if (searchCondition?.road) {
                params.road = searchCondition?.road
            }
            if (searchCondition?.hydrantState) {
                params.hydrantState = searchCondition?.hydrantState
            }
            if (searchCondition?.appearanceOk !== "") {
                params.appearanceOk = searchCondition?.appearanceOk
            }
            if (searchCondition?.hasWater !== "") {
                params.hasWater = searchCondition?.hasWater
            }
            if (searchCondition?.pressureOk !== "") {
                params.pressureOk = searchCondition?.pressureOk
            }
            getHydrantPage(params).then((res: any) => {
                setLoading(false);
                if (res?.code === 200) {
                    setTotal(Number(res?.data?.total));
                    setHydrantList(res?.data?.records);
                } else {
                    showFail(res?.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        }

        useEffect(() => {
            handleGetHydrantList(pageNum, pageSize, newSearchCondition);
        }, [pageSize, pageNum])

        const [areaTree, setAreaTree] = useState<any>();
        const [areaTreeTran, setAreaTreeTran] = useState<any>();
        const handleGetArea = () => {
            getAreaTree().then((res) => {
                setAreaTree(res?.data);
                let areaTreeTran = transform(res?.data);
                console.log('areaTreeTran', areaTreeTran);
                let _nthAreaTreeTran = areaTreeTran?.children?.find((d: any) => (d?.value === '4'))
                console.log('_nthAreaTreeTran', _nthAreaTreeTran);
                // setAreaTreeTran(transform(res?.data))
                setAreaTreeTran(_nthAreaTreeTran)
            }).catch((err) => {
                console.log(err)
            })
        }

        const [roads, setRoads] = useState([])

        const handleGetRoads = () => {
            // getRoads().then((res: any) => {
            //     const items = res?.data;
            //     const roadNames = new Set();
            //     const uniqueItems = items.filter((item: any) => {
            //         if (roadNames.has(item.roadName)) {
            //             return false;
            //         } else {
            //             roadNames.add(item.roadName);
            //             return true;
            //         }
            //     });
            //     setRoads(uniqueItems?.map((d: any) => {
            //         return {value: d?.roadName, label: d?.roadName}
            //     }))
            // }).catch((err: any) => {
            //     console.log(err)
            // })
        }

        useEffect(() => {
            handleGetArea();
            handleGetRoads();
        }, [])

        const [searchCondition, setSearchCondition] = useState({
            hydrantId: "",
            areaId: "4",
            hydrantName: "",
            hydrantAddress: "",
            qrCode: oriQrCode || "",
            road: "",
            hydrantState: "",
            appearanceOk: "",
            hasWater: "",
            pressureOk: ""
        });
        const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);


        const handleIdChange = (e: any) => {
            setSearchCondition({...searchCondition, hydrantId: e?.target?.value})
        }

        const handleNameChange = (e: any) => {
            setSearchCondition({...searchCondition, hydrantName: e?.target?.value})
        }

        const handleAddressChange = (e: any) => {
            setSearchCondition({...searchCondition, hydrantAddress: e?.target?.value})
        }

        const handleQrcodeChange = (e: any) => {
            setSearchCondition({...searchCondition, qrCode: e?.target?.value})
        }

        const handleAreaChange = (v: any) => {
            if (v) {
                setSearchCondition({...searchCondition, areaId: v[v?.length - 1]})
            } else {
                setSearchCondition({...searchCondition, areaId: "4"})
            }
        }

        const handleRoadChange = (v: any) => {
            setSearchCondition({
                ...searchCondition,
                road: v
            })
        }

        const [roadOptions, setRoadOptions] = useState<any[]>([])
        const getPanelValue = (searchText: string) =>
            !searchText ? [] : roads?.filter((r: any) => {
                return r?.value?.includes(searchText)
            })

        const handleHydrantStateChange = (v: any) => {
            setSearchCondition({...searchCondition, hydrantState: v})
        }

        const handleAppearanceChange = (v: any) => {
            setSearchCondition({...searchCondition, appearanceOk: v})
        }

        const handleHasWaterChange = (v: any) => {
            setSearchCondition({...searchCondition, hasWater: v})
        }

        const handlePressureChange = (v: any) => {
            setSearchCondition({...searchCondition, pressureOk: v})
        }

        const [imageVisible, setImageVisible] = useState<boolean>(false);
        const [hydrantPictures, setHydrantPictures] = useState<[]>([]);

        console.log('hydrantPictures', hydrantPictures)

        //位置对话框
        const [hydrantLng, setHydrantLng] = useState<string | number>();
        const [hydrantLat, setHydrantLat] = useState();
        const [locationModalOpen, setLocationModalOpen] = useState(false);
        const handleCloseLocationModal = () => {
            setLocationModalOpen(false);
        }

        //位置对话框
        const [hydrantInspectRecordModalOpen, setHydrantInspectRecordModalOpen] = useState(false);
        const handleCloseHydrantInspectModal = () => {
            setHydrantInspectRecordModalOpen(false);
        }

        const [selectedRowKeys, setSelectedRowKeys] = useState<string[] | []>([]);
        const [selectedRows, setSelectedRows] = useState([]);

        const onSelectChange = (newSelectedRowKeys: any, selectedRows: any) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedRows(selectedRows)
        };
        const rowSelection: any = {
            selectedRowKeys,
            onChange: onSelectChange,
            preserveSelectedRowKeys: true,
            type: 'checkbox',
            getCheckboxProps: (record: any) => {
                let rectifyIdList = rectifyList?.map((r: any) => (r?.hydrantId))
                let disabled: boolean = false;
                if (record?.hydrantState === 1 || rectifyIdList.includes(record?.hydrantId)) {
                    disabled = true
                }
                return {
                    ...record,
                    disabled
                }
            },
        };
        const hasSelected = selectedRowKeys.length > 0;
        const columns: any = [
            {
                title: '名称',
                dataIndex: 'hydrantName',
                key: 'hydrantName',
                fixed: 'left',
                width: 180,
            },
            {
                title: '照片',
                dataIndex: 'picture',
                key: 'picture',
                render: (_: any, record: any) => (
                    <div>{(record?.pictures && record?.pictures?.length > 0) ? (
                        <Button type={'link'} onClick={() => {
                            setHydrantPictures(record?.pictures?.map((u: any) => {
                                return {
                                    key: u?.pictureId,
                                    src: u?.url
                                }
                            }));
                            setImageVisible(true)
                        }}>查看</Button>) : <div>
                        该消防栓无照片
                    </div>}
                    </div>
                )
            },

            {
                title: '县区及街道',
                dataIndex: 'areaId',
                key: 'areaId',
                render: (areaId: string) =>
                    (<div>{areaTree ? getAreaPath(areaTree, areaId) : "数据加载中"}</div>)
            },
            {
                title: '道路',
                dataIndex: 'road',
                key: 'road'
            },
            {
                title: '地址',
                dataIndex: 'hydrantAddress',
                key: 'hydrantAddress'
            },
            {
                title: '编码',
                dataIndex: 'qrCode',
                key: 'qrCode',
            },
            {
                title: '状态',
                dataIndex: 'hydrantState',
                key: 'hydrantState',
                render: (hydrantState: any) => (
                    <>
                        {hydrantState === 1 ? <Tag color={'green'}>正常</Tag> :
                            hydrantState === 2 ? <Tag color={'red'}>异常</Tag> :
                                ""}
                    </>
                )
            },
            {
                title: '外观',
                dataIndex: 'appearanceOk',
                key: 'appearanceOk',
                render: (appearanceOk: any) => (
                    <>
                        {appearanceOk === 0 ? <Tag color={'#f50'}>有损坏</Tag> :
                            appearanceOk === 1 ? <Tag color={'#87d068'}>完好</Tag> :
                                ""}
                    </>
                )
            },
            {
                title: '出水情况',
                dataIndex: 'hasWater',
                key: 'hasWater',
                render: (hasWater: any) => (
                    <>
                        {hasWater === 0 ? <Tag color={'#f50'}>不出水</Tag> :
                            hasWater === 1 ? <Tag color={'#87d068'}>有水</Tag> :
                                ""}
                    </>
                )
            },
            {
                title: '水压',
                dataIndex: 'pressureOk',
                key: 'pressureOk',
                render: (pressureOk: any) => (
                    <>
                        {pressureOk === 0 ? <Tag color={'#f50'}>水压不足</Tag> :
                            pressureOk === 1 ? <Tag color={'#87d068'}>正常</Tag> :
                                ""}
                    </>
                )
            },
            {
                title: '描述',
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: '巡检备注',
                dataIndex: 'hydrantRemark',
                key: 'hydrantRemark',
            },
            {
                title: '定位',
                dataIndex: 'location',
                key: 'location',
                render: (_: any, record: any) => (
                    <>
                        {(record?.lng && record?.lat) ? (
                            <img style={{cursor: 'pointer'}} src={locationIcon} width={20} height={20} alt=""
                                 onClick={() => {
                                     setLocationModalOpen(true);
                                     setHydrantLat(record?.lat);
                                     setHydrantLng(record?.lng);
                                 }}/>
                        ) : (<div>
                            <Tooltip title={'该XHS暂无定位'}>
                                <img src={noneIcon} alt="" width={20} height={20}/>
                            </Tooltip>
                        </div>)}
                    </>
                )
            },
            {
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                fixed: 'right',
                render: (_: any, record: any) => (
                    <div>

                        <Button type={'link'} onClick={() => {
                            setHydrantRecord(record);
                            setEditModalVisible(true);
                        }}>修改</Button>
                        {isSuperManager&&(
                            <>
                                <Popconfirm title={"是否确认删除该XHS"} onConfirm={() => {
                                    deleteHydrant({hydrantId: record?.hydrantId}).then((res: any) => {
                                        if (res?.code === 200) {
                                            handleGetHydrantList(pageNum, pageSize, newSearchCondition).then(() => {
                                                showSuccess(res?.msg);
                                            })
                                        } else {
                                            showFail(res?.msg)
                                        }
                                    }).catch((err) => {
                                        console.log(err)
                                    })
                                }}>
                                    <Button danger type={'link'} color={'danger'}>删除</Button>
                                </Popconfirm>
                            </>
                        )}
                        <Button type={'link'} onClick={() => {
                            setHydrantRecord(record)
                            setHydrantInspectRecordModalOpen(true);
                        }}>查看巡检记录</Button>
                    </div>
                )
            },
        ];

        //新增
        const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
        const handleCloseAddModal = () => {
            setAddModalVisible(false);
        }

        //修改
        const [hydrantRecord, setHydrantRecord] = useState<iHydrant>();
        const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
        const handleCloseEditModal = () => {
            setEditModalVisible(false);
        }

        const [rectifyList, setRectifyList] = useState<any>([])
        console.log('rectifyList', rectifyList)
        const [writeRemarkModalOpen, setWriteRemarkModalOpen] = useState<boolean>(false);
        const handleCloseWriteRemarkModal = () => {
            setWriteRemarkModalOpen(false)
        }
        const WriteRemarkModal = ({open, onCancel, hydrantList}: {
            open: boolean, onCancel: () => void, hydrantList: any[]
        }) => {
            const [rectifyRemark, setRectifyRemark] = useState<string>("");
            const handleRectifyRemarkChange = (e: any) => {
                setRectifyRemark(e?.target?.value)
            }

            const handleAddRectifyHydrant = () => {
                let _hydrantList = hydrantList?.map((h: any) => {
                    return {
                        ...h,
                        hydrantRemark: rectifyRemark
                    }
                })
                setRectifyList((prev: any) => {
                    let _rectifyList = [...prev, ..._hydrantList]
                    return _rectifyList
                })
                setWriteRemarkModalOpen(false);
                setRectifyFormOpen(true);
                setSelectedRowKeys([]);
                setSelectedRows([]);
            }

            return <Modal title={'整改备注'} open={open} onCancel={onCancel}
                          okText={'添加至整改清单'} onOk={handleAddRectifyHydrant}
            >
                <div style={{marginBottom: '10px'}}>
                    {hydrantList?.map((h: any) => (
                        <div style={{fontSize: '18px', color: '#498ac4'}}>
                            {h?.hydrantName}
                        </div>
                    ))}
                </div>

                <Input.TextArea rows={5} placeholder="请填写选中XHS的整改备注" onChange={handleRectifyRemarkChange}/>
            </Modal>
        }


        const [rectifyFormOpen, setRectifyFormOpen] = useState<boolean>(false);
        const handleCloseRectifyForm = () => {
            setRectifyFormOpen(false);
        }
        const RectifyFormModal = ({open, onCancel}: {
            open: boolean, onCancel: () => void
        }) => {
            console.log('rectifyList', rectifyList)
            const [form] = Form.useForm();
            const [pushLoading, setPushLoading] = useState<boolean>(false);
            const handlePushRectifyForm = () => {
                form.validateFields().then((values: any) => {
                    setPushLoading(true)
                    console.log('values', values);
                    const list = Object.entries(values).map(([hydrantId, hydrantRemark]) => ({
                        hydrantId,
                        hydrantRemark
                    }));
                    getHydrantDoc(list).then((res: any) => {
                        setPushLoading(false)
                        if (res?.code === 200) {
                            setRectifyFormOpen(false);
                            setDocUrl(res.msg);
                            setDocOpen(true)
                        } else {
                            showFail(res?.msg)
                        }
                    })
                }).catch((err: any) => {
                    console.log(err)
                })
            }

            return (<Modal open={open} onCancel={() => {
                onCancel();
                setRectifyList([])
            }} title={'整改清单(待生成通告)'} okText={'生成整改通告'}
                           onOk={debounce(handlePushRectifyForm, 1000)} cancelText={'关闭并清空待整改清单'}
                           cancelButtonProps={{danger: true}} closeIcon={false} maskClosable={false}
                           okButtonProps={{loading: pushLoading}}
            >
                <Form form={form} name="form" layout="vertical">
                    {rectifyList?.map((r: any, index: number) => (
                        <Form.Item
                            name={r?.hydrantId}
                            label={`${index + 1}. ${r?.hydrantName}整改备注`}
                            rules={[
                                {
                                    required: true, message: "请填写整改备注"
                                },
                            ]}
                            initialValue={r?.hydrantRemark}
                        >
                            <Input.TextArea rows={3} placeholder={'请填写整改备注'}/>
                        </Form.Item>
                    ))}

                </Form>
                <Button style={{width: '50%', margin: "20px 25%"}} type={'default'}
                        onClick={onCancel}>关闭并继续添加待整改消防栓</Button>
            </Modal>)
        }


        return (
            <div className={styles.main}>
                {(rectifyList?.length > 0 && !rectifyFormOpen) && (
                    <div className={styles.rectifyButton} onClick={() => {
                        setRectifyFormOpen(true);
                    }}>
                        <div style={{marginRight: '5px', display: "flex", alignItems: 'center'}}>
                            <img src={reportIcon}
                                 width={36} height={36}
                                 alt=""/></div>
                        <div>整改清单(待生成通告)</div>
                    </div>
                )}
                <div className={styles.mainHead}>
                    <div className={styles.searchContent}>
                        <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                               onChange={handleIdChange} placeholder={'XHSid'} allowClear
                        />

                        <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                               onChange={handleNameChange} placeholder={'XHS名称'} allowClear/>

                        <Cascader onChange={handleAreaChange} style={{width: 250, marginRight: '30px', marginTop: '10px'}}
                                  options={areaTreeTran ? [areaTreeTran] : []}
                                  changeOnSelect placeholder="县区/街道/乡镇" allowClear/>

                        <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                               onChange={handleAddressChange} placeholder={'XHS地址'} allowClear/>

                        <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                               onChange={handleQrcodeChange} placeholder={'XHS二维码'} allowClear
                               value={searchCondition?.qrCode}/>

                        <AutoComplete
                            options={roadOptions}
                            style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                            onSearch={(text) => setRoadOptions(getPanelValue(text))}
                            placeholder="道路"
                            allowClear
                            onChange={handleRoadChange}
                        />


                        <Select style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                                options={[{value: 1, label: "各项正常"}, {value: 2, label: "存在异常情况"}]}
                                placeholder="XHS状态" onChange={handleHydrantStateChange} allowClear/>

                        <Select style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                                options={[{value: 1, label: "完好"}, {value: 0, label: "有损坏"}]}
                                placeholder="外观情况" onChange={handleAppearanceChange} allowClear/>

                        <Select style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                                options={[{value: 1, label: "有水"}, {value: 0, label: "不出水"}]}
                                placeholder="出水情况" onChange={handleHasWaterChange} allowClear/>

                        <Select style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                                options={[{value: 1, label: "正常"}, {value: 0, label: "水压不足"}]}
                                placeholder="水压情况" onChange={handlePressureChange} allowClear/>


                    </div>
                    <div className={styles.searchButton}>

                        <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                            handleGetHydrantList(1, 10, searchCondition);
                            setPageNum(1);
                            setPageSize(10);
                            setNewSearchCondition(searchCondition)
                        }}>查询
                        </Button>

                        <Button type='primary' style={{marginRight: '20px', marginTop: '10px'}} onClick={() => {
                            setAddModalVisible(true);
                        }}>新增
                        </Button>

                        <Button type='primary'
                                style={{
                                    marginRight: '20px',
                                    marginTop: '10px',
                                    border: 'none',
                                    backgroundColor: '#33c70e'
                                }} onClick={() => {
                            getAllHydrantDoc().then((res: any) => {
                                if (res?.code === 200) {
                                    console.log('getAllHydrantDoc', res)
                                    setAllHydrantDocOpen(true);
                                    setAllHydrantDocUrl(res?.msg)
                                }
                            })
                        }}>
                            生成最新巡检报告
                        </Button>


                        <Button type='primary'
                                style={{
                                    marginRight: '20px',
                                    marginTop: '10px',
                                    border: 'none',
                                    backgroundColor: '#c7800e'
                                }} onClick={() => {
                            let url:any;
                            if (process.env.NODE_ENV === 'development') {
                                url = "http://localhost:8006/api/hydrant/getExcelAllHydrant";
                            } else {
                                url = "https://xfapi-dev.iterated.xyz/api/hydrant/getExcelAllHydrant";
                            };
                            axios({
                                url,
                                method: 'post',
                                responseType:"blob",
                                headers: {
                                    'Authorization': `${window.localStorage.getItem('token')}`
                                }
                            }).then((res:any) => {
                                const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'}));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', "所有XHS巡检情况" + '.xls');
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link); //下载完成移除元素
                                window.URL.revokeObjectURL(url); //释放掉blob对象
                            }, (err:any)=>{
                                console.log(err);
                            })
                        }}>
                            下载最新巡检报告表格
                        </Button>
                    </div>

                </div>

                {hasSelected && (
                    <div style={{display: 'flex', flexDirection: 'row', fontSize: '15px', marginBottom: '10px'}}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row'
                            }}>已选中 {selectedRowKeys.length} 个XHS
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                            <Button style={{fontSize: '15px', marginLeft: "10px"}} type='primary' onClick={() => {
                                console.log('selectedRowKeys', selectedRowKeys);

                                setWriteRemarkModalOpen(true)
                                let hydrantList = selectedRowKeys?.map((d: any) => {
                                    return {
                                        hydrantId: d
                                    }
                                })
                                // getHydrantDoc(hydrantList).then((res: any) => {
                                //     if (res?.code === 200) {
                                //         setDocUrl(res.msg)
                                //         setDocOpen(true)
                                //     } else {
                                //         showFail(res?.msg)
                                //     }
                                // })
                            }}>
                                填写整改备注
                            </Button>

                            <Button style={{fontSize: '15px', marginLeft: "10px", backgroundColor: '#d4a72b'}}
                                    type='primary' onClick={()=>{
                                let hydrantList = selectedRowKeys?.map((d: any) => {
                                    return {
                                        hydrantId: d
                                    }
                                });
                                let url:any;
                                if (process.env.NODE_ENV === 'development') {
                                    url = "http://localhost:8006/api/hydrant/getChangeExcel";
                                } else {
                                    url = "https://xfapi-dev.iterated.xyz/api/hydrant/getChangeExcel";
                                };
                                console.log('hydrantList',hydrantList)
                                axios({
                                    url,
                                    method: 'post',
                                    data: hydrantList,
                                    responseType:"blob",
                                    headers: {
                                        'Authorization': `${window.localStorage.getItem('token')}`
                                    }
                                }).then((res:any) => {

                                            const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'}));
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', "整改XHS" + '.xls');
                                            document.body.appendChild(link);
                                            link.click();
                                            document.body.removeChild(link); //下载完成移除元素
                                            window.URL.revokeObjectURL(url); //释放掉blob对象

                                }, (err:any)=>{
                                    console.log(err);
                                })
                            }}>
                                下载整改表格
                            </Button>

                            <Button danger style={{fontSize: '15px', marginLeft: "10px"}} type='primary' onClick={() => {
                                setSelectedRowKeys([])
                            }}>
                                取消勾选
                            </Button>
                        </div>
                    </div>)}
                <Table rowKey={'hydrantId'} scroll={{x: 1800}} columns={columns} dataSource={hydrantList} loading={loading}
                       rowSelection={rowSelection}
                       pagination={{
                           current: pageNum,
                           onChange: onPageChange,
                           onShowSizeChange: onPageSizeChange,
                           total: total,
                           showSizeChanger: true,
                           defaultPageSize: pageSize,
                           pageSizeOptions: ['10', '20', '50', '100'],
                       }}/>
                {/*<ImageModal open={imageModalVisible} pictures={hydrantPictures} onCancel={handleCloseImageModal}/>*/}
                <Image.PreviewGroup
                    items={hydrantPictures}
                    preview={{
                        visible: imageVisible,
                        onVisibleChange: (value) => {
                            setImageVisible(value);
                        },
                    }}
                >
                    {hydrantPictures.map((image: any) => (
                        <Image
                            key={image.key}
                            width={200}
                            height={200}
                            style={{display: 'none'}}
                            src={image.src}
                            preview={{
                                scaleStep: 0.5,
                                src: image.src,
                            }}
                        />
                    ))}
                </Image.PreviewGroup>
                <LocationModal open={locationModalOpen} onCancel={handleCloseLocationModal}
                               lat={hydrantLat} lng={hydrantLng}/>
                <AddHydrantModal open={addModalVisible} onClose={handleCloseAddModal} areaOptions={[areaTreeTran]}
                                 handleGetHydrant={async () => {
                                     await handleGetHydrantList(pageNum, pageSize, newSearchCondition)
                                 }}/>

                <EditHydrantModal open={editModalVisible} onClose={handleCloseEditModal} areaOptions={[areaTreeTran]}
                                  handleGetHydrant={async () => {
                                      await handleGetHydrantList(pageNum, pageSize, newSearchCondition)
                                  }} hydrantRecord={hydrantRecord}/>

                <HydrantInspectRecordDrawer open={hydrantInspectRecordModalOpen} onClose={handleCloseHydrantInspectModal}
                                            hydrant={hydrantRecord as iHydrant}/>

                <WriteRemarkModal open={writeRemarkModalOpen} onCancel={handleCloseWriteRemarkModal}
                                  hydrantList={selectedRows}/>

                <RectifyFormModal open={rectifyFormOpen} onCancel={handleCloseRectifyForm}/>

                <Modal
                    title='整改通告预览'
                    open={docOpen}
                    onCancel={() => {
                        setDocOpen(false)
                    }}
                    // onOk={onAddDept}
                    width={"1000px"}
                    footer={[]}
                    style={{top: 10}}
                >
                    <div style={{
                        boxSizing: "border-box",
                        padding: "16px",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#f3f2f1",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start"
                    }}>
                        <iframe style={{width: "100%", height: "800px"}} title={"docViewer"}
                                src={"https://view.officeapps.live.com/op/view.aspx?src=" + docUrl + "&timestamp=" + Date.now() + "}"}/>
                    </div>
                </Modal>

                <Modal
                    title='最新巡检记录'
                    open={allHydrantDocOpen}
                    onCancel={() => {
                        setAllHydrantDocOpen(false)
                    }}
                    // onOk={onAddDept}
                    width={"1000px"}
                    footer={[]}
                    style={{top: 10}}
                >
                    <div style={{
                        boxSizing: "border-box",
                        padding: "16px",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#f3f2f1",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start"
                    }}>
                        <iframe style={{width: "100%", height: "800px"}} title={"docViewer"}
                                src={"https://view.officeapps.live.com/op/view.aspx?src=" + allHydrantDocUrl + "&timestamp=" + Date.now() + "}"}/>
                    </div>
                </Modal>
            </div>
        );
    }
;

export default Hydrant;