import React from 'react';
import {UploadOutlined} from '@ant-design/icons';
import {Button, Upload, message} from 'antd';
import {showSuccess} from "../functions";


const AddNewFile = ({value, onChange, max,name,afterSuccess,type}: any) => {

    const handleChange = ({fileList,event}: any) => {
        onChange?.([...fileList]);
        if(fileList[0]?.response?.code===200){
            if(type===1){
                afterSuccess().then(()=>{
                    showSuccess(`${name}新增成功`)
                });
            }else{
                afterSuccess(fileList[0]?.response?.data)
            }

        }
    };
    const onRemove = (file: any) => {
        const files = (value || []).filter((v: any) => v.url !== file.url);
        if (onChange) {
            onChange(files);
        }
    };

    const getExtraData = (file: any) => ({
        image: file,
        name:name,
    });

    let url="";
    if (process.env.NODE_ENV === 'development') {
        url = "http://localhost:8002/api";
    } else {
        url = "https://www.huxf.com.cn/api";
    }

    const uploadProps: any = {
        listType: "picture",
        name: 'file',
        fileList: value,
        action:type===1? `${url}/chemicals/upload`:`${url}/community/uploadPdf`,
        onChange: handleChange,
        onRemove,
        maxCount: max,
        data: getExtraData,
        headers: {
            Authorization: window.localStorage.getItem('token'),
        },
        beforeUpload: (file: any) => {
            const isPNGorJPG = file?.type === 'application/pdf';
            if (!isPNGorJPG) {
                message.error(`仅支持上传PDF格式的文档`);
            }
            return isPNGorJPG || Upload.LIST_IGNORE;
        },
        afterUpload: (res:any)=>{
            console.log('res',res);
        }
    };
    return (
        <Upload {...uploadProps}>
            <Button icon={<UploadOutlined/>}>上传</Button>
        </Upload>
    );
};

export default AddNewFile;