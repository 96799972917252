import {Form, Input, Modal} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
// @ts-ignore
import {debounce} from "lodash"
import {addDict, updateDict} from "../../api";
import {getAreaIdArray, showFail, showNeedCompleteInfo, showSuccess, showWarning} from "../functions";

const useResetFormOnCloseModal = ({form, open}: { form: any, open: boolean }) => {
    const prevOpenRef: any = useRef();
    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;
    useEffect(() => {
        if (!open && prevOpen) {
            form.resetFields();
        }
    }, [form, prevOpen, open]);
};
const EditDictModal = ({
                           open,
                           onCancel,
                           record,
                           handleGetDict
                       }: { open: boolean, onCancel: () => void, record: any, handleGetDict: any }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false)
    useResetFormOnCloseModal({
        form,
        open,
    });

    useEffect(() => {
        if (open) {
            form.resetFields();
        }
    }, [open])

    const handleAddNewDict = () => {
        form.validateFields().then((value) => {
            setLoading(true);
            updateDict({...value, id: record.id, isLook: 1}).then((res: any) => {
                setLoading(false);
                if (res.code === 200) {
                    handleGetDict().then(() => {
                        showSuccess(res?.msg);
                    });
                    onCancel();
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                console.log(err)
            })
        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }

    return (
        <div>
            <Modal open={open} onCancel={onCancel} title={'新增配置'} maskClosable={false} okText={'确认提交'}
                   onOk={debounce(handleAddNewDict, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                <Form form={form} name="form" labelAlign="right" labelCol={{
                    xs: {span: 24},
                    sm: {span: 6},
                }} wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 14},
                }}>
                    <Form.Item
                        name="model"
                        label="类型"
                        initialValue={record?.model}
                        rules={[
                            {
                                required: true, message: "类型不能为空"
                            },
                        ]}
                    >
                        <Input placeholder="请输入类型"/>
                    </Form.Item>

                    <Form.Item
                        name="attribute"
                        label="名称"
                        initialValue={record?.attribute}
                        rules={[
                            {
                                required: true, message: "名称不能为空"
                            },
                        ]}
                    >
                        <Input placeholder="请输入名称"/>
                    </Form.Item>

                    <Form.Item
                        name="value"
                        label="内容"
                        initialValue={record?.value}
                        rules={[
                            {
                                required: true, message: "内容不能为空"
                            },
                        ]}
                    >
                        <Input placeholder="请输入内容"/>
                    </Form.Item>

                    <Form.Item
                        name="description"
                        label="描述"
                        initialValue={record?.description}
                    >
                        <Input placeholder="请输入描述"/>
                    </Form.Item>

                    <Form.Item
                        name="dictOrder"
                        label="序号"
                        initialValue={record?.dictOrder}
                        rules={[
                            {
                                required: true, message: "序号不能为空"
                            },
                        ]}
                    >
                        <Input placeholder="请输入序号"/>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default EditDictModal;