import {Button, Modal} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
// @ts-ignore
import {debounce} from "lodash"
import {showFail, showNeedCompleteInfo, showSuccess, showWarning} from "../functions";
import styles from './index.module.css'


const CarNumberInputDialog = ({
                                  open,
                                  onCancel,
                                  licensePlateNumber,
                                  confirmCarNumber,
                              }: { open: boolean, onCancel: () => void, licensePlateNumber: string, confirmCarNumber: any }) => {

    const [loading, setLoading] = useState<boolean>(false);
    // const [licensePlateNumber, setLicensePlateNumber] = useState<string>('浙E')
    const [provinceKeyboardShow, setProvinceKeyboardShow] = useState<boolean>(licensePlateNumber?.length <= 0);
    const [letterKeyboardShow, setLetterKeyboardShow] = useState<boolean>(licensePlateNumber?.length > 0);
    const [digitalKeyboardShow, setDigitalKeyboardShow] = useState<boolean>(false);


    const licensePlate_provinces = [
        "京", "沪", "湘", "苏", "粤", "鲁",
        "晋", "冀", "豫", "川", "渝", "辽",
        "吉", "黑", "皖", "鄂", "津", "贵",
        "云", "桂", "琼", "青", "新", "藏",
        "蒙", "宁", "甘", "陕", "闽", "赣",
        "浙"
    ];

    const licensePlate_letter = [
        "A", "B", "C", "D", "E", "F",
        "G", "H", "L", "J", "K", "L",
        "M", "N", "O", "P", "Q", "R",
        "S", "T", "U", "V", "W", "X",
        "Y", "Z"
    ];

    const licensePlate_digital = [
        "1", "2", "3",
        "4", "5", "6",
        "7", "8", "9",
        "0"
    ]

    const chooseLicensePlateProvinces = (d: any) => {
        // setLicensePlateNumber(d);
        let number =  d;
        confirmCarNumber(number)
        setProvinceKeyboardShow(false);
        setLetterKeyboardShow(true);
        setDigitalKeyboardShow(false);
    }

    const chooseLicensePlateLetter = (d: any) => {
        const LicensePlateNumberLen = licensePlateNumber.length;
        if (LicensePlateNumberLen != 8) {
            let number = licensePlateNumber + d;
            confirmCarNumber(number);
            if(number?.length == 8){
                onCancel();
            }
        } else {
            showWarning("车牌号码最多不能超过8位")
        }
    }

    const chooseLicensePlateDigital = (d: any) => {
        const LicensePlateNumberLen = licensePlateNumber.length;
        if (LicensePlateNumberLen != 8) {
            let number = licensePlateNumber + d;
            confirmCarNumber(number);
            if(number?.length == 8){
                onCancel();
            }
        } else {
            showWarning("车牌号码最多不能超过8位")
        }
    }


    const licensePlaceDelete=()=>{
        const LicensePlateNumberLen = licensePlateNumber.length;
        const LicensePlateNumberDelete = licensePlateNumber.split('');
        const NewLicensePlateNumber = LicensePlateNumberDelete.join('').slice(0,-1)
            if (LicensePlateNumberDelete.slice(0,-1).length == 1){
                setProvinceKeyboardShow(false);
                setLetterKeyboardShow(true);
                setDigitalKeyboardShow(false);
            } else if(LicensePlateNumberLen == 0 || licensePlateNumber == '' || LicensePlateNumberDelete.slice(0, -1).length == 0){
                setProvinceKeyboardShow(true);
                setLetterKeyboardShow(false);
                setDigitalKeyboardShow(false);
            }
            confirmCarNumber(NewLicensePlateNumber)
    }

    const licensePlaceDeleteAll=()=>{
        setProvinceKeyboardShow(true);
        setLetterKeyboardShow(false);
        setDigitalKeyboardShow(false);
        confirmCarNumber("")
    }


    return (
        <div>
            <Modal width={'620px'} height={'290px'} open={open} onCancel={onCancel} closeIcon={false} okText={'确认'}
                   onOk={debounce(() => {
                   }, 1000)} okButtonProps={{loading: loading}} destroyOnClose mask={false}
                   style={{top: "55vh"}}
                   footer={(_, {OkBtn}) => (
                       <>
                           {licensePlateNumber?.length>0&&(
                               <Button onClick={() => {
                                   if (letterKeyboardShow) {
                                       setProvinceKeyboardShow(false);
                                       setLetterKeyboardShow(false);
                                       setDigitalKeyboardShow(true);
                                   } else {
                                       setProvinceKeyboardShow(false);
                                       setLetterKeyboardShow(true);
                                       setDigitalKeyboardShow(false);
                                   }
                               }} type={'primary'}>{letterKeyboardShow ? "数字" : "字母"}</Button>
                           )}

                           <Button danger onClick={licensePlaceDelete}>删除</Button>
                           <Button danger onClick={licensePlaceDeleteAll}>清空</Button>
                           <Button onClick={onCancel}>关闭</Button>
                       </>
                   )}
            >
                {provinceKeyboardShow && (
                    <div className={styles.licensePlate_provinces_Box}>
                        {licensePlate_provinces?.map((d: any) => (
                            <div className={styles.licensePlate_provincesTist} onClick={() => {
                                chooseLicensePlateProvinces(d)
                            }}>
                                {d}
                            </div>
                        ))}
                    </div>
                )}

                {letterKeyboardShow && (
                    <div className={styles.licensePlate_letter_Box}>
                        {licensePlate_letter?.map((d: any) => (
                            <div className={styles.licensePlate_letterTist} onClick={() => {
                                chooseLicensePlateLetter(d)
                            }}>
                                {d}
                            </div>
                        ))}
                    </div>)}

                {digitalKeyboardShow && (
                    <div className={styles.licensePlate_digital_Box}>
                        {licensePlate_digital?.map((d: any) => (
                            <div className={styles.licensePlate_digitalTist} onClick={() => {
                                chooseLicensePlateDigital(d)
                            }}>
                                {d}
                            </div>
                        ))}
                    </div>)}

            </Modal>
        </div>
    );
};

export default CarNumberInputDialog;