import React from 'react';
import {UploadOutlined} from '@ant-design/icons';
import {Button, Upload, message} from 'antd';


const AddNewPictures = ({value, onChange, max, type, id, name}: any) => {

    const handleChange = ({fileList}: any) => {
        onChange?.([...fileList]);
    };
    const onRemove = (file: any) => {
        const files = (value || []).filter((v: any) => v.url !== file.url);
        if (onChange) {
            onChange(files);
        }
    };

    const getExtraData = (file: any) => ({
        image: file
    });

    let url="";
    if (process.env.NODE_ENV === 'development') {
        url = "http://localhost:8006/api";
    } else {
        url = "https://www.huxf.com.cn/api";
    }

    const uploadProps: any = {
        listType: "picture",
        name: 'file',
        fileList: value,
        action: type === 1 ? `${url}/picture/upload/${id}/${name}`:type===2?
            `${url}/community/upload/${id}/${name}`:`${url}/floorPicture/upload/${id}/${name}`,
        onChange: handleChange,
        onRemove,
        maxCount: max,
        data: getExtraData,
        headers: {
            Authorization: window.localStorage.getItem('token')
        },
        beforeUpload: (file: any) => {
            console.log(file?.type)
            const isPNGorJPG = file?.type === 'image/png' || file.type === "image/tiff" || file.type === "image/jpeg";
            if (!isPNGorJPG) {
                message.error(`仅支持上传JPG或PNG格式的照片`);
            }
            return isPNGorJPG || Upload.LIST_IGNORE;
        },
    };
    return (
        <Upload {...uploadProps}>
            <Button icon={<UploadOutlined/>}>上传</Button>
        </Upload>
    );
};

export default AddNewPictures;