import {Button, Cascader, Form, Input, Modal, Select} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import AddNewPictures from '../AddNewPictures';
// @ts-ignore
import {debounce} from "lodash"
import {getAreaIdArray, showFail, showNeedCompleteInfo, showSuccess, showWarning} from "../functions";
import PickLocationModal from '../pickLocationModal';
import {updateHydrant} from "../../api";
import {iHydrant} from "../../interface";
import UpdatePictures from '../modifyPictures';
import PickUser from "../pickUser";


const EditHydrantModal = ({
                              open,
                              onClose,
                              areaOptions,
                              handleGetHydrant,
                              hydrantRecord
                          }: { open: boolean, onClose: () => void, areaOptions: any, handleGetHydrant: any, hydrantRecord: iHydrant | undefined }) => {
    const [form] = Form.useForm();
    const [record, setRecord] = useState<any>()
    const [loc, setLoc] = useState<any>()
    const [initLoc, setInitLoc] = useState<any>({lng: 120.09, lat: 30.88});

    //新增宿舍中的选择位置对话框
    const [pickLocModalOpen, setPickLocModalOpen] = useState(false);
    const handleCancelLocModal = () => {
        setPickLocModalOpen(false);
    }


    const confirmLoc = (e: any) => {
        setLoc(e);
        form.setFieldsValue({"location": e})
    }

    const confirmHydrantName = (e: any) => {
        form.setFieldsValue({"hydrantName": e})
    }

    const confirmRoad = (e: any) => {
        form.setFieldsValue({"road": e})
    }

    const confirmAddress = (e: any) => {
        form.setFieldsValue({"hydrantAddress": e})
    }


    const getLocation = () => {
        console.log(navigator.geolocation)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((l) => {
                if (l?.coords) {
                    setInitLoc({lng: l?.coords?.longitude, lat: l?.coords?.latitude})
                } else {
                    showFail('获取定位失败')
                }
            });
        } else {
            alert("浏览器不支持地理定位。");
        }
    }

    const [user, setUser] = useState<any>()
    const [pickUserModalOpen, setPickUserModalOpen] = useState(false);
    const handleCancelUserModal = () => {
        setPickUserModalOpen(false);
    }

    const confirmUser = (e: any) => {
        setUser(e);
        form.setFieldsValue({"userId": e?.userId})
    }


    const [loading, setLoading] = useState<boolean>(false);
    const handleUpdateHydrant = () => {
        form.validateFields().then((value) => {
            let pictures: any[] = [];
            if (value?.pictures) {
                value?.pictures?.map((p: any) => {
                        if (p?.response) {
                            pictures.push({url: p?.response?.full_url, name: p?.name})
                        } else {
                            pictures.push({url: p?.url, name: p?.name})
                        }
                    }
                )
            } else {
                pictures = record?.pictures
            }

            let hydrantState: number = 1;
            if (!(value.appearanceOk && value.hasWater && value.pressureOk)) {
                hydrantState = 2
            }
            let updatedHydrant = {
                ...value,
                hydrantId: record?.hydrantId,
                lng: value?.location?.lng,
                lat: value?.location?.lat,
                areaId: value?.area && value?.area[value?.area?.length - 1],
                pictures,
                hydrantState
            }
            // console.log('updatedHydrant',updatedHydrant)
            setLoading(true);
            updateHydrant(updatedHydrant).then((res: any) => {
                setLoading(false);
                if (res?.code === 200) {
                    handleGetHydrant().then(() => {
                        showSuccess(res?.msg);
                        onClose();
                        setLoc(undefined);
                    })
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                console.log(err)
            })

        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }
    const [areaIdArrays, setAreaIdArrays] = useState<any[]>([])

    useEffect(() => {
        if (open) {
            form.resetFields();
            setRecord(hydrantRecord);
            setLoc({lng: hydrantRecord?.lng, lat: hydrantRecord?.lat});
            setInitLoc({lng: hydrantRecord?.lng, lat: hydrantRecord?.lat});
            try {
                let _areaIdArrays = getAreaIdArray(areaOptions[0], hydrantRecord?.areaId as string).reverse();
                setAreaIdArrays(_areaIdArrays);
                setUser(hydrantRecord?.sysUser)
            }catch (e){
                showWarning("网络超时, 请刷新页面!")
            }
        }
    }, [open])

    return (
        <div>
            <Modal open={open} onCancel={() => {
                onClose();
                setLoading(false);
            }} title={'XHS修改'} maskClosable={false} okText={'确认修改'}
                   onOk={debounce(handleUpdateHydrant, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                <Form form={form} name="form" labelAlign="right" labelCol={{
                    xs: {span: 24},
                    sm: {span: 6},
                }} wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 14},
                }}>


                    <Form.Item
                        name="area"
                        label="街道"
                        initialValue={areaIdArrays}
                        rules={[
                            {
                                required: true, message: "请选择XHS街道"
                            },
                        ]}
                    >
                        <Cascader style={{width: 250}}
                                  options={areaOptions}
                                  changeOnSelect={false} placeholder="街道/乡镇"/>
                    </Form.Item>


                    <Form.Item
                        name="location"
                        label="定位"
                        initialValue={loc}
                    >
                        {loc ? (<div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{color: "#235c7a"}}>
                                    <div>经度:{loc?.lng}</div>
                                    <div>纬度:{loc?.lat}</div>
                                </div>
                                <div style={{width: "20px"}}/>
                                <div style={{display: "grid", placeItems: "center"}}>
                                    <Button type="primary" onClick={() => {
                                        setPickLocModalOpen(true)
                                    }}>重新选择
                                    </Button>
                                </div>

                            </div>
                        ) : (
                            <Button type="primary" onClick={() => {
                                getLocation();
                                setPickLocModalOpen(true);
                            }}>选择定位
                            </Button>
                        )}

                    </Form.Item>

                    {
                        loc && (
                            <>
                                <Form.Item
                                    name="hydrantName"
                                    label="XHS名称"
                                    initialValue={record?.hydrantName}
                                    rules={[
                                        {
                                            required: true, message: "请输入XHS名称"
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入XHS名称"/>
                                </Form.Item>

                                <Form.Item
                                    name="road"
                                    label="道路"
                                    initialValue={record?.road}
                                    rules={[
                                        {
                                            required: true, message: "请输入XHS所处道路"
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入XHS所处道路"/>
                                </Form.Item>

                                <Form.Item
                                    name="hydrantAddress"
                                    label="具体地址"
                                    initialValue={record?.hydrantAddress}
                                    rules={[
                                        {
                                            required: true, message: "请输入XHS具体地址"
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入XHS具体地址"/>
                                </Form.Item>
                            </>
                        )
                    }

                    {/*<Form.Item*/}
                    {/*    name="userId"*/}
                    {/*    label="负责人"*/}
                    {/*>*/}
                    {/*    {user ? (<div style={{display: "flex", flexDirection: "row",alignItems:'center'}}>*/}
                    {/*            <div style={{color: "#235c7a"}}>*/}
                    {/*                <div> {user?.username}{user?.mobile}</div>*/}
                    {/*            </div>*/}
                    {/*            <div style={{width: "20px"}}/>*/}
                    {/*            <div style={{display: "grid", placeItems: "center"}}>*/}
                    {/*                <Button type="primary" onClick={() => {*/}
                    {/*                    setPickUserModalOpen(true)*/}
                    {/*                }}>重新选择*/}
                    {/*                </Button>*/}
                    {/*            </div>*/}

                    {/*        </div>*/}
                    {/*    ) : (*/}
                    {/*        <Button type="primary" onClick={() => {*/}
                    {/*            setPickUserModalOpen(true);*/}
                    {/*        }}>选择负责人*/}
                    {/*        </Button>*/}
                    {/*    )}*/}
                    {/*</Form.Item>*/}

                    <Form.Item
                        name="appearanceOk"
                        label="外观情况"
                        initialValue={record?.hasWater}
                    >
                        <Select options={[
                            {
                                value: 1,
                                label: '完好'
                            },
                            {
                                value: 0,
                                label: '有损坏'
                            }
                        ]}/>
                    </Form.Item>

                    <Form.Item
                        name="hasWater"
                        label="出水情况"
                        initialValue={record?.hasWater}
                    >
                        <Select options={[
                            {
                                value: 1,
                                label: '有水'
                            },
                            {
                                value: 0,
                                label: '不出水'
                            }
                        ]}/>
                    </Form.Item>

                    <Form.Item
                        name="pressureOk"
                        label="水压情况"
                        initialValue={record?.pressureOk}
                    >
                        <Select options={[
                            {
                                value: 1,
                                label: '正常'
                            },
                            {
                                value: 0,
                                label: '水压不足'
                            }
                        ]}/>
                    </Form.Item>

                    <Form.Item
                        name="qrCode"
                        label="二维码"
                        initialValue={record?.qrCode}
                    >
                        <Input placeholder="请输入XHS二维码"/>
                    </Form.Item>

                    <Form.Item
                        name="description"
                        label="描述"
                        initialValue={record?.description}
                    >
                        <Input placeholder="请输入XHS描述"/>
                    </Form.Item>


                    {/*<Form.Item*/}
                    {/*    name="pictures"*/}
                    {/*    label="照片"*/}
                    {/*    valuePropName="fileList"*/}
                    {/*>*/}
                    {/*    <UpdatePictures defaultList={record?.pictures}/>*/}
                    {/*</Form.Item>*/}


                    <Form.Item
                        name="picture_front"
                        label="正面照片"
                        valuePropName="fileList"
                    >
                        <UpdatePictures defaultList={record?.pictures?.filter((p: any) => (
                            p?.pictureName.indexOf("front") !== -1
                        ))} id={record?.hydrantId} name={'front'} type={1} max={1}/>
                    </Form.Item>

                    <Form.Item
                        name="picture_back"
                        label="背面照片"
                        valuePropName="fileList"
                    >
                        <UpdatePictures defaultList={record?.pictures?.filter((p: any) => (
                            p?.pictureName.indexOf("back") !== -1
                        ))} id={record?.hydrantId} name={'back'} type={1} max={1}/>
                    </Form.Item>


                    <Form.Item
                        name="picture_left"
                        label="左面照片"
                        valuePropName="fileList"
                    >
                        <UpdatePictures defaultList={record?.pictures?.filter((p: any) => (
                            p?.pictureName.indexOf("left") !== -1
                        ))} id={record?.hydrantId} name={'left'} type={1} max={1}/>
                    </Form.Item>

                    <Form.Item
                        name="picture_right"
                        label="右面照片"
                        valuePropName="fileList"
                    >
                        <UpdatePictures defaultList={record?.pictures?.filter((p: any) => (
                            p?.pictureName.indexOf("right") !== -1
                        ))} id={record?.hydrantId} name={'right'} type={1} max={1}/>
                    </Form.Item>


                </Form>
                <PickLocationModal loc={initLoc} open={pickLocModalOpen} onCancel={handleCancelLocModal}
                                   confirmLoc={(e: any) => {
                                       confirmLoc(e)
                                   }} confirmName={confirmHydrantName} confirmAddress={confirmAddress}
                                   confirmRoad={confirmRoad} repick={true}/>

                {/*<PickUser open={pickUserModalOpen} onCancel={handleCancelUserModal} confirmUser={confirmUser}*/}
                {/*          initUser={user}/>*/}
            </Modal>

        </div>
    );
};

export default EditHydrantModal;