import React from 'react';
import "./index.css"
import {Avatar} from "antd"

const Personal = () => {
    let sysUser = JSON.parse(window.localStorage.getItem("sysUser") as string);
    let authentications = JSON.parse(window.localStorage.getItem("authentications") as string);
    let roles = authentications?.map((a: any) => {
        return a?.authority
    })?.filter((el: string) =>
        el.startsWith("ROLE_"))?.map((s: string) =>
        (s?.substring(5, s?.length)));

    return (
        <div className="Personal">
            <div className="topArea">
                <div className="avatarBlock">
                    <Avatar className="avatar" style={{fontSize: '2vw'}}
                            size={{xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100}}>
                        {sysUser?.username.substring(sysUser?.username.length - 2)}
                    </Avatar>
                </div>
                <div className="infoBlock">
                    <div className="name">{sysUser?.username}</div>
                    <div className="phone">{sysUser?.mobile}</div>
                    <div className="department">{sysUser?.organization}</div>
                    <div className="department">{sysUser?.dept}</div>
                </div>
            </div>
            <div className="card">我的角色</div>
            <div className="bottomArea" style={{display: 'flex', flexDirection: 'row',justifyContent:'center'}}>
                {roles?.map((r: string, index: number) => (
                    <div key={index} style={{marginRight:'10px'}} >
                        {r}
                    </div>))}
            </div>
        </div>
    );
};

export default Personal;